// external import
import { all, call, delay, put, race, takeLatest } from "redux-saga/effects";
// internal imports
import api from "../../../utils/api/general";
// get action names
import {
  GET_PARK_ERROR,
  GET_PARK_REQUEST,
  GET_PARK_SUCCESS,
  CLEAR_PARKS_ACTION_STATUS,
  ADD_PARK_ERROR,
  ADD_PARK_REQUEST,
  ADD_PARK_SUCCESS,
  DELETE_PARK_ERROR,
  DELETE_PARK_REQUEST,
  DELETE_PARK_SUCCESS,
  DELETE_PARK_BULK_ERROR,
  DELETE_PARK_BULK_REQUEST,
  DELETE_PARK_BULK_SUCCESS,
  EDIT_PARK_ERROR,
  EDIT_PARK_REQUEST,
  EDIT_PARK_SUCCESS,
} from "../constants";

// get initial state
import initialState from "./initialState";

const parseResponseTimeout = 50 * 1000; // 50 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case GET_PARK_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "park",
                success: null,
                error: "",
              },
      };
    case GET_PARK_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        parks: action.parks,
        parksCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "park",
                success: true,
                error: "",
              },
      };
    case GET_PARK_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "park",
          success: false,
          error: action.error,
        },
      };

    case ADD_PARK_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "park",
          success: null,
          error: "",
        },
      };
    case ADD_PARK_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "park",
          success: true,
          error: "",
        },
      };
    case ADD_PARK_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "park",
          success: false,
          error: action.error,
        },
      };

    case EDIT_PARK_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "park",
          success: null,
          error: "",
        },
      };
    case EDIT_PARK_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "park",
          success: true,
          error: "",
        },
      };
    case EDIT_PARK_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "park",
          success: false,
          error: action.error,
        },
      };
    case DELETE_PARK_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "park",
          success: null,
          error: "",
        },
      };
    case DELETE_PARK_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "park",
          success: true,
          error: "",
        },
      };
    case DELETE_PARK_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "park",
          success: false,
          error: action.error,
        },
      };

    case DELETE_PARK_BULK_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "park",
          success: null,
          error: "",
        },
      };
    case DELETE_PARK_BULK_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "park",
          success: true,
          error: "",
        },
      };
    case DELETE_PARK_BULK_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "park",
          success: false,
          error: action.error,
        },
      };

    case CLEAR_PARKS_ACTION_STATUS:
      return {
        ...newState,
        actionStatus: { type: "", entity: "", success: null, error: "" },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetParks(action) {
  try {
    const { park } = yield race({
      park: call(api.get, "park", {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (park) {
      const parks = park.data ? park.data : [];
      yield put({
        type: GET_PARK_SUCCESS,
        source: action.source,
        parks: parks.data,
        count: parks.count,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_PARK_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_PARK_ERROR,
      error: typeof error === "object" ? error : "Parks could not be updated.",
    });
  }
}
function* getParks() {
  yield takeLatest(GET_PARK_REQUEST, runGetParks);
}

function* runAddPark(action) {
  try {
    const { park } = yield race({
      park: call(api.add, "park", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (park)
      yield put({
        type: ADD_PARK_SUCCESS,
        ...park.data,
      });
    else
      yield put({
        type: ADD_PARK_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_PARK_ERROR,
      error, //error: typeof error === "object" ? error : "Could not add park",
    });
  }
}
function* addPark() {
  yield takeLatest(ADD_PARK_REQUEST, runAddPark);
}

function* runEditPark(action) {
  try {
    const { park } = yield race({
      park: call(api.updateNoId, "park", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (park)
      yield put({
        type: EDIT_PARK_SUCCESS,
        ...park.data,
      });
    else
      yield put({
        type: EDIT_PARK_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_PARK_ERROR,
      error, //error: typeof error === "object" ? error : "Could not edit park",
    });
  }
}
function* editPark() {
  yield takeLatest(EDIT_PARK_REQUEST, runEditPark);
}

function* runDeletePark(action) {
  try {
    const { park } = yield race({
      park: call(api.delete, "park", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (park) yield put({ type: DELETE_PARK_SUCCESS });
    else
      yield put({
        type: DELETE_PARK_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_PARK_ERROR,
      error: typeof error === "object" ? error : "Could not delete park",
    });
  }
}
function* deletePark() {
  yield takeLatest(DELETE_PARK_REQUEST, runDeletePark);
}

function* runDeleteParks(action) {
  try {
    const { stop } = yield race({
      stop: call(api.delete, "parkBulk", {
        data: { ...action.params },
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (stop) yield put({ type: DELETE_PARK_BULK_SUCCESS });
    else
      yield put({
        type: DELETE_PARK_BULK_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_PARK_BULK_ERROR,
      error: typeof error === "object" ? error : "Could not delete parks",
    });
  }
}

function* deleteParks() {
  yield takeLatest(DELETE_PARK_BULK_REQUEST, runDeleteParks);
}

export function* saga() {
  while (true) {
    yield all({
      getParks: call(getParks),
      addPark: call(addPark),
      editPark: call(editPark),
      deletePark: call(deletePark),
      deleteParks: call(deleteParks),
    });
  }
}
