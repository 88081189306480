import { reducer as contractorReducers } from "./contractorState";
import { reducer as staffReducers } from "./staffState";
import { reducer as contractFocalPointReducers } from "./contractFocalPointState";
import { reducer as driverReducers } from "./driverState";
import { reducer as focalPointReducers } from "./focalPointState";
import { reducer as busOperatorReducers } from "./busOperatorState";
import { reducer as userReducers } from "./userState";
import { reducer as approverReducers } from "./approverState";
import { reducer as adminUserReducers } from "./adminState";
// import { reducer as loggingUser } from './login'

const core = [
  contractFocalPointReducers,
  contractorReducers,
  driverReducers,
  focalPointReducers,
  busOperatorReducers,
  staffReducers,
  userReducers,
  approverReducers,
  adminUserReducers,
];

export default function userReducer(state, action, i) {
  if (!Number.isInteger(i)) i = 0;
  if (i < core.length)
    return userReducer(core[i](state, action), action, i + 1);
  else return state;
}
