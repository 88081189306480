// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  ADD_DRIVER_REQUEST,
  ADD_DRIVER_SUCCESS,
  ADD_DRIVER_ERROR,
  EDIT_DRIVER_REQUEST,
  EDIT_DRIVER_SUCCESS,
  EDIT_DRIVER_ERROR,
  GET_DRIVER_REQUEST,
  GET_DRIVER_SUCCESS,
  GET_DRIVER_ERROR,
  GET_SINGLE_DRIVER_REQUEST,
  GET_SINGLE_DRIVER_SUCCESS,
  GET_SINGLE_DRIVER_ERROR,
  DELETE_DRIVER_REQUEST,
  DELETE_DRIVER_SUCCESS,
  DELETE_DRIVER_ERROR,
  DELETE_BULK_DRIVER_REQUEST,
  DELETE_BULK_DRIVER_SUCCESS,
  DELETE_BULK_DRIVER_ERROR,
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 30 * 1000; // 5 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_DRIVER_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "driver",
          success: null,
          error: "",
        },
      };
    case ADD_DRIVER_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "driver",
          success: true,
          error: "",
        },
      };
    case ADD_DRIVER_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "driver",
          success: false,
          error: action.error,
        },
      };

    case EDIT_DRIVER_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "driver",
          success: null,
          error: "",
        },
      };
    case EDIT_DRIVER_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "driver",
          success: true,
          error: "",
        },
      };
    case EDIT_DRIVER_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "driver",
          success: false,
          error: action.error,
        },
      };
    case DELETE_DRIVER_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "driver",
          success: null,
          error: "",
        },
        userLinked: null,
      };
    case DELETE_DRIVER_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "driver",
          success: true,
          error: "",
        },
        userLinked: action.userLinked,
      };
    case DELETE_DRIVER_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "driver",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BULK_DRIVER_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "driver",
          success: null,
          error: "",
        },
      };
    case DELETE_BULK_DRIVER_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "driver",
          success: true,
          error: "",
        },
      };
    case DELETE_BULK_DRIVER_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "driver",
          success: false,
          error: action.error,
        },
      };
    case GET_DRIVER_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "driver",
                success: null,
                error: "",
              },
      };
    case GET_DRIVER_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        drivers: action.drivers,
        driversCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "driver",
                success: true,
                error: "",
              },
      };
    case GET_DRIVER_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "driver",
          success: false,
          error: action.error,
        },
      };

    case GET_SINGLE_DRIVER_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "driver",
          success: null,
          error: "",
        },
      };
    case GET_SINGLE_DRIVER_SUCCESS:
      return {
        ...newState,
        drivers: {
          ...state.drivers,
          [action.id]: action.driver,
        },
        actionStatus: {
          type: "fetch",
          entity: "driver",
          success: true,
          error: "",
        },
      };
    case GET_SINGLE_DRIVER_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "driver",
          success: false,
          error: action.error,
        },
      };
    case "LOGOUT_SUCCESS":
      return {};

    default:
      return state;
  }
}

function* runGetSingleDriver(action) {
  try {
    const { driver } = yield race({
      driver: call(api.get, "driver", {
        lang: action.lang,
        id: action.id,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (driver)
      yield put({
        type: GET_SINGLE_DRIVER_SUCCESS,
        driver: driver.data ? driver.data.data : [],
        id: action.id,
      });
    else
      yield put({
        type: GET_SINGLE_DRIVER_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_SINGLE_DRIVER_ERROR,
      error: typeof error === "object" ? error : "Driver could not be updated.",
    });
  }
}
function* getSingleDriver() {
  yield takeLatest(GET_SINGLE_DRIVER_REQUEST, runGetSingleDriver);
}

function* runGetDrivers(action) {
  try {
    const { driver } = yield race({
      driver: call(api.get, `driver${action.search ? "Search" : ""}`, {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (driver) {
      const drivers = driver.data ? driver.data.data : [];

      yield put({
        type: GET_DRIVER_SUCCESS,
        source: action.source,
        drivers: drivers.rows,
        count: drivers.count,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_DRIVER_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_DRIVER_ERROR,
      error:
        typeof error === "object" ? error : "Drivers  could not be updated.",
    });
  }
}
function* getDrivers() {
  yield takeLatest(GET_DRIVER_REQUEST, runGetDrivers);
}

function* runAddDriver(action) {
  try {
    const { driver } = yield race({
      driver: call(api.add, "driver", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (driver)
      yield put({
        type: ADD_DRIVER_SUCCESS,
        ...driver.data,
      });
    else
      yield put({
        type: ADD_DRIVER_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_DRIVER_ERROR,
      error,
      // error: typeof error === "object" ? error : "Could not add driver",
    });
  }
}
function* addDriver() {
  yield takeLatest(ADD_DRIVER_REQUEST, runAddDriver);
}

function* runEditDriver(action) {
  try {
    // Separate driver fields from user fields

    // Update user details
    const { users } = yield race({
      users: call(api.update, "driver", {
        lang: action.lang,
        id: action.id,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (users)
      yield put({
        type: EDIT_DRIVER_SUCCESS,
        ...users.data,
      });
    else
      yield put({
        type: EDIT_DRIVER_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_DRIVER_ERROR,
      error,
      //error: typeof error === "object" ? error : "Could not edit driver",
    });
  }
}
function* editDriver() {
  yield takeLatest(EDIT_DRIVER_REQUEST, runEditDriver);
}

function* runDeleteDriver(action) {
  try {
    const { driver } = yield race({
      driver: call(api.delete, `driver${action.params?.ids ? "Bulk" : ""}`, {
        id: action.params?.id || undefined,
        data: action.params?.ids || undefined,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (driver) {
      let obj = { type: DELETE_DRIVER_SUCCESS };
      if (driver?.data?.data && Object.values(driver?.data?.data).length > 0)
        obj.userLinked = {
          message: driver.data.message,
          data: driver.data.data,
        };
      yield put(obj);
    } else
      yield put({
        type: DELETE_DRIVER_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_DRIVER_ERROR,
      error: typeof error === "object" ? error : "Could not delete drivers",
    });
  }
}
function* deleteDriver() {
  yield takeLatest(DELETE_DRIVER_REQUEST, runDeleteDriver);
}

function* runDeleteBulkDriver(action) {
  try {
    const { driver } = yield race({
      driver: call(api.delete, "driver", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (driver) yield put({ type: DELETE_BULK_DRIVER_SUCCESS });
    else
      yield put({
        type: DELETE_BULK_DRIVER_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BULK_DRIVER_ERROR,
      error: typeof error === "object" ? error : "Could not delete drivers",
    });
  }
}
function* deleteBulkDriver() {
  yield takeEvery(DELETE_BULK_DRIVER_REQUEST, runDeleteBulkDriver);
}

export function* saga() {
  while (true) {
    yield all({
      getDrivers: call(getDrivers),
      getSingleDriver: call(getSingleDriver),
      addDriver: call(addDriver),
      editDriver: call(editDriver),
      deleteDriver: call(deleteDriver),
      deleteBulkDriver: call(deleteBulkDriver),
    });
  }
}
