// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";
import moment from "moment";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  APPROVE_PENDING_REGISTRATION_REQUEST,
  APPROVE_PENDING_REGISTRATION_SUCCESS,
  APPROVE_PENDING_REGISTRATION_ERROR,
  APPROVE_BULK_PENDING_REGISTRATION_REQUEST,
  APPROVE_BULK_PENDING_REGISTRATION_SUCCESS,
  APPROVE_BULK_PENDING_REGISTRATION_ERROR,
  GET_PENDING_REGISTRATION_REQUEST,
  GET_PENDING_REGISTRATION_SUCCESS,
  GET_PENDING_REGISTRATION_ERROR,
  GET_APPROVER_PENDING_REGISTRATION_REQUEST,
  GET_APPROVER_PENDING_REGISTRATION_SUCCESS,
  GET_APPROVER_PENDING_REGISTRATION_ERROR,
  CLEAR_REQUESTS_ACTION_STATUS,
  UPDATE_REJECTED_REGISTRATION_REQUEST,
  UPDATE_REJECTED_REGISTRATION_ERROR,
  UPDATE_REJECTED_REGISTRATION_SUCCESS,
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 50 * 1000; // 50 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case APPROVE_PENDING_REGISTRATION_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: action.isCfp ? "pendingRegistration" : "registration",
          success: null,
          extra: {
            ids: [action.id],
            status: action.status,
          },
          error: "",
        },
      };
    case APPROVE_PENDING_REGISTRATION_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: action.status,
          entity: action.isCfp ? "pendingRegistration" : "registration",
          success: true,
          extra: {
            ids: [action.id],
            status: action.status,
          },
          error: "",
        },
      };
    case APPROVE_PENDING_REGISTRATION_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: action.status,
          entity: action.isCfp ? "pendingRegistration" : "registration",
          success: false,
          extra: {
            ids: [action.id],
            status: action.status,
          },
          error: action.error,
        },
      };

    case UPDATE_REJECTED_REGISTRATION_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "moved",
          entity: "pendingRegistration",
          success: null,
          extra: {
            ids: [action.id],
            status: "moved",
          },
          error: "",
        },
      };
    case UPDATE_REJECTED_REGISTRATION_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "moved",
          entity: "pendingRegistration",
          success: true,
          extra: {
            ids: [action.id],
            status: "moved",
          },
          error: "",
        },
      };
    case UPDATE_REJECTED_REGISTRATION_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "moved",
          entity: "pendingRegistration",
          success: false,
          extra: {
            ids: [action.id],
            status: "moved",
          },
          error: action.error,
        },
      };

    case APPROVE_BULK_PENDING_REGISTRATION_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkEdit",
          entity: "registration",
          success: null,
          extra: {
            ids: action.ids,
            status: action.status,
          },
          error: "",
        },
      };
    case APPROVE_BULK_PENDING_REGISTRATION_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkEdit",
          entity: "registration",
          success: true,
          extra: {
            ids: action.ids,
            status: action.status,
          },
          error: "",
        },
      };
    case APPROVE_BULK_PENDING_REGISTRATION_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkEdit",
          entity: "registration",
          success: false,
          extra: {
            ids: action.ids,
            status: action.status,
          },
          error: action.error,
        },
      };

    case GET_PENDING_REGISTRATION_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "registration",
                success: null,
                error: "",
              },
      };
    case GET_PENDING_REGISTRATION_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        registrations: action.registrations,
        registrationsCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "registration",
                success: true,
                error: "",
              },
      };
    case GET_PENDING_REGISTRATION_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "registration",
          success: false,
          error: action.error,
        },
      };

    case GET_APPROVER_PENDING_REGISTRATION_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "registration",
                success: null,
                error: "",
              },
      };
    case GET_APPROVER_PENDING_REGISTRATION_SUCCESS:
      let data = action.admin ? "adminRegistrations" : "registrations";
      return {
        ...newState,
        filters: action.filters,
        [`${data}`]: action.registrations,
        [`${data}Count`]: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "registration",
                success: true,
                error: "",
              },
      };
    case GET_APPROVER_PENDING_REGISTRATION_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "registration",
          success: false,
          error: action.error,
        },
      };
    case CLEAR_REQUESTS_ACTION_STATUS:
      return {
        ...newState,
        actionStatus: { type: "", entity: "", success: null, error: "" },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetRegistrations(action) {
  try {
    const { registration } = yield race({
      registration: call(api.get, "pendingRegistration", {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (registration) {
      const registrations = registration.data ? registration.data.data : [];
      yield put({
        type: GET_PENDING_REGISTRATION_SUCCESS,
        source: action.source,
        registrations: registrations.rows,
        count: registrations.count,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_PENDING_REGISTRATION_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_PENDING_REGISTRATION_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Registrations could not be updated.",
    });
  }
}
function* getRegistrations() {
  yield takeLatest(GET_PENDING_REGISTRATION_REQUEST, runGetRegistrations);
}
function* runGetApproverRegistrations(action) {
  try {
    const { admin } = action;
    let endpoint = `pendingApproverRegistration${admin ? admin : ""}`;
    const { registration } = yield race({
      registration: call(api.get, endpoint, {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (registration) {
      const registrations = registration.data ? registration.data.data : [];
      yield put({
        type: GET_APPROVER_PENDING_REGISTRATION_SUCCESS,
        source: action.source,
        registrations: registrations.rows,
        count: registrations.count,
        filters: action.filters,
        admin,
      });
    } else
      yield put({
        type: GET_APPROVER_PENDING_REGISTRATION_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_APPROVER_PENDING_REGISTRATION_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Registrations could not be updated.",
    });
  }
}
function* getApproverRegistrations() {
  yield takeLatest(
    GET_APPROVER_PENDING_REGISTRATION_REQUEST,
    runGetApproverRegistrations
  );
}

function* runBulkApproveRegistration(action) {
  try {
    let isCompletedSuccessfully = false;
    if (typeof action.fields === "object") {
      const fields = Object.values(action.fields);

      for (const field of fields) {
        const { registration } = yield race({
          registration: call(api.update, "approveRegistration", {
            lang: action.lang,
            ...field,
          }),
          timeout: delay(parseResponseTimeout),
        });

        if (registration) {
          isCompletedSuccessfully = true;
        }
      }
    }

    if (isCompletedSuccessfully)
      yield put({
        type: APPROVE_BULK_PENDING_REGISTRATION_SUCCESS,
        ids: action.ids,
        status: action.status,
      });
    else
      yield put({
        type: APPROVE_BULK_PENDING_REGISTRATION_ERROR,
        error: errors.requestTimeout.message,
        ids: action.ids,
        status: action.status,
      });
  } catch (error) {
    yield put({
      type: APPROVE_BULK_PENDING_REGISTRATION_ERROR,
      ids: action.ids,
      status: action.status,
      error: typeof error === "object" ? error : "Could not add registrations",
    });
  }
}
function* bulkApproveRegistration() {
  yield takeLatest(
    APPROVE_BULK_PENDING_REGISTRATION_REQUEST,
    runBulkApproveRegistration
  );
}

function* runApproveRegistration(action) {
  try {
    let isCfp = action.fields?.isCfp;
    const { registration } = yield race({
      registration: call(
        api.update,
        `approveRegistration${isCfp ? "Admin" : ""}`,
        {
          lang: action.lang,
          id: action.id,
          status: action.status,
          isCfp,
          ...action.fields,
        }
      ),
      timeout: delay(parseResponseTimeout),
    });

    if (registration)
      yield put({
        type: APPROVE_PENDING_REGISTRATION_SUCCESS,
        id: action.id,
        status: action.status,
        ...registration.data,
        isCfp,
      });
    else
      yield put({
        type: APPROVE_PENDING_REGISTRATION_ERROR,
        id: action.id,
        status: action.status,
        error: errors.requestTimeout.message,
        isCfp,
      });
  } catch (error) {
    yield put({
      type: APPROVE_PENDING_REGISTRATION_ERROR,
      id: action.id,
      status: action.status,
      isCfp: action.fields?.isCfp,
      error:
        typeof error === "object"
          ? error
          : `Registration could not be ${action.status}`,
    });
  }
}
function* approveRegistration() {
  yield takeLatest(
    APPROVE_PENDING_REGISTRATION_REQUEST,
    runApproveRegistration
  );
}

function* runRejectRegistration(action) {
  try {
    const { response } = yield race({
      response: call(api.update, "rejectRegistration", {
        lang: action.lang,
        id: action.id,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response)
      yield put({
        type: UPDATE_REJECTED_REGISTRATION_SUCCESS,
        id: action.id,
        ...response.data,
      });
    else
      yield put({
        type: UPDATE_REJECTED_REGISTRATION_ERROR,
        id: action.id,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: UPDATE_REJECTED_REGISTRATION_ERROR,
      id: action.id,
      error:
        typeof error === "object"
          ? error
          : "Could not move to pending registration",
    });
  }
}
function* rejectRegistration() {
  yield takeLatest(UPDATE_REJECTED_REGISTRATION_REQUEST, runRejectRegistration);
}

export function* saga() {
  while (true) {
    yield all({
      getRegistrations: call(getRegistrations),
      approveRegistration: call(approveRegistration),
      rejectRegistration: call(rejectRegistration),
      getApproverRegistrations: call(getApproverRegistrations),
      bulkApproveRegistration: call(bulkApproveRegistration),
    });
  }
}
