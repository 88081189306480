// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";
import moment from "moment";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  ADD_BATCH_REQUEST,
  ADD_BATCH_SUCCESS,
  ADD_BATCH_ERROR,
  EDIT_BATCH_REQUEST,
  EDIT_BATCH_SUCCESS,
  EDIT_BATCH_ERROR,
  GET_BATCH_REQUEST,
  GET_BATCH_SUCCESS,
  GET_BATCH_ERROR,
  DELETE_BATCH_REQUEST,
  DELETE_BATCH_SUCCESS,
  DELETE_BATCH_ERROR,
  DELETE_BULK_BATCH_REQUEST,
  DELETE_BULK_BATCH_SUCCESS,
  DELETE_BULK_BATCH_ERROR,
  // smsCodeTimeout
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 50 * 1000; // 50 seconds
const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_BATCH_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "batch",
          success: null,
          error: "",
        },
      };
    case ADD_BATCH_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "batch",
          success: true,
          error: "",
        },
      };
    case ADD_BATCH_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "batch",
          success: false,
          error: action.error,
        },
      };
    case EDIT_BATCH_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "batch",
          success: null,
          error: "",
        },
      };
    case EDIT_BATCH_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "batch",
          success: true,
          error: "",
        },
      };
    case EDIT_BATCH_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "batch",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BATCH_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "batch",
          success: null,
          error: "",
        },
      };
    case DELETE_BATCH_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "batch",
          success: true,
          error: "",
        },
      };
    case DELETE_BATCH_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "batch",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BULK_BATCH_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "batch",
          success: null,
          error: "",
        },
      };
    case DELETE_BULK_BATCH_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "batch",
          success: true,
          error: "",
        },
      };
    case DELETE_BULK_BATCH_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "batch",
          success: false,
          error: action.error,
        },
      };

    case GET_BATCH_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "batch",
                success: null,
                error: "",
              },
      };
    case GET_BATCH_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        batches: action.batches,
        batchesCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "batch",
                success: true,
                error: "",
              },
      };
    case GET_BATCH_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "batch",
          success: false,
          error: action.error,
        },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetBatches(action) {
  try {
    const { batch } = yield race({
      batch: call(api.get, "batch", {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (batch) {
      const batches = batch.data ? batch.data.data : [];
      yield put({
        type: GET_BATCH_SUCCESS,
        source: action.source,
        batches: batches.rows,
        count: batches.count,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_BATCH_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_BATCH_ERROR,
      error: typeof error === "object" ? error : "Could not get batches",
    });
  }
}
function* getBatches() {
  yield takeLatest(GET_BATCH_REQUEST, runGetBatches);
}

function* runAddBatch(action) {
  try {
    const { batch } = yield race({
      batch: call(api.add, "batch", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (batch)
      yield put({
        type: ADD_BATCH_SUCCESS,
        ...batch.data,
      });
    else
      yield put({
        type: ADD_BATCH_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_BATCH_ERROR,
      error: typeof error === "object" ? error : "Could not add batch",
    });
  }
}
function* addBatch() {
  yield takeLatest(ADD_BATCH_REQUEST, runAddBatch);
}

function* runEditBatch(action) {
  try {
    const { batch } = yield race({
      batch: call(api.update, "batch", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (batch)
      yield put({
        type: EDIT_BATCH_SUCCESS,
        ...batch.data,
      });
    else
      yield put({
        type: EDIT_BATCH_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_BATCH_ERROR,
      error: typeof error === "object" ? error : "Could not edit batch",
    });
  }
}
function* editBatch() {
  yield takeLatest(EDIT_BATCH_REQUEST, runEditBatch);
}

function* runDeleteBatch(action) {
  try {
    const { batch } = yield race({
      batch: call(api.delete, "batch", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (batch) yield put({ type: DELETE_BATCH_SUCCESS });
    else
      yield put({
        type: DELETE_BATCH_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BATCH_ERROR,
      error: typeof error === "object" ? error : "Could not delete batch",
    });
  }
}
function* deleteBatch() {
  yield takeLatest(DELETE_BATCH_REQUEST, runDeleteBatch);
}

function* runDeleteBulkBatch(action) {
  try {
    const { batch } = yield race({
      batch: call(api.delete, "batch", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (batch) yield put({ type: DELETE_BULK_BATCH_SUCCESS });
    else
      yield put({
        type: DELETE_BULK_BATCH_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BULK_BATCH_ERROR,
      error: typeof error === "object" ? error : "Could not delete batch",
    });
  }
}
function* deleteBulkBatch() {
  yield takeEvery(DELETE_BULK_BATCH_REQUEST, runDeleteBulkBatch);
}

export function* saga() {
  while (true) {
    yield all({
      getBatches: call(getBatches),
      addBatch: call(addBatch),
      editBatch: call(editBatch),
      deleteBatch: call(deleteBatch),
      deleteBulkBatch: call(deleteBulkBatch),
    });
  }
}
