export function asyncParse(string) {
  return new Response(string).json();
}

export const convertNumbers2English = async (string) => {
  return string
    .replace(/[\u0660-\u0669]/g, function(c) {
      return c.charCodeAt(0) - 0x0660;
    })
    .replace(/[\u06f0-\u06f9]/g, function(c) {
      return c.charCodeAt(0) - 0x06f0;
    });
};
