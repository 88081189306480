import {
  SWITCH_LOCALE,
  TOGGLE_SIDE_MENU,
  UPDATE_USER_ROLE,
  CHANGE_TABLE_VIEW,
  BOOKING_TABLE_CHANGE,
} from "../constants";
import initialState from "./initialState";

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SWITCH_LOCALE:
      return { ...state, locale: action.locale };
    case TOGGLE_SIDE_MENU:
      return { ...state, expandMenu: action.open };
    case UPDATE_USER_ROLE:
      return { ...state, role: action.role };
    case CHANGE_TABLE_VIEW:
      return { ...state, tableCompactView: action.tableView };
    case BOOKING_TABLE_CHANGE:
      return {
        ...state,
        tableColumns: {
          ...(state?.tableColumns || {}),
          [action.name]: action.data,
        },
      };
    default:
      return state;
  }
}
