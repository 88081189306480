// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  ADD_CONTRACT_FOCAL_POINT_REQUEST,
  ADD_CONTRACT_FOCAL_POINT_SUCCESS,
  ADD_CONTRACT_FOCAL_POINT_ERROR,
  EDIT_CONTRACT_FOCAL_POINT_REQUEST,
  EDIT_CONTRACT_FOCAL_POINT_SUCCESS,
  EDIT_CONTRACT_FOCAL_POINT_ERROR,
  GET_CONTRACT_FOCAL_POINT_REQUEST,
  GET_CONTRACT_FOCAL_POINT_SUCCESS,
  GET_CONTRACT_FOCAL_POINT_ERROR,
  GET_SINGLE_CONTRACT_FOCAL_POINT_REQUEST,
  GET_SINGLE_CONTRACT_FOCAL_POINT_SUCCESS,
  GET_SINGLE_CONTRACT_FOCAL_POINT_ERROR,
  DELETE_CONTRACT_FOCAL_POINT_REQUEST,
  DELETE_CONTRACT_FOCAL_POINT_SUCCESS,
  DELETE_CONTRACT_FOCAL_POINT_ERROR,
  DELETE_BULK_CONTRACT_FOCAL_POINT_REQUEST,
  DELETE_BULK_CONTRACT_FOCAL_POINT_SUCCESS,
  DELETE_BULK_CONTRACT_FOCAL_POINT_ERROR,
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 30 * 1000; // 5 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_CONTRACT_FOCAL_POINT_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "contractFocalPoint",
          success: null,
          error: "",
        },
      };
    case ADD_CONTRACT_FOCAL_POINT_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "contractFocalPoint",
          success: true,
          error: "",
        },
      };
    case ADD_CONTRACT_FOCAL_POINT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "contractFocalPoint",
          success: false,
          error: action.error,
        },
      };

    case EDIT_CONTRACT_FOCAL_POINT_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "contractFocalPoint",
          success: null,
          error: "",
        },
      };
    case EDIT_CONTRACT_FOCAL_POINT_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "contractFocalPoint",
          success: true,
          error: "",
        },
      };
    case EDIT_CONTRACT_FOCAL_POINT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "contractFocalPoint",
          success: false,
          error: action.error,
        },
      };
    case DELETE_CONTRACT_FOCAL_POINT_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "contractFocalPoint",
          success: null,
          error: "",
        },
        userLinked: null,
      };
    case DELETE_CONTRACT_FOCAL_POINT_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "contractFocalPoint",
          success: true,
          error: "",
        },
        userLinked: action.userLinked,
      };
    case DELETE_CONTRACT_FOCAL_POINT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "contractFocalPoint",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BULK_CONTRACT_FOCAL_POINT_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "contractFocalPoint",
          success: null,
          error: "",
        },
      };
    case DELETE_BULK_CONTRACT_FOCAL_POINT_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "contractFocalPoint",
          success: true,
          error: "",
        },
      };
    case DELETE_BULK_CONTRACT_FOCAL_POINT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "contractFocalPoint",
          success: false,
          error: action.error,
        },
      };
    case GET_CONTRACT_FOCAL_POINT_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "contractFocalPoint",
                success: null,
                error: "",
              },
      };
    case GET_CONTRACT_FOCAL_POINT_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        contractFocalPoints: action.contractFocalPoints,
        contractFocalPointsCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "contractFocalPoint",
                success: true,
                error: "",
              },
      };
    case GET_CONTRACT_FOCAL_POINT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "contractFocalPoint",
          success: false,
          error: action.error,
        },
      };

    case GET_SINGLE_CONTRACT_FOCAL_POINT_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "contractFocalPoint",
          success: null,
          error: "",
        },
      };
    case GET_SINGLE_CONTRACT_FOCAL_POINT_SUCCESS:
      return {
        ...newState,
        contractFocalPoints: {
          ...state.contractFocalPoints,
          [action.id]: action.contractFocalPoint,
        },
        actionStatus: {
          type: "fetch",
          entity: "contractFocalPoint",
          success: true,
          error: "",
        },
      };
    case GET_SINGLE_CONTRACT_FOCAL_POINT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "contractFocalPoint",
          success: false,
          error: action.error,
        },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetSingleContractFocalPoint(action) {
  try {
    const { contractFocalPoint } = yield race({
      contractFocalPoint: call(api.get, "contractFocalPoint", {
        lang: action.lang,
        id: action.id,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (contractFocalPoint)
      yield put({
        type: GET_SINGLE_CONTRACT_FOCAL_POINT_SUCCESS,
        contractFocalPoint: contractFocalPoint.data,
        id: action.id,
      });
    else
      yield put({
        type: GET_SINGLE_CONTRACT_FOCAL_POINT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_SINGLE_CONTRACT_FOCAL_POINT_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Contract focal point could not be updated.",
    });
  }
}
function* getSingleContractFocalPoint() {
  yield takeLatest(
    GET_SINGLE_CONTRACT_FOCAL_POINT_REQUEST,
    runGetSingleContractFocalPoint
  );
}

function* runGetContractFocalPoints(action) {
  try {
    const { contractFocalPoint } = yield race({
      contractFocalPoint: call(
        api.get,
        `contractFocalPoint${action.params?.search ? "Search" : ""}`,
        {
          lang: action.lang,
          ...action.params,
          ...action.filters,
        }
      ),
      timeout: delay(parseResponseTimeout),
    });

    if (contractFocalPoint) {
      const contractFocalPoints = contractFocalPoint.data
        ? contractFocalPoint.data.data
        : [];

      yield put({
        type: GET_CONTRACT_FOCAL_POINT_SUCCESS,
        source: action.source,
        contractFocalPoints: contractFocalPoints.rows,
        count: contractFocalPoints.count,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_CONTRACT_FOCAL_POINT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_CONTRACT_FOCAL_POINT_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Contract focal points could not be updated.",
    });
  }
}
function* getContractFocalPoints() {
  yield takeLatest(GET_CONTRACT_FOCAL_POINT_REQUEST, runGetContractFocalPoints);
}

function* runAddContractFocalPoint(action) {
  try {
    let extraUrl = action.userType === "staff" ? "Staff" : "";
    const { contractFocalPoint } = yield race({
      contractFocalPoint: call(api.add, `contractFocalPoint${extraUrl}`, {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (contractFocalPoint)
      yield put({
        type: ADD_CONTRACT_FOCAL_POINT_SUCCESS,
        ...contractFocalPoint.data,
      });
    else
      yield put({
        type: ADD_CONTRACT_FOCAL_POINT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_CONTRACT_FOCAL_POINT_ERROR,
      error,
      // error:
      // typeof error === "object" ? error : "Could not add contractFocalPoint",
    });
  }
}
function* addContractFocalPoint() {
  yield takeLatest(ADD_CONTRACT_FOCAL_POINT_REQUEST, runAddContractFocalPoint);
}

function* runEditContractFocalPoint(action) {
  try {
    // Separate contractFocalPoint fields from user fields
    const { id, user_id, ...userFields } = action.fields;

    // Update user details
    const { users } = yield race({
      users: call(api.update, "contractFocalPoint", {
        lang: action.lang,
        id: action.id,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });
    if (users)
      yield put({
        type: EDIT_CONTRACT_FOCAL_POINT_SUCCESS,
        ...users.data,
      });
    else
      yield put({
        type: EDIT_CONTRACT_FOCAL_POINT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_CONTRACT_FOCAL_POINT_ERROR,
      error,
      // error:
      // typeof error === "object" ? error : "Could not edit contractFocalPoint",
    });
  }
}
function* editContractFocalPoint() {
  yield takeLatest(
    EDIT_CONTRACT_FOCAL_POINT_REQUEST,
    runEditContractFocalPoint
  );
}

function* runDeleteContractFocalPoint(action) {
  try {
    const { contractFocalPoint } = yield race({
      contractFocalPoint: call(
        api.delete,
        `contractFocalPoint${action.params?.ids ? "Bulk" : ""}`,
        {
          id: action.params?.id || undefined,
          data: action.params?.ids || undefined,
          lang: action.lang,
        }
      ),
      timeout: delay(parseResponseTimeout),
    });

    if (contractFocalPoint) {
      let obj = { type: DELETE_CONTRACT_FOCAL_POINT_SUCCESS };
      if (
        contractFocalPoint?.data?.data &&
        Object.values(contractFocalPoint?.data?.data).length > 0
      )
        obj.userLinked = {
          message: contractFocalPoint.data.message,
          data: contractFocalPoint.data.data,
        };
      yield put(obj);
    } else
      yield put({
        type: DELETE_CONTRACT_FOCAL_POINT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_CONTRACT_FOCAL_POINT_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Could not delete contractFocalPoints",
    });
  }
}
function* deleteContractFocalPoint() {
  yield takeLatest(
    DELETE_CONTRACT_FOCAL_POINT_REQUEST,
    runDeleteContractFocalPoint
  );
}

function* runDeleteBulkContractFocalPoint(action) {
  try {
    const { contractFocalPoint } = yield race({
      contractFocalPoint: call(api.delete, "contractFocalPoint", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (contractFocalPoint)
      yield put({ type: DELETE_BULK_CONTRACT_FOCAL_POINT_SUCCESS });
    else
      yield put({
        type: DELETE_BULK_CONTRACT_FOCAL_POINT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BULK_CONTRACT_FOCAL_POINT_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Could not delete contractFocalPoints",
    });
  }
}
function* deleteBulkContractFocalPoint() {
  yield takeEvery(
    DELETE_BULK_CONTRACT_FOCAL_POINT_REQUEST,
    runDeleteBulkContractFocalPoint
  );
}

export function* saga() {
  while (true) {
    yield all({
      getContractFocalPoints: call(getContractFocalPoints),
      getSingleContractFocalPoint: call(getSingleContractFocalPoint),
      addContractFocalPoint: call(addContractFocalPoint),
      editContractFocalPoint: call(editContractFocalPoint),
      deleteContractFocalPoint: call(deleteContractFocalPoint),
      deleteBulkContractFocalPoint: call(deleteBulkContractFocalPoint),
    });
  }
}
