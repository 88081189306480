import { useMemo } from "react";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";

import rootSaga from "./sagas";
import rootReducer from "./reducers";

const persistConfig = {
  key: "primary",
  // version: process.env.NEXT_PUBLIC_version, // version mismatch will empty persistor
  storage,
};
let store: any;
const persistedReducer = persistReducer(persistConfig, rootReducer);
const bindMiddleware = (middleware: Array<any>) => {
  // if (process.env.NODE_ENV !== "production") {
  const { composeWithDevTools } = require("redux-devtools-extension");
  // const { logger } = require(`redux-logger`);
  return composeWithDevTools(applyMiddleware(...middleware));
  // }
  // return applyMiddleware(...middleware);
};
function makeStore(initialState: any) {
  const sagaMiddleware = createSagaMiddleware();
  let store: any = createStore(
    persistedReducer,
    initialState,
    bindMiddleware([sagaMiddleware])
  );
  store.sagaTask = sagaMiddleware.run(rootSaga);
  return store;
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? makeStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = makeStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }
  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState: any) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
