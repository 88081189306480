import axios from "axios";
import { initializeStore } from "../../states/store";
import { apiURL } from "../apiCallsApp";
import { endPoints } from "./endpoints";
import { handleError } from "../handleError";
import {
  IS_SESSION_VALID_SUCCESS,
  IS_SESSION_VALID_ERROR,
} from "../../modules/core/constants";

export const refreshAuthLogic = (failedRequest) => {
  if (failedRequest.response.status === 401) {
    const store = initializeStore();
    const state = store.getState();
    const access_token = state?.core?.accessToken;
    const refresh_token = state?.core?.refreshToken;
    return axios
      .post(`${apiURL("", "core", false)}/${endPoints["checkSession"]}`, {
        access_token,
        refresh_token,
      })
      .then(({ data }) => {
        store.dispatch({
          type: IS_SESSION_VALID_SUCCESS,
          access_token: data.data.access_token,
          refresh_token: data.data.refresh_token,
        });
        failedRequest.response.config.headers[
          "Authorization"
        ] = `Bearer ${data.data.access_token}`;
        return Promise.resolve();
      })
      .catch((err) => {
        let error = handleError(err);
        let obj = {
          type: IS_SESSION_VALID_ERROR,
          error: "User session is not valid.",
        };
        if (error?.response === 440) obj.sessionError = error?.message || error;
        store.dispatch({ ...obj });
      });
  } else {
    throw failedRequest;
  }
};
