import { combineReducers } from "redux";
// import { REHYDRATE } from "redux-persist/lib/constants";

import coreReducer from "../modules/core/states/coreReducers";
import trackingReducer from "../modules/tracking/states/trackingReducers";
import userReducer from "../modules/users/states/userReducers";
import organizationReducer from "../modules/organizations/states/organizationReducers";
import vehicleReducer from "../modules/vehicles/states/vehicleReducers";
import hardwareReducer from "../modules/hardware/states/hardwareReducers";
import settingsReducer from "../modules/settings/states/settingsReducers";
import reportsReducer from "../modules/reports/states/reportReducers";
import regionsReducer from "../modules/regions/states/regionReducers";
import bookingsReducer from "../modules/bookings/states/bookingReducers";
import tripsReducer from "../modules/trips/states/tripReducers";
import stopsReducer from "../modules/stops/states/stopReducers";
import contractsReducer from "../modules/contracts/states/contractReducers";
import routesReducer from "../modules/routes/states/routeReducers";
import manifestReducer from "../modules/misc/states/manifestReducers";
import miscReducer from "../modules/misc/states/miscReducers";
import vehicleManifestReducer from "../modules/misc/states/vehicleManifestReducers";
import requestsReducer from "../modules/requests/states/requestReducers";
import notificationReducer from "../modules/notifications/states/notificationReducers";
import parkReducer from "../modules/parks/states/parkReducers"

// export function rehydrateReducer(state = {}, action) {
//   // apply any state resets here

//   switch (action.type) {
//     case REHYDRATE:
//       return { ...state, persistedState: action.payload };
//     default:
//       return state;
//   }
// }

export default combineReducers({
  // rehydrate: rehydrateReducer,
  core: coreReducer,
  tracking: trackingReducer,
  users: userReducer,
  organizations: organizationReducer,
  vehicles: vehicleReducer,
  hardware: hardwareReducer,
  settings: settingsReducer,
  reports: reportsReducer,
  regions: regionsReducer,
  bookings: bookingsReducer,
  trips: tripsReducer,
  stops: stopsReducer,
  routes: routesReducer,
  manifest: manifestReducer,
  vehicleManifest: vehicleManifestReducer,
  misc: miscReducer,
  contracts: contractsReducer,
  requests: requestsReducer,
  notifications: notificationReducer,
  parks: parkReducer
});
