// external import
import { all, put, call, takeLatest, race, delay } from "redux-saga/effects";
import { apiVehicleLogin } from "../../../utils/api/login";
import { LOGOUT_VEHICLE_MANIFEST } from "../../misc/constants";
// import { loginWithPassword } from "../actions";
import {
  VEHICLE_LOGIN_REQUEST,
  VEHICLE_LOGIN_SUCCESS,
  VEHICLE_LOGIN_ERROR,
} from "../constants";
const parseResponseTimeout = 50 * 1000; // 50 seconds
const isDev = process.env.NODE_ENV === "development";
export function reducer(state = {}, action) {
  const newState = Object.assign({}, state, {});
  switch (action.type) {
    case VEHICLE_LOGIN_REQUEST:
      return {
        ...newState,
        isVehicleLoggedIn: null,
        expiresIn: null,
      };
    case VEHICLE_LOGIN_SUCCESS:
      return {
        ...newState,
        isVehicleLoggedIn: false,
        vehicle: action.vehicle,
      };
    case VEHICLE_LOGIN_ERROR:
      return {
        ...newState,
        isVehicleLoggedIn: false,
        vehicle: { error: action.error },
      };
    case LOGOUT_VEHICLE_MANIFEST:
      return {
        ...newState,
        isVehicleLoggedIn: null,
        vehicle: null,
      };

    default:
      return state;
  }
}

function* runVehicleLogin(action) {
  try {
    const { user } = yield race({
      user: call(apiVehicleLogin, {
        // user: call(loginWithPassword, {
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (user) {
      const vehicleData = user.data ? user.data.data : {};
      yield put({
        type: VEHICLE_LOGIN_SUCCESS,
        vehicle: {
          user: vehicleData.vehicle,
          access_token: vehicleData.access_token,
          refresh_token: vehicleData.refresh_token,
        },
      });
      action.router.push("/vehicle-manifest");
    } else
      yield put({
        type: VEHICLE_LOGIN_ERROR,
        error: "timeout",
      });
  } catch (error) {
    yield put({
      type: VEHICLE_LOGIN_ERROR,
      error: error,
    });
  }
}
function* vehicleLogin() {
  yield takeLatest(VEHICLE_LOGIN_REQUEST, runVehicleLogin);
}

export function* saga() {
  while (true) {
    yield all({
      vehicleLogin: call(vehicleLogin),
    });
  }
}
