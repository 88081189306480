// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";
import moment from "moment";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  ACTIVATE_STAFF_PHONE_REQUEST,
  ACTIVATE_STAFF_PHONE_SUCCESS,
  ACTIVATE_STAFF_PHONE_ERROR,
  RESET_STAFF_PHONE_STATUS,
  CLEAR_MISC_ACTION_STATUS,
} from "../constants";

// get initial state
import initialState from "./manifestInitialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 50 * 1000; // 50 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ACTIVATE_STAFF_PHONE_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "activate",
                entity: "staffPhone",
                success: null,
                error: "",
              },
      };
    case ACTIVATE_STAFF_PHONE_SUCCESS:
      return {
        ...newState,
        staffPhone: action.data,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "activate",
                entity: "staffPhone",
                success: true,
                error: "",
              },
      };
    case ACTIVATE_STAFF_PHONE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "activate",
          entity: "staffPhone",
          success: false,
          error: action.error,
        },
      };
    case CLEAR_MISC_ACTION_STATUS:
      return {
        ...newState,
        actionStatus: { type: "", entity: "", success: null, error: "" },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runActivatePhoneNumber(action) {
  try {
    const { activationStatus } = yield race({
      activationStatus: call(api.get, "staffPhoneActivation", {
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (activationStatus) {
      // const manifestData = (activationStatus.data && activationStatus.data.data) || {};
      yield put({
        type: ACTIVATE_STAFF_PHONE_SUCCESS,
        data: activationStatus.data && activationStatus.data.data,
        filters: action.filters,
        source: action.source,
      });
    } else
      yield put({
        type: ACTIVATE_STAFF_PHONE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ACTIVATE_STAFF_PHONE_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Staff phone number could not be updated.",
    });
  }
}
function* activateStaffPhoneNumber() {
  yield takeLatest(ACTIVATE_STAFF_PHONE_REQUEST, runActivatePhoneNumber);
}

export function* saga() {
  while (true) {
    yield all({
      activateStaffPhoneNumber: call(activateStaffPhoneNumber),
    });
  }
}
