export const GET_VEHICLE_REQUEST = "GET_VEHICLE_REQUEST";
export const GET_VEHICLE_SUCCESS = "GET_VEHICLE_SUCCESS";
export const GET_VEHICLE_ERROR = "GET_VEHICLE_ERROR";

export const GET_SINGLE_VEHICLE_REQUEST = "GET_SINGLE_VEHICLE_REQUEST";
export const GET_SINGLE_VEHICLE_SUCCESS = "GET_SINGLE_VEHICLE_SUCCESS";
export const GET_SINGLE_VEHICLE_ERROR = "GET_SINGLE_VEHICLE_ERROR";

export const ADD_VEHICLE_REQUEST = "ADD_VEHICLE_REQUEST";
export const ADD_VEHICLE_SUCCESS = "ADD_VEHICLE_SUCCESS";
export const ADD_VEHICLE_ERROR = "ADD_VEHICLE_ERROR";

export const EDIT_VEHICLE_REQUEST = "EDIT_VEHICLE_REQUEST";
export const EDIT_VEHICLE_SUCCESS = "EDIT_VEHICLE_SUCCESS";
export const EDIT_VEHICLE_ERROR = "EDIT_VEHICLE_ERROR";

export const DELETE_VEHICLE_REQUEST = "DELETE_VEHICLE_REQUEST";
export const DELETE_VEHICLE_SUCCESS = "DELETE_VEHICLE_SUCCESS";
export const DELETE_VEHICLE_ERROR = "DELETE_VEHICLE_ERROR";

export const DELETE_BULK_VEHICLE_REQUEST = "DELETE_BULK_VEHICLE_REQUEST";
export const DELETE_BULK_VEHICLE_SUCCESS = "DELETE_BULK_VEHICLE_SUCCESS";
export const DELETE_BULK_VEHICLE_ERROR = "DELETE_BULK_VEHICLE_ERROR";

export const CLEAR_VEHICLES_ACTION_STATUS = "CLEAR_VEHICLES_ACTION_STATUS";
