export const GET_CONTRACT_REQUEST = "GET_CONTRACT_REQUEST";
export const GET_CONTRACT_SUCCESS = "GET_CONTRACT_SUCCESS";
export const GET_CONTRACT_ERROR = "GET_CONTRACT_ERROR";

export const ADD_CONTRACT_REQUEST = "ADD_CONTRACT_REQUEST";
export const ADD_CONTRACT_SUCCESS = "ADD_CONTRACT_SUCCESS";
export const ADD_CONTRACT_ERROR = "ADD_CONTRACT_ERROR";

export const EDIT_CONTRACT_REQUEST = "EDIT_CONTRACT_REQUEST";
export const EDIT_CONTRACT_SUCCESS = "EDIT_CONTRACT_SUCCESS";
export const EDIT_CONTRACT_ERROR = "EDIT_CONTRACT_ERROR";

export const DELETE_CONTRACT_REQUEST = "DELETE_CONTRACT_REQUEST";
export const DELETE_CONTRACT_SUCCESS = "DELETE_CONTRACT_SUCCESS";
export const DELETE_CONTRACT_ERROR = "DELETE_CONTRACT_ERROR";

export const DELETE_BULK_CONTRACT_REQUEST = "DELETE_BULK_CONTRACT_REQUEST";
export const DELETE_BULK_CONTRACT_SUCCESS = "DELETE_BULK_CONTRACT_SUCCESS";
export const DELETE_BULK_CONTRACT_ERROR = "DELETE_BULK_CONTRACT_ERROR";

export const CLEAR_CONTRACTS_ACTION_STATUS = "CLEAR_CONTRACTS_ACTION_STATUS";

export const GET_ALL_CONTRACT_HOLDERS_REQUEST =
  "GET_ALL_CONTRACT_HOLDERS_REQUEST";
export const GET_ALL_CONTRACT_HOLDERS_SUCCESS =
  "GET_ALL_CONTRACT_HOLDERS_SUCCESS";
export const GET_ALL_CONTRACT_HOLDERS_ERROR = "GET_ALL_CONTRACT_HOLDERS_ERROR";
