const initialState = {
  contractorBilling: [],
  contractorBillingCount: 0,
  departmentBilling: [],
  departmentBillingCount: 0,
  bookingStatistics: [],
  bookingStatisticsCount: 0,
  report1: [],
  report1Count: 0,
  report2: [],
  report2Count: 0,
  report3: [],
  report3Count: 0,
  report4: [],
  report4Count: 0,
  actionStatus: {
    action: "",
    entity: "",
    success: null,
    error: "",
  },
  filters: {},
};

export default initialState;
