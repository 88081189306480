import { all, delay, put, call, takeLatest, race } from "redux-saga/effects";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  SEND_PASSENGER_NOTIFICATION_REQUEST,
  SEND_PASSENGER_NOTIFICATION_SUCCESS,
  SEND_PASSENGER_NOTIFICATION_ERROR,
  SEND_PASSENGER_TRIP_NOTIFICATION_REQUEST,
  SEND_PASSENGER_TRIP_NOTIFICATION_SUCCESS,
  SEND_PASSENGER_TRIP_NOTIFICATION_ERROR,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_ERROR,
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_ERROR,
  CLEAR_NOTIFICATION_STATUS,
  EDIT_NOTIFICATION_REQUEST,
  EDIT_NOTIFICATION_SUCCESS,
  EDIT_NOTIFICATION_ERROR,
  CANCEL_NOTIFICATION_REQUEST,
  CANCEL_NOTIFICATION_SUCCESS,
  CANCEL_NOTIFICATION_ERROR,
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 50 * 1000; // 50 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case SEND_PASSENGER_NOTIFICATION_REQUEST:
      return {
        ...newState,
        notificationSent: false,
        notificationError: null,
      };
    case SEND_PASSENGER_NOTIFICATION_SUCCESS:
      return {
        ...newState,
        notificationSent: true,
        notificationError: null,
      };
    case SEND_PASSENGER_NOTIFICATION_ERROR:
      return {
        ...newState,
        notificationSent: false,
        notificationError: action.error,
      };

    case SEND_PASSENGER_TRIP_NOTIFICATION_REQUEST:
      return {
        ...newState,
        notificationSent: false,
        notificationError: null,
      };
    case SEND_PASSENGER_TRIP_NOTIFICATION_SUCCESS:
      return {
        ...newState,
        notificationSent: true,
        notificationError: null,
      };
    case SEND_PASSENGER_TRIP_NOTIFICATION_ERROR:
      return {
        ...newState,
        notificationSent: false,
        notificationError: action.error,
      };

    case SEND_NOTIFICATION_REQUEST:
      return {
        ...newState,
        notificationSent: false,
        notificationError: null,
        actionStatus: {
          type: "add",
          entity: "notification",
          success: null,
          error: "",
        },
      };
    case SEND_NOTIFICATION_SUCCESS:
      return {
        ...newState,
        notificationSent: true,
        notificationError: null,
        actionStatus: {
          type: "add",
          entity: "notification",
          success: true,
          error: "",
        },
      };
    case SEND_NOTIFICATION_ERROR:
      return {
        ...newState,
        notificationSent: false,
        notificationError: action.error,
        actionStatus: {
          type: "add",
          entity: "notification",
          success: false,
          error: action.error,
        },
      };

    case CLEAR_NOTIFICATION_STATUS:
      return {
        ...newState,
        actionStatus: {
          type: "",
          entity: "",
          success: null,
          error: "",
        },
      };

    case GET_NOTIFICATION_REQUEST:
      return {
        ...newState,
        notificationError: null,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "notification",
                success: null,
                error: "",
              },
      };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        notification: action.notifications?.rows,
        // notification: action.notifications?.data,
        notificationCount: action.notifications?.count,
        // notificationCount: action.notifications?.total,
        notificationError: null,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "notification",
                success: true,
                error: "",
              },
      };
    case GET_NOTIFICATION_ERROR:
      return {
        ...newState,
        notificationError: action.error,
        // isLoggedIn: false
        actionStatus: {
          type: "fetch",
          entity: "notification",
          success: false,
          error: action.error,
        },
      };

    case EDIT_NOTIFICATION_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "notification",
          success: null,
          error: "",
        },
      };
    case EDIT_NOTIFICATION_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "notification",
          success: true,
          error: "",
        },
      };
    case EDIT_NOTIFICATION_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "notification",
          success: false,
          error: action.error,
        },
      };

    case CANCEL_NOTIFICATION_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "notification",
          success: null,
          error: "",
        },
      };
    case CANCEL_NOTIFICATION_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "notification",
          success: true,
          error: "",
        },
      };
    case CANCEL_NOTIFICATION_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "notification",
          success: false,
          error: action.error,
        },
      };

    case "LOGOUT_SUCCESS":
      return {};

    default:
      return state;
  }
}

function* runGetNotifications(action) {
  try {
    const { notification } = yield race({
      notification: call(api.get, "notification", {
        ...action.filters,
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (notification) {
      yield put({
        type: GET_NOTIFICATION_SUCCESS,
        // notifications: notification?.data,
        notifications: notification?.data?.data,
        filters: action.filters,
        source: action.params.source,
      });
    } else
      yield put({
        type: GET_NOTIFICATION_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_NOTIFICATION_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Notifications could not be updated",
    });
  }
}
function* getNotifications() {
  yield takeLatest(GET_NOTIFICATION_REQUEST, runGetNotifications);
}
function* runSendPassengerNotification(action) {
  try {
    const { notification } = yield race({
      notification: call(api.add, "notifyPassenger", {
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (notification)
      yield put({
        type: SEND_PASSENGER_NOTIFICATION_SUCCESS,
        ...notification.data,
      });
    else
      yield put({
        type: SEND_PASSENGER_NOTIFICATION_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: SEND_PASSENGER_NOTIFICATION_ERROR,
      error: typeof error === "object" ? error : "Could not add notification",
    });
  }
}
function* sendPassengerNotification() {
  yield takeLatest(
    SEND_PASSENGER_NOTIFICATION_REQUEST,
    runSendPassengerNotification
  );
}
function* runSendNotifications(action) {
  try {
    const { notification } = yield race({
      notification: call(
        api.add,
        `notification${action.fields?.bulkNotification ? "Bulk" : ""}`,
        {
          lang: action.lang,
          ...action.fields,
        }
      ),
      timeout: delay(parseResponseTimeout),
    });

    if (notification) {
      yield put({
        type: SEND_NOTIFICATION_SUCCESS,
        ...notification.data,
      });
    } else
      yield put({
        type: SEND_NOTIFICATION_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: SEND_NOTIFICATION_ERROR,
      error: typeof error === "object" ? error : "Could not add notification",
    });
  }
}
function* sendNotifications() {
  yield takeLatest(SEND_NOTIFICATION_REQUEST, runSendNotifications);
}
function* runCancelBulkNotification(action) {
  try {
    const { notification } = yield race({
      notification: call(
        api.delete,
        `notification${action.params?.ids ? "Bulk" : ""}`,
        {
          id: action.params?.id || undefined,
          data: action.params?.ids || undefined,
          lang: action.lang,
        }
      ),
      timeout: delay(parseResponseTimeout),
    });

    if (notification) yield put({ type: CANCEL_NOTIFICATION_SUCCESS });
    else
      yield put({
        type: CANCEL_NOTIFICATION_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: CANCEL_NOTIFICATION_ERROR,
      error:
        typeof error === "object" ? error : "Could not delete Notification",
    });
  }
}
function* cancelBulkNotification() {
  yield takeLatest(CANCEL_NOTIFICATION_REQUEST, runCancelBulkNotification);
}
function* runEditNotification(action) {
  try {
    const { notification } = yield race({
      notification: call(api.update, "notification", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (notification)
      yield put({
        type: EDIT_NOTIFICATION_SUCCESS,
        ...notification.data,
      });
    else
      yield put({
        type: EDIT_NOTIFICATION_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_NOTIFICATION_ERROR,
      error, //error: typeof error === "object" ? error : "Could not edit notification",
    });
  }
}
function* editNotification() {
  yield takeLatest(EDIT_NOTIFICATION_REQUEST, runEditNotification);
}
function* runSendPassengerTripNotification(action) {
  try {
    const { notification } = yield race({
      notification: call(api.add, "notifyPassengerTrip", {
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (notification)
      yield put({
        type: SEND_PASSENGER_TRIP_NOTIFICATION_SUCCESS,
        ...notification.data,
      });
    else
      yield put({
        type: SEND_PASSENGER_TRIP_NOTIFICATION_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: SEND_PASSENGER_TRIP_NOTIFICATION_ERROR,
      error, //error: typeof error === "object" ? error : "Could not add notification",
    });
  }
}
function* sendPassengerTripNotification() {
  yield takeLatest(
    SEND_PASSENGER_TRIP_NOTIFICATION_REQUEST,
    runSendPassengerTripNotification
  );
}
export function* saga() {
  while (true) {
    yield all({
      getNotifications: call(getNotifications),
      sendNotifications: call(sendNotifications),
      editNotification: call(editNotification),
      sendPassengerNotification: call(sendPassengerNotification),
      sendPassengerTripNotification: call(sendPassengerTripNotification),
      cancelBulkNotification: call(cancelBulkNotification),
    });
  }
}
