// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  ADD_STAFF_REQUEST,
  ADD_STAFF_SUCCESS,
  ADD_STAFF_ERROR,
  EDIT_STAFF_REQUEST,
  EDIT_STAFF_SUCCESS,
  EDIT_STAFF_ERROR,
  GET_STAFF_REQUEST,
  GET_STAFF_SUCCESS,
  GET_STAFF_ERROR,
  GET_SINGLE_STAFF_REQUEST,
  GET_SINGLE_STAFF_SUCCESS,
  GET_SINGLE_STAFF_ERROR,
  DELETE_STAFF_REQUEST,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_ERROR,
  DELETE_BULK_STAFF_REQUEST,
  DELETE_BULK_STAFF_SUCCESS,
  DELETE_BULK_STAFF_ERROR,
  GET_STAFF_SEARCH_REQUEST,
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 30 * 1000; // 5 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_STAFF_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "staff",
          success: null,
          error: "",
        },
      };
    case ADD_STAFF_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "staff",
          success: true,
          error: "",
        },
      };
    case ADD_STAFF_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "staff",
          success: false,
          error: action.error,
        },
      };

    case EDIT_STAFF_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "staff",
          success: null,
          error: "",
        },
      };
    case EDIT_STAFF_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "staff",
          success: true,
          error: "",
        },
      };
    case EDIT_STAFF_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "staff",
          success: false,
          error: action.error,
        },
      };
    case DELETE_STAFF_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "staff",
          success: null,
          error: "",
        },
        userLinked: null,
      };
    case DELETE_STAFF_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "staff",
          success: true,
          error: "",
        },
        userLinked: action.userLinked,
      };
    case DELETE_STAFF_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "staff",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BULK_STAFF_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "staff",
          success: null,
          error: "",
        },
      };
    case DELETE_BULK_STAFF_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "staff",
          success: true,
          error: "",
        },
      };
    case DELETE_BULK_STAFF_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "staff",
          success: false,
          error: action.error,
        },
      };
    case GET_STAFF_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "staff",
                success: null,
                error: "",
              },
      };
    case GET_STAFF_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        staff: action.staff,
        staffCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "staff",
                success: true,
                error: "",
              },
      };
    case GET_STAFF_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "staff",
          success: false,
          error: action.error,
        },
      };

    case GET_SINGLE_STAFF_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "staff",
          success: null,
          error: "",
        },
      };
    case GET_SINGLE_STAFF_SUCCESS:
      return {
        ...newState,
        staff: {
          ...state.staff,
          [action.id]: action.staff,
        },
        actionStatus: {
          type: "fetch",
          entity: "staff",
          success: true,
          error: "",
        },
      };
    case GET_SINGLE_STAFF_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "staff",
          success: false,
          error: action.error,
        },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetSingleStaff(action) {
  try {
    const { staff } = yield race({
      staff: call(api.get, "staff", {
        lang: action.lang,
        id: action.id,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (staff)
      yield put({
        type: GET_SINGLE_STAFF_SUCCESS,
        staff: staff.data,
        id: action.id,
      });
    else
      yield put({
        type: GET_SINGLE_STAFF_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_SINGLE_STAFF_ERROR,
      error: typeof error === "object" ? error : "Staff could not be updated.",
    });
  }
}
function* getSingleStaff() {
  yield takeLatest(GET_SINGLE_STAFF_REQUEST, runGetSingleStaff);
}

function* runGetStaff(action) {
  try {
    const { staff } = yield race({
      staff: call(api.get, `passenger${action.search ? "Search" : ""}`, {
        lang: action.lang,
        type: action.userType,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (staff) {
      const staffObj = staff.data ? staff.data.data : {};

      yield put({
        type: GET_STAFF_SUCCESS,
        source: action.source,
        staff: staffObj.rows,
        count: staffObj.count,
        filters: action.filters,
        filter: action.filter,
      });
    } else
      yield put({
        type: GET_STAFF_ERROR,
        error: errors.requestTimeout.message,
        filter: action.filter,
      });
  } catch (error) {
    yield put({
      type: GET_STAFF_ERROR,
      error: typeof error === "object" ? error : "Staff could not be updated.",
      filter: action.filter,
    });
  }
}
function* getStaff() {
  yield takeLatest(GET_STAFF_REQUEST, runGetStaff);
}

function* runAddStaff(action) {
  try {
    const { staff } = yield race({
      staff: call(api.add, "passenger", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (staff)
      yield put({
        type: ADD_STAFF_SUCCESS,
        ...staff.data,
      });
    else
      yield put({
        type: ADD_STAFF_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_STAFF_ERROR,
      error,
      //error: typeof error === "object" ? error : "Could not add staff",
    });
  }
}
function* addStaff() {
  yield takeLatest(ADD_STAFF_REQUEST, runAddStaff);
}

function* runEditStaff(action) {
  try {
    // Separate staff fields from user fields

    // Update user details
    const { users } = yield race({
      users: call(api.update, "passenger", {
        lang: action.lang,
        id: action.id,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });
    if (users)
      yield put({
        type: EDIT_STAFF_SUCCESS,
        ...users.data,
      });
    else
      yield put({
        type: EDIT_STAFF_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_STAFF_ERROR,
      error,
      //error: typeof error === "object" ? error : "Could not edit staff",
    });
  }
}
function* editStaff() {
  yield takeLatest(EDIT_STAFF_REQUEST, runEditStaff);
}

function* runDeleteStaff(action) {
  // yield put({
  //   type: DELETE_STAFF_ERROR,
  //   error: {
  //     has_bookings: true,
  //     confirm_dialogue: true,
  //     message: "Stop cannot be deleted. Stop is linked to routes.",
  //     action: "delete",
  //     routes_count: 10,
  //     allow_action: true,
  //   },
  // });
  try {
    const { staff } = yield race({
      staff: call(api.delete, `passenger${action.params?.ids ? "Bulk" : ""}`, {
        id: action.params?.id || undefined,
        data: action.params?.ids || undefined,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (staff) {
      let obj = { type: DELETE_STAFF_SUCCESS };
      if (staff?.data?.data && Object.values(staff?.data?.data).length > 0)
        obj.userLinked = {
          message: staff.data.message,
          data: staff.data.data,
        };
      yield put(obj);
    } else
      yield put({
        type: DELETE_STAFF_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_STAFF_ERROR,
      error: typeof error === "object" ? error : "Could not delete staff",
    });
  }
}
function* deleteStaff() {
  yield takeLatest(DELETE_STAFF_REQUEST, runDeleteStaff);
}

function* runDeleteBulkStaff(action) {
  // yield put({
  //   type: DELETE_BULK_STAFF_ERROR,
  //   error: {
  //     has_bookings: true,
  //     confirm_dialogue: true,
  //     message: "Stop cannot be deleted. Stop is linked to routes.",
  //     action: "delete",
  //     routes_count: 10,
  //     allow_action: true,
  //   },
  // });
  try {
    const { staff } = yield race({
      staff: call(api.delete, "bulkPassenger", {
        data: { ...action.params },
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (staff) yield put({ type: DELETE_BULK_STAFF_SUCCESS });
    else
      yield put({
        type: DELETE_BULK_STAFF_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BULK_STAFF_ERROR,
      error: typeof error === "object" ? error : "Could not delete staff",
    });
  }
}
function* deleteBulkStaff() {
  yield takeEvery(DELETE_BULK_STAFF_REQUEST, runDeleteBulkStaff);
}

export function* saga() {
  while (true) {
    yield all({
      getStaff: call(getStaff),
      getSingleStaff: call(getSingleStaff),
      addStaff: call(addStaff),
      editStaff: call(editStaff),
      deleteStaff: call(deleteStaff),
      deleteBulkStaff: call(deleteBulkStaff),
    });
  }
}
