export const GET_HARDWARE_REQUEST = "GET_HARDWARE_REQUEST";
export const GET_HARDWARE_SUCCESS = "GET_HARDWARE_SUCCESS";
export const GET_HARDWARE_ERROR = "GET_HARDWARE_ERROR";

export const GET_SINGLE_HARDWARE_REQUEST = "GET_SINGLE_HARDWARE_REQUEST";
export const GET_SINGLE_HARDWARE_SUCCESS = "GET_SINGLE_HARDWARE_SUCCESS";
export const GET_SINGLE_HARDWARE_ERROR = "GET_SINGLE_HARDWARE_ERROR";

export const ADD_HARDWARE_REQUEST = "ADD_HARDWARE_REQUEST";
export const ADD_HARDWARE_SUCCESS = "ADD_HARDWARE_SUCCESS";
export const ADD_HARDWARE_ERROR = "ADD_HARDWARE_ERROR";

export const EDIT_HARDWARE_REQUEST = "EDIT_HARDWARE_REQUEST";
export const EDIT_HARDWARE_SUCCESS = "EDIT_HARDWARE_SUCCESS";
export const EDIT_HARDWARE_ERROR = "EDIT_HARDWARE_ERROR";

export const DELETE_HARDWARE_REQUEST = "DELETE_HARDWARE_REQUEST";
export const DELETE_HARDWARE_SUCCESS = "DELETE_HARDWARE_SUCCESS";
export const DELETE_HARDWARE_ERROR = "DELETE_HARDWARE_ERROR";

export const DELETE_BULK_HARDWARE_REQUEST = "DELETE_BULK_HARDWARE_REQUEST";
export const DELETE_BULK_HARDWARE_SUCCESS = "DELETE_BULK_HARDWARE_SUCCESS";
export const DELETE_BULK_HARDWARE_ERROR = "DELETE_BULK_HARDWARE_ERROR";

export const GET_BATCH_REQUEST = "GET_BATCH_REQUEST";
export const GET_BATCH_SUCCESS = "GET_BATCH_SUCCESS";
export const GET_BATCH_ERROR = "GET_BATCH_ERROR";

export const GET_SINGLE_BATCH_REQUEST = "GET_SINGLE_BATCH_REQUEST";
export const GET_SINGLE_BATCH_SUCCESS = "GET_SINGLE_BATCH_SUCCESS";
export const GET_SINGLE_BATCH_ERROR = "GET_SINGLE_BATCH_ERROR";

export const ADD_BATCH_REQUEST = "ADD_BATCH_REQUEST";
export const ADD_BATCH_SUCCESS = "ADD_BATCH_SUCCESS";
export const ADD_BATCH_ERROR = "ADD_BATCH_ERROR";

export const EDIT_BATCH_REQUEST = "EDIT_BATCH_REQUEST";
export const EDIT_BATCH_SUCCESS = "EDIT_BATCH_SUCCESS";
export const EDIT_BATCH_ERROR = "EDIT_BATCH_ERROR";

export const DELETE_BATCH_REQUEST = "DELETE_BATCH_REQUEST";
export const DELETE_BATCH_SUCCESS = "DELETE_BATCH_SUCCESS";
export const DELETE_BATCH_ERROR = "DELETE_BATCH_ERROR";

export const DELETE_BULK_BATCH_REQUEST = "DELETE_BULK_BATCH_REQUEST";
export const DELETE_BULK_BATCH_SUCCESS = "DELETE_BULK_BATCH_SUCCESS";
export const DELETE_BULK_BATCH_ERROR = "DELETE_BULK_BATCH_ERROR";

export const CLEAR_HARDWARE_ACTION_STATUS = "CLEAR_HARDWARE_ACTION_STATUS";
