// external import
import { all, put, call, takeLatest, race, delay } from "redux-saga/effects";
import api from "../../../utils/api/general";
// internal imports
import { vehicleApi } from "../../../utils/api/vehicleApi";

// get action names
import {
  GET_VEHICLE_TRIPS_REQUEST,
  GET_VEHICLE_TRIPS_SUCCESS,
  GET_VEHICLE_TRIPS_ERROR,
  GET_VEHICLE_MANIFEST_REQUEST,
  GET_VEHICLE_MANIFEST_SUCCESS,
  GET_VEHICLE_MANIFEST_ERROR,
  LOGOUT_VEHICLE_MANIFEST,
  CLEAR_VEHICLE_MANIFEST_ACTION_STATUS,
  CHANGE_VEHICLE_MANIFEST_BOARDING_STATUS_REQUEST,
  CHANGE_VEHICLE_MANIFEST_BOARDING_STATUS_SUCCESS,
  CHANGE_VEHICLE_MANIFEST_BOARDING_STATUS_ERROR,
  GET_TIMEZONE_SETTINGS_REQUEST,
  GET_TIMEZONE_SETTINGS_SUCCESS,
  GET_TIMEZONE_SETTINGS_ERROR,
} from "../constants";

const parseResponseTimeout = 50 * 1000; // 50 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

export function reducer(state = {}, action) {
  const newState = Object.assign({}, state, {});
  switch (action.type) {
    case GET_VEHICLE_TRIPS_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "vehicleDailyTrips",
          success: null,
          error: "",
        },
      };
    case GET_VEHICLE_TRIPS_SUCCESS:
      return {
        ...newState,
        vehicleDailyTrips: action.data,
        vehicleDailyTripsCount: action.count,
        actionStatus: {
          type: "fetch",
          entity: "vehicleDailyTrips",
          success: true,
          error: "",
        },
      };
    case GET_VEHICLE_TRIPS_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "vehicleDailyTrips",
          success: false,
          error: action.error,
        },
      };
    case GET_VEHICLE_MANIFEST_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "vehicleManifest",
          success: null,
          error: "",
        },
      };
    case GET_VEHICLE_MANIFEST_SUCCESS:
      return {
        ...newState,
        vehicleManifest: action.data,
        vehicleManifestCount: action.count,
        actionStatus: {
          type: "fetch",
          entity: "vehicleManifest",
          success: true,
          error: "",
        },
      };
    case GET_VEHICLE_MANIFEST_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "vehicleManifest",
          success: false,
          error: action.error,
        },
      };

    case CLEAR_VEHICLE_MANIFEST_ACTION_STATUS:
      return {
        ...newState,
        actionStatus: {
          type: "",
          entity: "",
          success: null,
          error: "",
        },
      };
    case CHANGE_VEHICLE_MANIFEST_BOARDING_STATUS_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "change",
          entity: "boardingStatus",
          success: null,
          error: "",
        },
      };
    case CHANGE_VEHICLE_MANIFEST_BOARDING_STATUS_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "change",
          entity: "boardingStatus",
          success: true,
          error: "",
        },
      };
    case CHANGE_VEHICLE_MANIFEST_BOARDING_STATUS_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "change",
          entity: "boardingStatus",
          success: false,
          error: action.error,
        },
      };
    case GET_TIMEZONE_SETTINGS_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "setting",
                success: null,
                error: "",
              },
      };
    case GET_TIMEZONE_SETTINGS_SUCCESS:
      return {
        ...newState,
        setting: action.setting,
        actionStatus: {
          type: "fetch",
          entity: "setting",
          success: true,
          error: " ",
        },
      };
    case GET_TIMEZONE_SETTINGS_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "setting",
          success: false,
          error: action.error,
        },
      };
    case LOGOUT_VEHICLE_MANIFEST:
      return {};
    default:
      return newState;
  }
}

function* runVehicleTrips(action) {
  try {
    const { dailyTrips } = yield race({
      dailyTrips: call(vehicleApi.get, "dailyTrips", { ...action.params }),
      timeout: delay(parseResponseTimeout),
    });

    if (dailyTrips) {
      yield put({
        type: GET_VEHICLE_TRIPS_SUCCESS,
        data: dailyTrips?.data?.data,
      });
    } else
      yield put({
        type: GET_VEHICLE_TRIPS_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_VEHICLE_TRIPS_ERROR,
      error: error,
      // typeof error === "object" ? error : "Manifest could not be updated",
    });
  }
}
function* getVehicleTrips() {
  yield takeLatest(GET_VEHICLE_TRIPS_REQUEST, runVehicleTrips);
}

function* runVehicleManifest(action) {
  try {
    const { manifest } = yield race({
      manifest: call(vehicleApi.get, "tripsManifest", { ...action.params }),
      timeout: delay(parseResponseTimeout),
    });

    if (manifest) {
      yield put({
        type: GET_VEHICLE_MANIFEST_SUCCESS,
        data: manifest?.data?.data,
      });
    } else
      yield put({
        type: GET_VEHICLE_MANIFEST_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_VEHICLE_MANIFEST_ERROR,
      error,
      // typeof error === "object" ? error : "Manifest could not be updated",
    });
  }
}
function* getVehicleManifest() {
  yield takeLatest(GET_VEHICLE_MANIFEST_REQUEST, runVehicleManifest);
}

function* runChangeVehicleBoardingStatus(action) {
  try {
    const { response } = yield race({
      response: call(vehicleApi.put, `changeVehicleBoardingStatus`, {
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response) {
      const res = response.data ? response.data.data : [];
      yield put({
        type: CHANGE_VEHICLE_MANIFEST_BOARDING_STATUS_SUCCESS,
        response: res.rows,
      });
    } else
      yield put({
        type: CHANGE_VEHICLE_MANIFEST_BOARDING_STATUS_ERROR,
        error: errors.requestTimeout.message,
        entity: action.entity,
      });
  } catch (error) {
    // .log(error);
    yield put({
      type: CHANGE_VEHICLE_MANIFEST_BOARDING_STATUS_ERROR,
      entity: action.entity,
      error: typeof error === "object" ? error : error,
    });
  }
}
function* changeVehicleBoardingStatus() {
  yield takeLatest(
    CHANGE_VEHICLE_MANIFEST_BOARDING_STATUS_REQUEST,
    runChangeVehicleBoardingStatus
  );
}

function* runGetSetting(action) {
  try {
    const { res } = yield race({
      res: call(api.get, "settingDetail", {
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (res) {
      yield put({
        type: GET_TIMEZONE_SETTINGS_SUCCESS,
        error: "",
        setting: res?.data?.data?.timezone,
      });
    } else
      yield put({
        type: GET_TIMEZONE_SETTINGS_ERROR,
        error: error?.message || error,
      });
  } catch (error) {
    yield put({
      type: GET_TIMEZONE_SETTINGS_ERROR,
      error: error.error,
    });
  }
}

function* getSetting() {
  yield takeLatest(GET_TIMEZONE_SETTINGS_REQUEST, runGetSetting);
}

export function* saga() {
  while (true) {
    yield all({
      getSetting: call(getSetting),
      getVehicleTrips: call(getVehicleTrips),
      getVehicleManifest: call(getVehicleManifest),
      changeVehicleBoardingStatus: call(changeVehicleBoardingStatus),
    });
  }
}
