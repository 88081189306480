// external import
import { all, put, call, takeLatest, delay, race } from "redux-saga/effects";
import moment from "moment";
// internal imports
import api from "../../../utils/api/general";
// get action names
import {
  GET_UTILIZATION_REPORT_ONE_REQUEST,
  GET_UTILIZATION_REPORT_ONE_SUCCESS,
  GET_UTILIZATION_REPORT_ONE_ERROR,
  GET_UTILIZATION_REPORT_TWO_REQUEST,
  GET_UTILIZATION_REPORT_TWO_SUCCESS,
  GET_UTILIZATION_REPORT_TWO_ERROR,
  GET_UTILIZATION_REPORT_THREE_REQUEST,
  GET_UTILIZATION_REPORT_THREE_SUCCESS,
  GET_UTILIZATION_REPORT_THREE_ERROR,
  GET_UTILIZATION_REPORT_FOUR_REQUEST,
  GET_UTILIZATION_REPORT_FOUR_SUCCESS,
  GET_UTILIZATION_REPORT_FOUR_ERROR,
  CLEAR_REPORTS_ACTION_STATUS,
} from "../constants";
// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 50 * 1000; // 50 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case GET_UTILIZATION_REPORT_ONE_REQUEST:
      return {
        ...newState,
        loading: true,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "report1",
                success: null,
                error: "",
              },
      };
    case GET_UTILIZATION_REPORT_ONE_SUCCESS:
      return {
        ...newState,
        loading: false,
        filters: {
          ...action.filters,
          extraData: action.extraData,
        },
        report1: action.reports,
        report1Count: action.count,
        lastFetched: moment().format("YYYY-MM-DD"),
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "report1",
                success: true,
                error: "",
              },
      };
    case GET_UTILIZATION_REPORT_ONE_ERROR:
      return {
        ...newState,
        loading: false,
        actionStatus: {
          type: "fetch",
          entity: "report1",
          success: false,
          error: action.error,
        },
      };

    case GET_UTILIZATION_REPORT_TWO_REQUEST:
      return {
        ...newState,
        loading: true,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "report2",
                success: null,
                error: "",
              },
      };
    case GET_UTILIZATION_REPORT_TWO_SUCCESS:
      return {
        ...newState,
        loading: false,
        filters: {
          ...action.filters,
          extraData: action.extraData,
        },
        report2: action.reports,
        report2Count: action.count,
        lastFetched: moment().format("YYYY-MM-DD"),
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "report2",
                success: true,
                error: "",
              },
      };
    case GET_UTILIZATION_REPORT_TWO_ERROR:
      return {
        ...newState,
        loading: false,
        actionStatus: {
          type: "fetch",
          entity: "report2",
          success: false,
          error: action.error,
        },
      };

    case GET_UTILIZATION_REPORT_THREE_REQUEST:
      return {
        ...newState,
        loading: true,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "report3",
                success: null,
                error: "",
              },
      };
    case GET_UTILIZATION_REPORT_THREE_SUCCESS:
      return {
        ...newState,
        loading: false,
        filters: {
          ...action.filters,
          extraData: action.extraData,
        },
        report3: action.reports,
        report3Count: action.count,
        lastFetched: moment().format("YYYY-MM-DD"),
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "report3",
                success: true,
                error: "",
              },
      };
    case GET_UTILIZATION_REPORT_THREE_ERROR:
      return {
        ...newState,
        loading: false,
        actionStatus: {
          type: "fetch",
          entity: "report3",
          success: false,
          error: action.error,
        },
      };
    case GET_UTILIZATION_REPORT_FOUR_REQUEST:
      return {
        ...newState,
        loading: true,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "report4",
                success: null,
                error: "",
              },
      };
    case GET_UTILIZATION_REPORT_FOUR_SUCCESS:
      return {
        ...newState,
        loading: false,
        filters: {
          ...action.filters,
          extraData: action.extraData,
        },
        report4: action.reports,
        report4Count: action.count,
        lastFetched: moment().format("YYYY-MM-DD"),
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "report4",
                success: true,
                error: "",
              },
      };
    case GET_UTILIZATION_REPORT_FOUR_ERROR:
      return {
        ...newState,
        loading: false,
        actionStatus: {
          type: "fetch",
          entity: "report4",
          success: false,
          error: action.error,
        },
      };

    case CLEAR_REPORTS_ACTION_STATUS:
      return {
        ...newState,
        actionStatus: { type: "", entity: "", success: null, error: "" },
      };

    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetReport1(action) {
  try {
    const { extraData, ...filters } = action.filters;
    const { reports } = yield race({
      reports: call(api.get, "report1", {
        lang: action.lang,
        // admin: false,
        ...action.params,
        ...filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (reports) {
      const report = reports.data ? reports.data.data : [];
      yield put({
        type: GET_UTILIZATION_REPORT_ONE_SUCCESS,
        source: action.source,
        reports: report.rows || report,
        count: report.count,
        extraData,
        filters,
      });
    } else
      yield put({
        type: GET_UTILIZATION_REPORT_ONE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    // .log(error);
    yield put({
      type: GET_UTILIZATION_REPORT_ONE_ERROR,
      error:
        typeof error === "object" ? error : "Report-1 could not be updated.",
    });
  }
}
function* getReport1() {
  yield takeLatest(GET_UTILIZATION_REPORT_ONE_REQUEST, runGetReport1);
}

function* runGetReport2(action) {
  try {
    const { extraData, ...filters } = action.filters;

    const { reports } = yield race({
      reports: call(api.get, "report2", {
        lang: action.lang,
        // admin: false,
        ...action.params,
        ...filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (reports) {
      const report = reports.data ? reports.data.data : [];
      yield put({
        type: GET_UTILIZATION_REPORT_TWO_SUCCESS,
        source: action.source,
        reports: report.rows || report,
        count: report.count,
        extraData,
        filters,
      });
    } else
      yield put({
        type: GET_UTILIZATION_REPORT_TWO_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    // .log(error);
    yield put({
      type: GET_UTILIZATION_REPORT_TWO_ERROR,
      error:
        typeof error === "object" ? error : "Report-2 could not be updated.",
    });
  }
}
function* getReport2() {
  yield takeLatest(GET_UTILIZATION_REPORT_TWO_REQUEST, runGetReport2);
}

function* runGetReport3(action) {
  try {
    const { extraData, ...filters } = action.filters;

    const { reports } = yield race({
      reports: call(api.get, "report3", {
        lang: action.lang,
        // admin: false,
        ...action.params,
        ...filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (reports) {
      const report = reports.data ? reports.data.data : [];
      yield put({
        type: GET_UTILIZATION_REPORT_THREE_SUCCESS,
        source: action.source,
        reports: report.rows || report,
        count: report.count,
        extraData,
        filters,
      });
    } else
      yield put({
        type: GET_UTILIZATION_REPORT_THREE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    // .log(error);
    yield put({
      type: GET_UTILIZATION_REPORT_THREE_ERROR,
      error:
        typeof error === "object" ? error : "Report-3 could not be updated.",
    });
  }
}
function* getReport3() {
  yield takeLatest(GET_UTILIZATION_REPORT_THREE_REQUEST, runGetReport3);
}

function* runGetReport4(action) {
  try {
    const { extraData, ...filters } = action.filters;

    const { reports } = yield race({
      reports: call(api.get, "report4", {
        lang: action.lang,
        // admin: false,
        ...action.params,
        ...filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (reports) {
      const report = reports.data ? reports.data.data : [];
      yield put({
        type: GET_UTILIZATION_REPORT_FOUR_SUCCESS,
        source: action.source,
        reports: report.rows || report,
        count: report.count,
        extraData,
        filters,
      });
    } else
      yield put({
        type: GET_UTILIZATION_REPORT_FOUR_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    // .log(error);
    yield put({
      type: GET_UTILIZATION_REPORT_FOUR_ERROR,
      error:
        typeof error === "object" ? error : "Report-4 could not be updated.",
    });
  }
}
function* getReport4() {
  yield takeLatest(GET_UTILIZATION_REPORT_FOUR_REQUEST, runGetReport4);
}

export function* saga() {
  while (true) {
    yield all({
      getReport1: call(getReport1),
      getReport2: call(getReport2),
      getReport3: call(getReport3),
      getReport4: call(getReport4),
    });
  }
}
