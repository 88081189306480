// external import
import {
  all,
  put,
  call,
  takeLatest,
  takeEvery,
  race,
  delay,
} from "redux-saga/effects";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  ADD_ADMIN_USER_REQUEST,
  ADD_ADMIN_USER_SUCCESS,
  ADD_ADMIN_USER_ERROR,
  EDIT_ADMIN_USER_REQUEST,
  EDIT_ADMIN_USER_SUCCESS,
  EDIT_ADMIN_USER_ERROR,
  GET_ADMIN_USER_REQUEST,
  GET_ADMIN_USER_SUCCESS,
  GET_ADMIN_USER_ERROR,
  GET_SINGLE_ADMIN_USER_REQUEST,
  GET_SINGLE_ADMIN_USER_SUCCESS,
  GET_SINGLE_ADMIN_USER_ERROR,
  DELETE_ADMIN_USER_REQUEST,
  DELETE_ADMIN_USER_SUCCESS,
  DELETE_ADMIN_USER_ERROR,
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 30 * 1000; // 5 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_ADMIN_USER_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "adminUser",
          success: null,
          error: "",
        },
      };
    case ADD_ADMIN_USER_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "adminUser",
          success: true,
          error: "",
        },
      };
    case ADD_ADMIN_USER_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "adminUser",
          success: false,
          error: action.error,
        },
      };

    case EDIT_ADMIN_USER_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "adminUser",
          success: null,
          error: "",
        },
      };
    case EDIT_ADMIN_USER_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "adminUser",
          success: true,
          error: "",
        },
      };
    case EDIT_ADMIN_USER_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "adminUser",
          success: false,
          error: action.error,
        },
      };
    case DELETE_ADMIN_USER_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "adminUser",
          success: null,
          error: "",
        },
        userLinked: null,
      };
    case DELETE_ADMIN_USER_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "adminUser",
          success: true,
          error: "",
        },
        userLinked: action.userLinked,
      };
    case DELETE_ADMIN_USER_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "adminUser",
          success: false,
          error: action.error,
        },
      };

    case GET_ADMIN_USER_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "adminUser",
                success: null,
                error: "",
              },
      };
    case GET_ADMIN_USER_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        adminUsers: action.adminUsers,
        adminUsersCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "adminUser",
                success: true,
                error: "",
              },
      };
    case GET_ADMIN_USER_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "adminUser",
          success: false,
          error: action.error,
        },
      };

    case GET_SINGLE_ADMIN_USER_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "adminUser",
          success: null,
          error: "",
        },
      };
    case GET_SINGLE_ADMIN_USER_SUCCESS:
      return {
        ...newState,
        adminUsers: {
          ...state.adminUsers,
          [action.id]: action.adminUser,
        },
        actionStatus: {
          type: "fetch",
          entity: "adminUser",
          success: true,
          error: "",
        },
      };
    case GET_SINGLE_ADMIN_USER_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "adminUser",
          success: false,
          error: action.error,
        },
      };
    case "LOGOUT_SUCCESS":
      return {};

    default:
      return state;
  }
}

function* runGetSingleAdminUser(action) {
  try {
    const { adminUser } = yield race({
      adminUser: call(api.get, "adminUser", {
        lang: action.lang,
        id: action.id,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (adminUser)
      yield put({
        type: GET_SINGLE_ADMIN_USER_SUCCESS,
        adminUser: adminUser.data ? adminUser.data.data : [],
        id: action.id,
      });
    else
      yield put({
        type: GET_SINGLE_ADMIN_USER_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_SINGLE_ADMIN_USER_ERROR,
      error:
        typeof error === "object" ? error : "Admin User could not be updated.",
    });
  }
}
function* getSingleAdminUser() {
  yield takeLatest(GET_SINGLE_ADMIN_USER_REQUEST, runGetSingleAdminUser);
}

function* runGetAdminUsers(action) {
  try {
    const { adminUser } = yield race({
      adminUser: call(api.get, "adminUser", {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (adminUser) {
      yield put({
        type: GET_ADMIN_USER_SUCCESS,
        source: action.source,
        adminUsers: adminUser?.data?.data?.rows || [],
        count: adminUser?.data?.data?.count || 0,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_ADMIN_USER_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_ADMIN_USER_ERROR,
      error:
        typeof error === "object" ? error : "Admin Users could not be updated.",
    });
  }
}
function* getAdminUsers() {
  yield takeLatest(GET_ADMIN_USER_REQUEST, runGetAdminUsers);
}

function* runAddAdminUser(action) {
  try {
    let extraUrl = action.userType === "staff" ? "Staff" : "";
    const { adminUser } = yield race({
      adminUser: call(api.add, `adminUser${extraUrl}`, {
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (adminUser)
      yield put({
        type: ADD_ADMIN_USER_SUCCESS,
        ...adminUser.data,
      });
    else
      yield put({
        type: ADD_ADMIN_USER_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_ADMIN_USER_ERROR,
      error,
      //error: typeof error === "object" ? error : "Could not add adminUser",
    });
  }
}

function* addAdminUser() {
  yield takeLatest(ADD_ADMIN_USER_REQUEST, runAddAdminUser);
}

function* runEditAdminUser(action) {
  try {
    // Separate adminUser fields from user fields

    // Update user details
    const { users } = yield race({
      users: call(api.update, "adminUser", {
        lang: action.lang,
        id: action.id,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (users)
      yield put({
        type: EDIT_ADMIN_USER_SUCCESS,
        ...users.data,
      });
    else
      yield put({
        type: EDIT_ADMIN_USER_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_ADMIN_USER_ERROR,
      error,
      //error: typeof error === "object" ? error : "Could not edit adminUser",
    });
  }
}
function* editAdminUser() {
  yield takeLatest(EDIT_ADMIN_USER_REQUEST, runEditAdminUser);
}

function* runDeleteAdminUser(action) {
  try {
    const { adminUser } = yield race({
      adminUser: call(
        api.delete,
        `adminUser${action.params?.ids ? "Bulk" : ""}`,
        {
          id: action.params?.id || undefined,
          data: action.params?.ids || undefined,
          lang: action.lang,
        }
      ),
      timeout: delay(parseResponseTimeout),
    });

    if (adminUser) {
      let obj = { type: DELETE_ADMIN_USER_SUCCESS };
      if (
        adminUser?.data?.data &&
        Object.values(adminUser?.data?.data).length > 0
      )
        obj.userLinked = {
          message: adminUser.data.message,
          data: adminUser.data.data,
        };
      yield put(obj);
    } else
      yield put({
        type: DELETE_ADMIN_USER_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_ADMIN_USER_ERROR,
      error: typeof error === "object" ? error : "Could not delete adminUsers",
    });
  }
}
function* deleteAdminUser() {
  yield takeLatest(DELETE_ADMIN_USER_REQUEST, runDeleteAdminUser);
}

export function* saga() {
  while (true) {
    yield all({
      getAdminUsers: call(getAdminUsers),
      getSingleAdminUser: call(getSingleAdminUser),
      addAdminUser: call(addAdminUser),
      editAdminUser: call(editAdminUser),
      deleteAdminUser: call(deleteAdminUser),
    });
  }
}
