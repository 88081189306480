import { all, delay, put, call, takeLatest, race } from "redux-saga/effects";
import api from "../../../utils/api/general";
import {
  GET_BOOKING_TEMPLATE_REQUEST,
  GET_BOOKING_TEMPLATE_SUCCESS,
  GET_BOOKING_TEMPLATE_ERROR,
  ADD_BOOKING_TEMPLATE_REQUEST,
  ADD_BOOKING_TEMPLATE_SUCCESS,
  ADD_BOOKING_TEMPLATE_ERROR,
  EDIT_BOOKING_TEMPLATE_REQUEST,
  EDIT_BOOKING_TEMPLATE_SUCCESS,
  EDIT_BOOKING_TEMPLATE_ERROR,
  DELETE_BOOKING_TEMPLATE_REQUEST,
  DELETE_BOOKING_TEMPLATE_SUCCESS,
  DELETE_BOOKING_TEMPLATE_ERROR,
  GET_PASSENGER_ON_TEMPLATE_REQUEST,
  ADD_PASSENGER_ON_TEMPLATE_REQUEST,
  DELETE_PASSENGER_ON_TEMPLATE_REQUEST,
  DELETE_PASSENGER_ON_TEMPLATE_ERROR,
  DELETE_PASSENGER_ON_TEMPLATE_SUCCESS,
  ADD_PASSENGER_ON_TEMPLATE_ERROR,
  ADD_PASSENGER_ON_TEMPLATE_SUCCESS,
  GET_PASSENGER_ON_TEMPLATE_ERROR,
  GET_PASSENGER_ON_TEMPLATE_SUCCESS,
  GET_STOP_RELATED_TO_ROUTE_REQUEST,
  GET_STOP_RELATED_TO_ROUTE_SUCCESS,
  GET_STOP_RELATED_TO_ROUTE_ERROR,
  REVIEW_SHIFT_PASSENGER_REQUEST,
  REVIEW_SHIFT_PASSENGER_SUCCESS,
  REVIEW_SHIFT_PASSENGER_ERROR,
  ADD_SHIFT_BOOKING_REQUEST,
  ADD_SHIFT_BOOKING_SUCCESS,
  ADD_SHIFT_BOOKING_ERROR,
} from "../constants";
import initialState from "./initialState";
// TODO: is this the best place for this???
const parseResponseTimeout = 50 * 1000; // 50 seconds
const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};
// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});
  switch (action.type) {
    case ADD_BOOKING_TEMPLATE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "shiftTemplate",
          success: null,
          error: "",
        },
      };
    case ADD_BOOKING_TEMPLATE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "shiftTemplate",
          success: true,
          error: "",
        },
      };
    case ADD_BOOKING_TEMPLATE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "shiftTemplate",
          success: false,
          error: action.error,
        },
      };
    case EDIT_BOOKING_TEMPLATE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "shiftTemplate",
          success: null,
          error: "",
        },
      };
    case EDIT_BOOKING_TEMPLATE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "shiftTemplate",
          success: true,
          error: "",
        },
      };
    case EDIT_BOOKING_TEMPLATE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "shiftTemplate",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BOOKING_TEMPLATE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "shiftTemplate",
          success: null,
          error: "",
        },
      };
    case DELETE_BOOKING_TEMPLATE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "shiftTemplate",
          success: true,
          error: "",
        },
      };
    case DELETE_BOOKING_TEMPLATE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "shiftTemplate",
          success: false,
          error: action.error,
        },
      };
    case GET_BOOKING_TEMPLATE_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "shiftTemplate",
                success: null,
                error: "",
              },
      };
    case GET_BOOKING_TEMPLATE_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        templates: action.data,
        templatesCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "shiftTemplate",
                success: true,
                error: "",
              },
      };
    case GET_BOOKING_TEMPLATE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "shiftTemplate",
          success: false,
          error: action.error,
        },
      };

    case ADD_PASSENGER_ON_TEMPLATE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "passengerTemplate",
          success: null,
          error: "",
        },
      };
    case ADD_PASSENGER_ON_TEMPLATE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "passengerTemplate",
          success: true,
          error: "",
        },
      };
    case ADD_PASSENGER_ON_TEMPLATE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "passengerTemplate",
          success: false,
          error: action.error,
        },
      };

    case DELETE_PASSENGER_ON_TEMPLATE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "passengerTemplate",
          success: null,
          error: "",
        },
      };
    case DELETE_PASSENGER_ON_TEMPLATE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "passengerTemplate",
          success: true,
          error: "",
        },
      };
    case DELETE_PASSENGER_ON_TEMPLATE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "passengerTemplate",
          success: false,
          error: action.error,
        },
      };
    case GET_PASSENGER_ON_TEMPLATE_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "passengerTemplate",
                success: null,
                error: "",
              },
      };
    case GET_PASSENGER_ON_TEMPLATE_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        templatePassenger: action.data,
        templatePassengerCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "passengerTemplate",
                success: true,
                error: "",
              },
      };
    case GET_PASSENGER_ON_TEMPLATE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "passengerTemplate",
          success: false,
          error: action.error,
        },
      };

    case GET_STOP_RELATED_TO_ROUTE_REQUEST:
      return {
        ...newState,
        // actionStatus: {
        //   type: "fetch",
        //   entity: "stopRelatedToRoute",
        //   success: null,
        //   error: "",
        // },
      };
    case GET_STOP_RELATED_TO_ROUTE_SUCCESS:
      return {
        ...newState,
        stops: action.data,
        // actionStatus: {
        //   type: "fetch",
        //   entity: "stopRelatedToRoute",
        //   success: true,
        //   error: "",
        // },
      };
    case GET_STOP_RELATED_TO_ROUTE_ERROR:
      return {
        ...newState,
        // actionStatus: {
        //   type: "fetch",
        //   entity: "stopRelatedToRoute",
        //   success: false,
        //   error: action.error,
        // },
      };

    case REVIEW_SHIFT_PASSENGER_REQUEST:
      return {
        ...newState,
        passengerReview: null,
        eligiblePassenger: "",
        actionStatus: {
          type: "check",
          entity: "shiftBooking",
          success: null,
          error: "",
        },
      };
    case REVIEW_SHIFT_PASSENGER_SUCCESS:
      return {
        ...newState,
        passengerReview: action?.data,
        eligiblePassenger: action.eligiblePassenger,
        actionStatus: {
          type: "check",
          entity: "shiftBooking",
          success: true,
          error: "",
        },
      };
    case REVIEW_SHIFT_PASSENGER_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "check",
          entity: "shiftBooking",
          success: false,
          error: action.error,
        },
      };

    case ADD_SHIFT_BOOKING_REQUEST:
      return {
        ...newState,
        existingBookings: null,
        actionStatus: {
          type: "add",
          entity: "shiftBooking",
          success: null,
          error: "",
        },
      };
    case ADD_SHIFT_BOOKING_SUCCESS:
      return {
        ...newState,
        existingBookings: action.existing_bookings,
        actionStatus: {
          type: "add",
          entity: "shiftBooking",
          success: true,
          error: "",
        },
      };
    case ADD_SHIFT_BOOKING_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "shiftBooking",
          success: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}

function* runGetBookingTemplates(action) {
  try {
    let url = "";

    const { response } = yield race({
      response: call(api.get, `shiftTemplate${url}`, {
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response) {
      const res = response?.data?.data || [];
      yield put({
        type: GET_BOOKING_TEMPLATE_SUCCESS,
        data: res.rows,
        count: res.count,
        source: action.source,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_BOOKING_TEMPLATE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_BOOKING_TEMPLATE_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Shift Templates could not be updated.",
    });
  }
}
function* getBookingTemplates() {
  yield takeLatest(GET_BOOKING_TEMPLATE_REQUEST, runGetBookingTemplates);
}

function* runAddBookingTemplate(action) {
  try {
    const { response } = yield race({
      response: call(api.add, "shiftTemplate", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response)
      yield put({
        type: ADD_BOOKING_TEMPLATE_SUCCESS,
        ...response.data,
      });
    else
      yield put({
        type: ADD_BOOKING_TEMPLATE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_BOOKING_TEMPLATE_ERROR,
      error, // error: typeof error === "object" ? error : "Could not add booking",
    });
  }
}
function* addBookingTemplate() {
  yield takeLatest(ADD_BOOKING_TEMPLATE_REQUEST, runAddBookingTemplate);
}

function* runEditBookingTemplate(action) {
  try {
    const { response } = yield race({
      response: call(api.update, "shiftTemplate", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response)
      yield put({
        type: EDIT_BOOKING_TEMPLATE_SUCCESS,
        ...response.data,
      });
    else
      yield put({
        type: EDIT_BOOKING_TEMPLATE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_BOOKING_TEMPLATE_ERROR,
      error, //  error: typeof error === "object" ? error : "Could not edit booking",
    });
  }
}
function* editBookingTemplate() {
  yield takeLatest(EDIT_BOOKING_TEMPLATE_REQUEST, runEditBookingTemplate);
}

function* runDeleteBookingTemplate(action) {
  try {
    const { response } = yield race({
      response: call(
        api.delete,
        `shiftTemplate${action.params?.ids ? "Bulk" : ""}`,
        {
          id: action.params?.id || undefined,
          data: action.params?.ids || undefined,
          lang: action.lang,
        }
      ),
      timeout: delay(parseResponseTimeout),
    });

    if (response) yield put({ type: DELETE_BOOKING_TEMPLATE_SUCCESS });
    else
      yield put({
        type: DELETE_BOOKING_TEMPLATE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BOOKING_TEMPLATE_ERROR,
      error:
        typeof error === "object" ? error : "Could not delete shift template",
    });
  }
}
function* deleteBookingTemplate() {
  yield takeLatest(DELETE_BOOKING_TEMPLATE_REQUEST, runDeleteBookingTemplate);
}
function* runGetPassengerOnTemplate(action) {
  try {
    let url = "";

    const { response } = yield race({
      response: call(api.get, `shiftTemplatePassenger${url}`, {
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response) {
      const res = response?.data?.data || [];
      yield put({
        type: GET_PASSENGER_ON_TEMPLATE_SUCCESS,
        data: res.rows,
        count: res.count,
        source: action.source,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_PASSENGER_ON_TEMPLATE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_PASSENGER_ON_TEMPLATE_ERROR,
      error:
        typeof error === "object" ? error : "Passengers could not be updated.",
    });
  }
}
function* getPassengerOnTemplate() {
  yield takeLatest(
    GET_PASSENGER_ON_TEMPLATE_REQUEST,
    runGetPassengerOnTemplate
  );
}

function* runAddPassengerOnTemplate(action) {
  try {
    const { response } = yield race({
      response: call(api.add, "shiftTemplatePassengerAdd", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response)
      yield put({
        type: ADD_PASSENGER_ON_TEMPLATE_SUCCESS,
        ...response.data,
      });
    else
      yield put({
        type: ADD_PASSENGER_ON_TEMPLATE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_PASSENGER_ON_TEMPLATE_ERROR,
      error, // error: typeof error === "object" ? error : "Could not add booking",
    });
  }
}
function* addPassengerOnTemplate() {
  yield takeLatest(
    ADD_PASSENGER_ON_TEMPLATE_REQUEST,
    runAddPassengerOnTemplate
  );
}

function* runDeletePassengerOnTemplate(action) {
  try {
    const { response } = yield race({
      response: call(
        api.delete,
        `shiftTemplatePassengerRemove${action.params?.bulk ? "Bulk" : ""}`,
        {
          data: action.params?.ids || undefined,
          lang: action.lang,
        }
      ),
      timeout: delay(parseResponseTimeout),
    });

    if (response) yield put({ type: DELETE_PASSENGER_ON_TEMPLATE_SUCCESS });
    else
      yield put({
        type: DELETE_PASSENGER_ON_TEMPLATE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_PASSENGER_ON_TEMPLATE_ERROR,
      error:
        typeof error === "object" ? error : "Could not delete passenger(s)",
    });
  }
}
function* deletePassengerOnTemplate() {
  yield takeLatest(
    DELETE_PASSENGER_ON_TEMPLATE_REQUEST,
    runDeletePassengerOnTemplate
  );
}

function* runStopRelateToRoute(action) {
  try {
    let url = "";
    const { response } = yield race({
      response: call(api.get, `route${url}`, {
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response) {
      const res = response?.data?.data || [];
      yield put({
        type: GET_STOP_RELATED_TO_ROUTE_SUCCESS,
        data: res.rows?.[0]?.stops?.map((i) => i?.stop),
      });
    } else
      yield put({
        type: GET_STOP_RELATED_TO_ROUTE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_STOP_RELATED_TO_ROUTE_ERROR,
      error:
        typeof error === "object" ? error : "Passengers could not be updated.",
    });
  }
}
function* getStopRelateToRoute() {
  yield takeLatest(GET_STOP_RELATED_TO_ROUTE_REQUEST, runStopRelateToRoute);
}

function* runReviewShiftPassengers(action) {
  try {
    const { response } = yield race({
      response: call(api.add, "checkShiftTemplates", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response) {
      const res = response?.data;

      yield put({
        type: REVIEW_SHIFT_PASSENGER_SUCCESS,
        data: res?.data || [],
        eligiblePassenger: res?.eligible_for_booking,
      });
    } else
      yield put({
        type: REVIEW_SHIFT_PASSENGER_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: REVIEW_SHIFT_PASSENGER_ERROR,
      error: error, // error: typeof error === "object" ? error : "Could not add booking",
    });
  }
}
function* reviewShiftPassengers() {
  yield takeLatest(REVIEW_SHIFT_PASSENGER_REQUEST, runReviewShiftPassengers);
}

function* runAddShiftBooking(action) {
  try {
    const { response } = yield race({
      response: call(api.add, "shiftBooking", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response) {
      const res = response?.data?.data || [];

      yield put({
        type: ADD_SHIFT_BOOKING_SUCCESS,
        data: res,
        ...response.data,
      });
    } else
      yield put({
        type: ADD_SHIFT_BOOKING_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_SHIFT_BOOKING_ERROR,
      error: error, // error: typeof error === "object" ? error : "Could not add booking",
    });
  }
}
function* addShiftBooking() {
  yield takeLatest(ADD_SHIFT_BOOKING_REQUEST, runAddShiftBooking);
}

export function* saga() {
  while (true) {
    yield all({
      getBookingTemplate: call(getBookingTemplates),
      addBookingTemplate: call(addBookingTemplate),
      editBookingTemplate: call(editBookingTemplate),
      deleteBookingTemplate: call(deleteBookingTemplate),
      getPassengerOnTemplate: call(getPassengerOnTemplate),
      getStopRelateToRoute: call(getStopRelateToRoute),
      addPassengerOnTemplate: call(addPassengerOnTemplate),
      deletePassengerOnTemplate: call(deletePassengerOnTemplate),
      reviewShiftPassengers: call(reviewShiftPassengers),
      addShiftBooking: call(addShiftBooking),
    });
  }
}
