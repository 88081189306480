import { take, put, all, call, delay } from "redux-saga/effects";
// import { REHYDRATE } from "redux-persist/lib/constants";

import { saga as loginSaga } from "../modules/core/states/loginState";
import { saga as vehicleLoginSaga } from "../modules/core/states/vehicleLoginState";
import { saga as dashboardSaga } from "../modules/core/states/dashboardState";
import { saga as contractorSaga } from "../modules/users/states/contractorState";
import { saga as staffSaga } from "../modules/users/states/staffState";
import { saga as approverSaga } from "../modules/users/states/approverState";
import { saga as adminUserSaga } from "../modules/users/states/adminState";
import { saga as contractFocalPointSaga } from "../modules/users/states/contractFocalPointState";
import { saga as driverSaga } from "../modules/users/states/driverState";
import { saga as focalPointSaga } from "../modules/users/states/focalPointState";
import { saga as busOperatorSaga } from "../modules/users/states/busOperatorState";
import { saga as userSaga } from "../modules/users/states/userState";
import { saga as organizationSaga } from "../modules/organizations/states/organizationState";
import { saga as departmentSaga } from "../modules/organizations/states/departmentState";
import { saga as directorateSaga } from "../modules/organizations/states/directorateState";
import { saga as vehicleSaga } from "../modules/vehicles/states/vehicleState";
import { saga as hardwareSaga } from "../modules/hardware/states/hardwareState";
import { saga as batchSaga } from "../modules/hardware/states/batchState";
import { saga as settingsSaga } from "../modules/settings/states/settingsState";
import { saga as trackingSaga } from "../modules/tracking/states/trackingState";
import { saga as reportSaga } from "../modules/reports/states/reportState";
import { saga as utilizationSaga } from "../modules/reports/states/utilizationState";
import { saga as regionSaga } from "../modules/regions/states/regionState";
import { saga as bookingSaga } from "../modules/bookings/states/bookingState";
import { saga as shiftTemplateSaga } from "../modules/bookings/states/shiftBookingState";
import { saga as stopSaga } from "../modules/stops/states/stopState";
import { saga as routeSaga } from "../modules/routes/states/routeState";
import { saga as tripSaga } from "../modules/trips/states/tripState";
import { saga as contractSaga } from "../modules/contracts/states/contractState";
import { saga as manifestSaga } from "../modules/misc/states/manifestState";
import { saga as vehicleManifestSaga } from "../modules/misc/states/vehicleManifestState";
import { saga as miscSaga } from "../modules/misc/states/miscState";
import { saga as requestSaga } from "../modules/requests/states/requestState";
import { saga as notificationSaga } from "../modules/notifications/states/notificationState";
import { saga as parkRideSaga } from "../modules/parks/states/parkState";
import { saga as scheduleSaga } from "../modules/parks/states/scheduleState";

export default function* rootSaga() {
  yield take("persist/REHYDRATE"); // Wait for rehydrate to prevent sagas from running with empty store
  // yield take(`persist/${REHYDRATE}`); // Wait for rehydrate to prevent sagas from running with empty store
  // yield delay(1000);

  // yield call(test);
  yield all([
    loginSaga(),
    vehicleLoginSaga(), //vehicle
    dashboardSaga(),
    contractorSaga(),
    staffSaga(),
    approverSaga(),
    adminUserSaga(),
    contractFocalPointSaga(),
    driverSaga(),
    focalPointSaga(),
    busOperatorSaga(),
    userSaga(),
    organizationSaga(),
    directorateSaga(),
    departmentSaga(),
    vehicleSaga(),
    hardwareSaga(),
    batchSaga(),
    settingsSaga(),
    trackingSaga(),
    reportSaga(),
    utilizationSaga(),
    regionSaga(),
    bookingSaga(),
    shiftTemplateSaga(),
    stopSaga(),
    routeSaga(),
    contractSaga(),
    tripSaga(),
    manifestSaga(),
    vehicleManifestSaga(), //vehicle
    miscSaga(),
    requestSaga(),
    notificationSaga(),
    parkRideSaga(),
    scheduleSaga(),
  ]);
}
