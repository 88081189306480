import { reducer as trackingReducers } from "./trackingState";
// import { reducer as loggingUser } from './login'

const core = [
  trackingReducers,
  // loggingUser
];

export default function trackingReducer(state, action, i) {
  if (!Number.isInteger(i)) i = 0;
  if (i < core.length)
    return trackingReducer(core[i](state, action), action, i + 1);
  else return state;
}
