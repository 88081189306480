const initialState = {
  bookings: [],
  bookingsCount: 0,
  parkingBookingsHistory: [],
  parkingBookingsHistoryCount: 0,
  templates: [],
  templatesCount: 0,
  actionStatus: {
    action: "",
    entity: "",
    success: null,
    error: "",
  },
};

export default initialState;
