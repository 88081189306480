export const NOTIFICATION_SUBSCRIBE_REQUEST = "NOTIFICATION_SUBSCRIBE_REQUEST";
export const NOTIFICATION_SUBSCRIBE_SUCCESS = "NOTIFICATION_SUBSCRIBE_SUCCESS";
export const NOTIFICATION_SUBSCRIBE_ERROR = "NOTIFICATION_SUBSCRIBE_ERROR";

export const NOTIFICATION_UNSUBSCRIBE_REQUEST =
  "NOTIFICATION_UNSUBSCRIBE_REQUEST";
export const NOTIFICATION_UNSUBSCRIBE_SUCCESS =
  "NOTIFICATION_UNSUBSCRIBE_SUCCESS";
export const NOTIFICATION_UNSUBSCRIBE_ERROR = "NOTIFICATION_UNSUBSCRIBE_ERROR";

// export const CONTACT_INFO_REQUEST = "CONTACT_INFO_REQUEST";
// export const CONTACT_INFO_SUCCESS = "CONTACT_INFO_SUCCESS";
// export const CONTACT_INFO_ERROR = "CONTACT_INFO_ERROR";

export const SETTING_REQUEST = "SETTING_REQUEST";
export const SETTING_SUCCESS = "SETTING_SUCCESS";
export const SETTING_ERROR = "SETTING_ERROR";

export const EDIT_SETTING_REQUEST = "EDIT_SETTING_REQUEST";
export const EDIT_SETTING_SUCCESS = "EDIT_SETTING_SUCCESS";
export const EDIT_SETTING_ERROR = "EDIT_SETTING_ERROR";

export const UPLOAD_MANIFEST_REQUEST = "UPLOAD_MANIFEST_REQUEST";
export const UPLOAD_MANIFEST_SUCCESS = "UPLOAD_MANIFEST_SUCCESS";
export const UPLOAD_MANIFEST_ERROR = "UPLOAD_MANIFEST_ERROR";

export const GET_MANIFEST_REQUEST = "GET_MANIFEST_REQUEST";
export const GET_MANIFEST_SUCCESS = "GET_MANIFEST_SUCCESS";
export const GET_MANIFEST_ERROR = "GET_MANIFEST_ERROR";

export const REMOVE_MANIFEST_REQUEST = "REMOVE_MANIFEST_REQUEST";
export const REMOVE_MANIFEST_SUCCESS = "REMOVE_MANIFEST_SUCCESS";
export const REMOVE_MANIFEST_ERROR = "REMOVE_MANIFEST_ERROR";

export const CLEAR_SETTING_ACTION_STATUS = "CLEAR_SETTING_ACTION_STATUS";
