export const GET_TRIP_REQUEST = "GET_TRIP_REQUEST";
export const GET_TRIP_SUCCESS = "GET_TRIP_SUCCESS";
export const GET_TRIP_ERROR = "GET_TRIP_ERROR";

export const ADD_TRIP_REQUEST = "ADD_TRIP_REQUEST";
export const ADD_TRIP_SUCCESS = "ADD_TRIP_SUCCESS";
export const ADD_TRIP_ERROR = "ADD_TRIP_ERROR";

export const ADD_RECURRING_TRIP_REQUEST = "ADD_RECURRING_TRIP_REQUEST";
export const ADD_RECURRING_TRIP_SUCCESS = "ADD_RECURRING_TRIP_SUCCESS";
export const ADD_RECURRING_TRIP_ERROR = "ADD_RECURRING_TRIP_ERROR";

export const EDIT_TRIP_REQUEST = "EDIT_TRIP_REQUEST";
export const EDIT_TRIP_SUCCESS = "EDIT_TRIP_SUCCESS";
export const EDIT_TRIP_ERROR = "EDIT_TRIP_ERROR";

export const DELETE_TRIP_REQUEST = "DELETE_TRIP_REQUEST";
export const DELETE_TRIP_SUCCESS = "DELETE_TRIP_SUCCESS";
export const DELETE_TRIP_ERROR = "DELETE_TRIP_ERROR";

export const DELETE_BULK_TRIP_REQUEST = "DELETE_BULK_TRIP_REQUEST";
export const DELETE_BULK_TRIP_SUCCESS = "DELETE_BULK_TRIP_SUCCESS";
export const DELETE_BULK_TRIP_ERROR = "DELETE_BULK_TRIP_ERROR";

export const CLEAR_TRIPS_ACTION_STATUS = "CLEAR_TRIPS_ACTION_STATUS";
export const BULK_EDIT_TRIPS_FIELDS = "BULK_EDIT_TRIPS_FIELDS";
