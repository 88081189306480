import { all, put, call, takeLatest, race, delay } from "redux-saga/effects";
import api from "../../../utils/api/general";
import {
  GET_USER_TYPE_STAT_REQUEST,
  GET_USER_TYPE_STAT_SUCCESS,
  GET_USER_TYPE_STAT_ERROR,
  GET_ROUTE_STAT_REQUEST,
  GET_ROUTE_STAT_SUCCESS,
  GET_ROUTE_STAT_ERROR,
  GET_COST_STAT_REQUEST,
  GET_COST_STAT_SUCCESS,
  GET_COST_STAT_ERROR,
  GET_SEAT_UTILIZATION_STAT_REQUEST,
  GET_SEAT_UTILIZATION_STAT_SUCCESS,
  GET_SEAT_UTILIZATION_STAT_ERROR,
  GET_SEAT_UTIL_CHART_STAT_REQUEST,
  GET_SEAT_UTIL_CHART_STAT_SUCCESS,
  GET_SEAT_UTIL_CHART_STAT_ERROR,
  GET_BOOKING_STAT_REQUEST,
  GET_BOOKING_STAT_SUCCESS,
  GET_BOOKING_STAT_ERROR,
  PERSIST_DASHBOARD_FILTERS,
} from "../constants";
const parseResponseTimeout = 50 * 1000; // 50 seconds
const isDev = process.env.NODE_ENV === "development";
export function reducer(state = {}, action) {
  const newState = Object.assign({}, state, {});
  switch (action.type) {
    case GET_USER_TYPE_STAT_REQUEST:
      return {
        ...newState,
        userTypeStat: {
          ...(newState?.userTypeStat || {}),
          success: null,
          error: "",
        },
      };
    case GET_USER_TYPE_STAT_SUCCESS:
      return {
        ...newState,
        userTypeStat: {
          success: true,
          error: "",
          stat: action.stat,
        },
      };
    case GET_USER_TYPE_STAT_ERROR:
      return {
        ...newState,
        userTypeStat: {
          ...(newState?.userTypeStat || {}),
          success: false,
          error: action.error,
        },
      };

    case GET_ROUTE_STAT_REQUEST:
      return {
        ...newState,
        routeStat: {
          ...(newState?.routeStat || {}),
          success: null,
          error: "",
        },
      };
    case GET_ROUTE_STAT_SUCCESS:
      return {
        ...newState,
        routeStat: {
          success: true,
          error: "",
          stat: action.stat,
        },
      };
    case GET_ROUTE_STAT_ERROR:
      return {
        ...newState,
        routeStat: {
          ...(newState?.routeStat || {}),
          success: false,
          error: action.error,
        },
      };
    case GET_COST_STAT_REQUEST:
      return {
        ...newState,
        costStat: {
          ...(newState?.costStat || {}),
          success: null,
          error: "",
        },
      };
    case GET_COST_STAT_SUCCESS:
      return {
        ...newState,
        costStat: {
          success: true,
          error: "",
          stat: action.stat,
        },
      };
    case GET_COST_STAT_ERROR:
      return {
        ...newState,
        costStat: {
          ...(newState?.costStat || {}),
          success: false,
          error: action.error,
        },
      };
    case GET_SEAT_UTILIZATION_STAT_REQUEST:
      return {
        ...newState,
        seatUtilStat: {
          ...(newState?.seatUtilStat || {}),
          success: null,
          error: "",
        },
      };
    case GET_SEAT_UTILIZATION_STAT_SUCCESS:
      return {
        ...newState,
        seatUtilStat: {
          success: true,
          error: "",
          stat: action.stat,
        },
      };
    case GET_SEAT_UTILIZATION_STAT_ERROR:
      return {
        ...newState,
        seatUtilStat: {
          ...(newState?.seatUtilStat || {}),
          success: false,
          error: action.error,
        },
      };
    case GET_SEAT_UTIL_CHART_STAT_REQUEST:
      return {
        ...newState,
        seatUtilChartStat: {
          ...(newState?.seatUtilChartStat || {}),
          success: null,
          error: "",
        },
      };
    case GET_SEAT_UTIL_CHART_STAT_SUCCESS:
      return {
        ...newState,
        seatUtilChartStat: {
          success: true,
          error: "",
          stat: action.stat,
        },
      };
    case GET_SEAT_UTIL_CHART_STAT_ERROR:
      return {
        ...newState,
        seatUtilChartStat: {
          ...(newState?.seatUtilChartStat || {}),
          success: false,
          error: action.error,
        },
      };
    case GET_BOOKING_STAT_REQUEST:
      return {
        ...newState,
        bookingStat: {
          ...(newState?.bookingStat || {}),
          success: null,
          error: "",
        },
      };
    case GET_BOOKING_STAT_SUCCESS:
      return {
        ...newState,
        bookingStat: {
          success: true,
          error: "",
          stat: action.stat,
        },
      };
    case GET_BOOKING_STAT_ERROR:
      return {
        ...newState,
        bookingStat: {
          ...(newState?.bookingStat || {}),
          success: false,
          error: action.error,
        },
      };
    case PERSIST_DASHBOARD_FILTERS:
      return {
        ...newState,
        dashboardFilters: {
          ...action.params,
        },
      };
    default:
      return state;
  }
}

function* runGetUserTypeStat(action) {
  try {
    const { stat } = yield race({
      stat: call(api.get, "userTypeStat", {
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (stat) {
      const statistics = stat.data ? stat.data.data : [];
      yield put({
        type: GET_USER_TYPE_STAT_SUCCESS,
        stat: statistics,
      });
    } else
      yield put({
        type: GET_USER_TYPE_STAT_ERROR,
        error: "timeout",
      });
  } catch (error) {
    yield put({
      type: GET_USER_TYPE_STAT_ERROR,
      error: error,
    });
  }
}
function* getUserTypeStat() {
  yield takeLatest(GET_USER_TYPE_STAT_REQUEST, runGetUserTypeStat);
}

function* runGetRouteStat(action) {
  try {
    const { stat } = yield race({
      stat: call(api.get, "routeStat", {
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (stat) {
      const statistics = stat?.data || {};
      yield put({
        type: GET_ROUTE_STAT_SUCCESS,
        // stat: { stat: statistics.data, keys: statistics.keys },
        stat: statistics.data,
      });
    } else
      yield put({
        type: GET_ROUTE_STAT_ERROR,
        error: "timeout",
      });
  } catch (error) {
    yield put({
      type: GET_ROUTE_STAT_ERROR,
      error: error,
    });
  }
}
function* getRouteStat() {
  yield takeLatest(GET_ROUTE_STAT_REQUEST, runGetRouteStat);
}

function* runGetCostStat(action) {
  try {
    const { stat } = yield race({
      stat: call(api.get, "costStat", {
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (stat) {
      const statistics = stat?.data || {};
      yield put({
        type: GET_COST_STAT_SUCCESS,
        // stat: { stat: statistics.data, keys: statistics.keys },
        stat: statistics.data,
      });
    } else
      yield put({
        type: GET_COST_STAT_ERROR,
        error: "timeout",
      });
  } catch (error) {
    yield put({
      type: GET_COST_STAT_ERROR,
      error: error,
    });
  }
}
function* getCostStat() {
  yield takeLatest(GET_COST_STAT_REQUEST, runGetCostStat);
}

function* runGetSeatUtilStat(action) {
  try {
    const { stat } = yield race({
      stat: call(api.get, "seatUtilStat", {
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (stat) {
      const statistics = stat?.data || {};
      yield put({
        type: GET_SEAT_UTILIZATION_STAT_SUCCESS,
        // stat: { stat: statistics.data, keys: statistics.keys },
        stat: statistics.data,
      });
    } else
      yield put({
        type: GET_SEAT_UTILIZATION_STAT_ERROR,
        error: "timeout",
      });
  } catch (error) {
    yield put({
      type: GET_SEAT_UTILIZATION_STAT_ERROR,
      error: error,
    });
  }
}
function* getSeatUtilStat() {
  yield takeLatest(GET_SEAT_UTILIZATION_STAT_REQUEST, runGetSeatUtilStat);
}

function* runGetSeatUtilChartStat(action) {
  try {
    const { stat } = yield race({
      stat: call(api.get, "seatUtilChartStat", {
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (stat) {
      const statistics = stat?.data || {};
      yield put({
        type: GET_SEAT_UTIL_CHART_STAT_SUCCESS,
        // stat: { stat: statistics.data, keys: statistics.keys },
        stat: statistics.data,
      });
    } else
      yield put({
        type: GET_SEAT_UTIL_CHART_STAT_ERROR,
        error: "timeout",
      });
  } catch (error) {
    yield put({
      type: GET_SEAT_UTIL_CHART_STAT_ERROR,
      error: error,
    });
  }
}
function* getSeatUtilChartStat() {
  yield takeLatest(GET_SEAT_UTIL_CHART_STAT_REQUEST, runGetSeatUtilChartStat);
}

function* runGetBookingStat(action) {
  try {
    const { stat } = yield race({
      stat: call(api.get, "bookingStat", {
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (stat) {
      const statistics = stat?.data || {};
      yield put({
        type: GET_BOOKING_STAT_SUCCESS,
        // stat: { stat: statistics.data, keys: statistics.keys },
        stat: statistics.data,
      });
    } else
      yield put({
        type: GET_BOOKING_STAT_ERROR,
        error: "timeout",
      });
  } catch (error) {
    yield put({
      type: GET_BOOKING_STAT_ERROR,
      error: error,
    });
  }
}
function* getBookingStat() {
  yield takeLatest(GET_BOOKING_STAT_REQUEST, runGetBookingStat);
}

export function* saga() {
  while (true) {
    yield all({
      getCostStat: call(getCostStat),
      getRouteStat: call(getRouteStat),
      getBookingStat: call(getBookingStat),
      getUserTypeStat: call(getUserTypeStat),
      getSeatUtilStat: call(getSeatUtilStat),
      getSeatUtilChartStat: call(getSeatUtilChartStat),
    });
  }
}
