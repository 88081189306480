import axios from "axios";
import config, { staticService } from "../config/config.js";

//TODO: change to https once the backend link is changed

export const apiURL = (lang = "", service = "core", admin = false) => {
  // console.log({ Api: config });
  return `${config[`${service}Service`].ssl ? "https" : "http"}://${
    config[`${service}Service`].hostname
  }:${config[`${service}Service`].port}${
    config[`${service}Service`]?.apiPrefix
      ? "/" + config[`${service}Service`]?.apiPrefix
      : ""
  }`;

  // return `${config[`${service}Service`].ssl ? "https" : "http"}://${
  //   config[`${service}Service`].hostname
  // }:${config[`${service}Service`].port}/${(config[`${service}Service`] &&
  //   config[`${service}Service`].apiPrefix) ||
  //   config.app.apiPrefix}/${lang}${admin ? "/admin" : ""}`;
  // return `http://35.193.75.162:3000`;
  // return `https://mytrip-dev.emushrif.om/api`;
  // return `http://mytrip-dev.emushrif.om/api`;
};

export const apiURLDev = (lang = "en", service = "core") =>
  `${config[`${service}Service`].ssl ? "https" : "http"}://${
    config[`${service}Service`].hostname
  }:${config[`${service}Service`].portDev}/${(config[`${service}Service`] &&
    config[`${service}Service`].apiPrefix) ||
    config.app.apiPrefix}/${lang}/admin`;

export const staticURL = (directory = "") =>
  `${staticService.ssl ? "https" : "http"}://${staticService.hostname}:${
    staticService.port
  }/${staticService.rootPath}${
    directory ? "/" + staticService[directory] : ""
  }`;

import { handleError } from "./handleError";

export const getUser = async ({ lang }) => {
  try {
    return await axios.get(`${apiURL(lang)}/user`, { withCredentials: true });
  } catch (error) {
    handleError(error);
  }
};
