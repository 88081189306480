const initialState = {
  organizations: [],
  organizationsCount: 0,
  departments: [],
  directorates: [],
  departmentsCount: 0,
  actionStatus: {
    action: "",
    entity: "",
    success: null,
    error: "",
  },
  filters: {},
};

export default initialState;
