import { reducer as miscReducers } from "./miscState";
// import { reducer as loggingUser } from './login'

const core = [
  miscReducers,
  // loggingUser
];

export default function miscReducer(state, action, i) {
  if (!Number.isInteger(i)) i = 0;
  if (i < core.length)
    return miscReducer(core[i](state, action), action, i + 1);
  else return state;
}
