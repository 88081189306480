// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";
import moment from "moment";

// internal imports
import api from "../../../utils/api/general";
import { snakeToCamel } from "../../../utils/stringHelpers";

// get action names
import {
  ADD_ORGANIZATION_REQUEST,
  ADD_ORGANIZATION_SUCCESS,
  ADD_ORGANIZATION_ERROR,
  EDIT_ORGANIZATION_REQUEST,
  EDIT_ORGANIZATION_SUCCESS,
  EDIT_ORGANIZATION_ERROR,
  GET_ORGANIZATION_REQUEST,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_ERROR,
  DELETE_ORGANIZATION_REQUEST,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_ERROR,
  DELETE_BULK_ORGANIZATION_REQUEST,
  DELETE_BULK_ORGANIZATION_SUCCESS,
  DELETE_BULK_ORGANIZATION_ERROR,
  // smsCodeTimeout
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 50 * 1000; // 50 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_ORGANIZATION_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "organization",
          success: null,
          error: "",
        },
      };
    case ADD_ORGANIZATION_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "organization",
          success: true,
          error: "",
        },
      };
    case ADD_ORGANIZATION_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "organization",
          success: false,
          error: action.error,
        },
      };

    case EDIT_ORGANIZATION_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "organization",
          success: null,
          error: "",
        },
      };
    case EDIT_ORGANIZATION_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "organization",
          success: true,
          error: "",
        },
      };
    case EDIT_ORGANIZATION_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "organization",
          success: false,
          error: action.error,
        },
      };
    case DELETE_ORGANIZATION_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "organization",
          success: null,
          error: "",
        },
      };
    case DELETE_ORGANIZATION_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "organization",
          success: true,
          error: "",
        },
      };
    case DELETE_ORGANIZATION_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "organization",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BULK_ORGANIZATION_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "organization",
          success: null,
          error: "",
        },
      };
    case DELETE_BULK_ORGANIZATION_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "organization",
          success: true,
          error: "",
        },
      };
    case DELETE_BULK_ORGANIZATION_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "organization",
          success: false,
          error: action.error,
        },
      };

    case GET_ORGANIZATION_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "organization",
                success: null,
                error: "",
              },
      };
    case GET_ORGANIZATION_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        [(action.orgType && snakeToCamel(action.orgType)) ||
        "organizations"]: action.organizations,
        [`${(action.orgType && snakeToCamel(action.orgType)) ||
          "organizations"}Count`]: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "organization",
                success: true,
                error: "",
              },
      };
    case GET_ORGANIZATION_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "organization",
          success: false,
          error: action.error,
        },
      };

    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetOrganizations(action) {
  try {
    const { organization } = yield race({
      organization: call(api.get, "organization", {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (organization) {
      const organizations = organization.data ? organization.data.data : [];
      let orgType = action.params && action.params.type;
      orgType =
        orgType && String(orgType).includes(",") ? "organizations" : orgType;

      yield put({
        type: GET_ORGANIZATION_SUCCESS,
        source: action.source,
        organizations: organizations.rows,
        count: organizations.count,
        orgType,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_ORGANIZATION_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_ORGANIZATION_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Organizations could not be updated.",
    });
  }
}
function* getOrganizations() {
  yield takeLatest(GET_ORGANIZATION_REQUEST, runGetOrganizations);
}

function* runAddOrganization(action) {
  try {
    const { organization } = yield race({
      organization: call(api.add, "organization", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (organization)
      yield put({
        type: ADD_ORGANIZATION_SUCCESS,
        ...organization.data,
      });
    else
      yield put({
        type: ADD_ORGANIZATION_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_ORGANIZATION_ERROR,
      error, //error: typeof error === "object" ? error : "Could not add organization",
    });
  }
}
function* addOrganization() {
  yield takeLatest(ADD_ORGANIZATION_REQUEST, runAddOrganization);
}

function* runEditOrganization(action) {
  try {
    const { organization } = yield race({
      organization: call(api.update, "organization", {
        lang: action.lang,
        id: action.id,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (organization)
      yield put({
        type: EDIT_ORGANIZATION_SUCCESS,
        ...organization.data,
      });
    else
      yield put({
        type: EDIT_ORGANIZATION_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_ORGANIZATION_ERROR,
      error, //error: typeof error === "object" ? error : "Could not edit organization",
    });
  }
}
function* editOrganization() {
  yield takeLatest(EDIT_ORGANIZATION_REQUEST, runEditOrganization);
}

function* runDeleteOrganization(action) {
  try {
    const { organization } = yield race({
      organization: call(api.delete, "organization", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (organization) yield put({ type: DELETE_ORGANIZATION_SUCCESS });
    else
      yield put({
        type: DELETE_ORGANIZATION_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_ORGANIZATION_ERROR,
      error:
        typeof error === "object" ? error : "Could not delete organization",
    });
  }
}
function* deleteOrganization() {
  yield takeLatest(DELETE_ORGANIZATION_REQUEST, runDeleteOrganization);
}

function* runDeleteBulkOrganization(action) {
  try {
    const { organization } = yield race({
      organization: call(api.delete, "organization", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (organization) yield put({ type: DELETE_BULK_ORGANIZATION_SUCCESS });
    else
      yield put({
        type: DELETE_BULK_ORGANIZATION_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BULK_ORGANIZATION_ERROR,
      error:
        typeof error === "object" ? error : "Could not delete organization",
    });
  }
}
function* deleteBulkOrganization() {
  yield takeEvery(DELETE_BULK_ORGANIZATION_REQUEST, runDeleteBulkOrganization);
}

export function* saga() {
  while (true) {
    yield all({
      getOrganizations: call(getOrganizations),
      addOrganization: call(addOrganization),
      editOrganization: call(editOrganization),
      deleteOrganization: call(deleteOrganization),
      deleteBulkOrganization: call(deleteBulkOrganization),
    });
  }
}
