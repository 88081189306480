const initialState = {
  regions: [],
  actionStatus: {
    action: "",
    entity: "",
    success: null,
    error: "",
  },
};

export default initialState;
