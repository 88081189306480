import { reducer as stopReducers } from "./stopState";
// import { reducer as loggingUser } from './login'

const core = [
  stopReducers,
  // loggingUser
];

export default function stopReducer(state, action, i) {
  if (!Number.isInteger(i)) i = 0;
  if (i < core.length)
    return stopReducer(core[i](state, action), action, i + 1);
  else return state;
}
