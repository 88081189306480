// external import
import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";
// internal imports
import api from "../../../utils/api/general";
// get action names
import {
  ADD_CONTRACT_REQUEST,
  ADD_CONTRACT_SUCCESS,
  ADD_CONTRACT_ERROR,
  EDIT_CONTRACT_REQUEST,
  EDIT_CONTRACT_SUCCESS,
  EDIT_CONTRACT_ERROR,
  GET_CONTRACT_REQUEST,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_ERROR,
  DELETE_CONTRACT_REQUEST,
  DELETE_CONTRACT_SUCCESS,
  DELETE_CONTRACT_ERROR,
  DELETE_BULK_CONTRACT_REQUEST,
  DELETE_BULK_CONTRACT_SUCCESS,
  DELETE_BULK_CONTRACT_ERROR,
  CLEAR_CONTRACTS_ACTION_STATUS,
  GET_ALL_CONTRACT_HOLDERS_REQUEST,
  GET_ALL_CONTRACT_HOLDERS_SUCCESS,
  GET_ALL_CONTRACT_HOLDERS_ERROR,
  // smsCodeTimeout
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 50 * 1000; // 50 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_CONTRACT_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "contract",
          success: null,
          error: "",
        },
      };
    case ADD_CONTRACT_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "contract",
          success: true,
          error: "",
        },
      };
    case ADD_CONTRACT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "contract",
          success: false,
          error: action.error,
        },
      };
    case EDIT_CONTRACT_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "contract",
          success: null,
          error: "",
        },
      };
    case EDIT_CONTRACT_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "contract",
          success: true,
          error: "",
        },
      };
    case EDIT_CONTRACT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "contract",
          success: false,
          error: action.error,
        },
      };
    case DELETE_CONTRACT_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "contract",
          success: null,
          error: "",
        },
      };
    case DELETE_CONTRACT_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "contract",
          success: true,
          error: "",
        },
      };
    case DELETE_CONTRACT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "contract",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BULK_CONTRACT_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "contract",
          success: null,
          error: "",
        },
      };
    case DELETE_BULK_CONTRACT_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "contract",
          success: true,
          error: "",
        },
      };
    case DELETE_BULK_CONTRACT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "contract",
          success: false,
          error: action.error,
        },
      };

    case GET_CONTRACT_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "contract",
                success: null,
                error: "",
              },
      };
    case GET_CONTRACT_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        contracts: action.contracts,
        contractsCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "contract",
                success: true,
                error: "",
              },
      };
    case GET_CONTRACT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "contract",
          success: false,
          error: action.error,
        },
      };

    case GET_ALL_CONTRACT_HOLDERS_REQUEST:
      return {
        ...newState,
      };
    case GET_ALL_CONTRACT_HOLDERS_SUCCESS:
      return {
        ...newState,
        contractHolders: action.data,
        contractHolderCount: action.count,
      };
    case GET_ALL_CONTRACT_HOLDERS_ERROR:
      return {
        ...newState,
      };
    case CLEAR_CONTRACTS_ACTION_STATUS:
      return {
        ...newState,
        actionStatus: { type: "", entity: "", success: null, error: "" },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetContracts(action) {
  try {
    const { contract } = yield race({
      contract: call(api.get, "contract", {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (contract) {
      const contracts = contract.data ? contract.data.data : [];
      yield put({
        type: GET_CONTRACT_SUCCESS,
        source: action.source,
        contracts: contracts.rows,
        count: contracts.count,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_CONTRACT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_CONTRACT_ERROR,
      error:
        typeof error === "object" ? error : "Contracts could not be updated.",
    });
  }
}
function* getContracts() {
  yield takeLatest(GET_CONTRACT_REQUEST, runGetContracts);
}

function* runAddContract(action) {
  try {
    const { contract } = yield race({
      contract: call(api.add, "contract", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (contract)
      yield put({
        type: ADD_CONTRACT_SUCCESS,
        ...contract.data,
      });
    else
      yield put({
        type: ADD_CONTRACT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_CONTRACT_ERROR,
      error, //error: typeof error === "object" ? error : "Could not add contract",
    });
  }
}
function* addContract() {
  yield takeLatest(ADD_CONTRACT_REQUEST, runAddContract);
}

function* runEditContract(action) {
  try {
    const { contract } = yield race({
      contract: call(api.update, "contract", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (contract)
      yield put({
        type: EDIT_CONTRACT_SUCCESS,
        ...contract.data,
      });
    else
      yield put({
        type: EDIT_CONTRACT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_CONTRACT_ERROR,
      error, //error: typeof error === "object" ? error : "Could not edit contract",
    });
  }
}
function* editContract() {
  yield takeLatest(EDIT_CONTRACT_REQUEST, runEditContract);
}

function* runDeleteContract(action) {
  try {
    const { contract } = yield race({
      contract: call(api.delete, "contract", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (contract) yield put({ type: DELETE_CONTRACT_SUCCESS });
    else
      yield put({
        type: DELETE_CONTRACT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_CONTRACT_ERROR,
      error: typeof error === "object" ? error : "Could not delete contract",
    });
  }
}
function* deleteContract() {
  yield takeLatest(DELETE_CONTRACT_REQUEST, runDeleteContract);
}

function* runDeleteBulkContract(action) {
  try {
    const { contract } = yield race({
      contract: call(api.delete, "contract", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (contract) yield put({ type: DELETE_BULK_CONTRACT_SUCCESS });
    else
      yield put({
        type: DELETE_BULK_CONTRACT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BULK_CONTRACT_ERROR,
      error: typeof error === "object" ? error : "Could not delete contract",
    });
  }
}
function* deleteBulkContract() {
  yield takeEvery(DELETE_BULK_CONTRACT_REQUEST, runDeleteBulkContract);
}

function* runGetAllContractHolder(action) {
  try {
    const { response } = yield race({
      response: call(api.get, "contractHolder", {
        lang: action.lang,
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response) {
      const res = response?.data?.data;
      yield put({
        type: GET_ALL_CONTRACT_HOLDERS_SUCCESS,
        data: res.rows,
        count: res.count,
      });
    } else
      yield put({
        type: GET_ALL_CONTRACT_HOLDERS_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_ALL_CONTRACT_HOLDERS_ERROR,
      error:
        typeof error === "object" ? error : "Could not fetch contract holder",
    });
  }
}
function* getAllContractHolder() {
  yield takeEvery(GET_ALL_CONTRACT_HOLDERS_REQUEST, runGetAllContractHolder);
}

export function* saga() {
  while (true) {
    yield all({
      getContracts: call(getContracts),
      addContract: call(addContract),
      editContract: call(editContract),
      deleteContract: call(deleteContract),
      deleteBulkContract: call(deleteBulkContract),
      getAllContractHolder: call(getAllContractHolder),
    });
  }
}
