// export const refreshManifestInterval = 15000; // every 15 seconds make API request to get realtime data
export const refreshManifestInterval = 60000; // every 15 seconds make API request to get realtime data

export const GET_TRIP_MANIFEST_REQUEST = "GET_TRIP_MANIFEST_REQUEST";
export const GET_TRIP_MANIFEST_SUCCESS = "GET_TRIP_MANIFEST_SUCCESS";
export const GET_TRIP_MANIFEST_ERROR = "GET_TRIP_MANIFEST_ERROR";

export const SEND_TRIP_MANIFEST_REQUEST = "SEND_TRIP_MANIFEST_REQUEST";
export const SEND_TRIP_MANIFEST_SUCCESS = "SEND_TRIP_MANIFEST_SUCCESS";
export const SEND_TRIP_MANIFEST_ERROR = "SEND_TRIP_MANIFEST_ERROR";

export const PASSENGER_CHECKING_REQUEST = "PASSENGER_CHECKING_REQUEST";
export const PASSENGER_CHECKING_SUCCESS = "PASSENGER_CHECKING_SUCCESS";
export const PASSENGER_CHECKING_ERROR = "PASSENGER_CHECKING_ERROR";

export const ACTIVATE_STAFF_PHONE_REQUEST = "ACTIVATE_STAFF_PHONE_REQUEST";
export const ACTIVATE_STAFF_PHONE_SUCCESS = "ACTIVATE_STAFF_PHONE_SUCCESS";
export const ACTIVATE_STAFF_PHONE_ERROR = "ACTIVATE_STAFF_PHONE_ERROR";

export const CLEAR_MISC_ACTION_STATUS = "CLEAR_MISC_ACTION_STATUS";

export const GET_VEHICLE_TRIPS_REQUEST = "GET_VEHICLE_TRIPS_REQUEST";
export const GET_VEHICLE_TRIPS_SUCCESS = "GET_VEHICLE_TRIPS_SUCCESS";
export const GET_VEHICLE_TRIPS_ERROR = "GET_VEHICLE_TRIPS_ERROR";

export const GET_VEHICLE_MANIFEST_REQUEST = "GET_VEHICLE_MANIFEST_REQUEST";
export const GET_VEHICLE_MANIFEST_SUCCESS = "GET_VEHICLE_MANIFEST_SUCCESS";
export const GET_VEHICLE_MANIFEST_ERROR = "GET_VEHICLE_MANIFEST_ERROR";

export const LOGOUT_VEHICLE_MANIFEST = "LOGOUT_VEHICLE_MANIFEST";

export const CLEAR_VEHICLE_MANIFEST_ACTION_STATUS =
  "CLEAR_VEHICLE_MANIFEST_ACTION_STATUS";

export const CHANGE_MANIFEST_BOARDING_STATUS_REQUEST =
  "CHANGE_MANIFEST_BOARDING_STATUS_REQUEST";
export const CHANGE_MANIFEST_BOARDING_STATUS_SUCCESS =
  "CHANGE_MANIFEST_BOARDING_STATUS_SUCCESS";
export const CHANGE_MANIFEST_BOARDING_STATUS_ERROR =
  "CHANGE_MANIFEST_BOARDING_STATUS_ERROR";

export const CHANGE_VEHICLE_MANIFEST_BOARDING_STATUS_REQUEST =
  "CHANGE_VEHICLE_MANIFEST_BOARDING_STATUS_REQUEST";
export const CHANGE_VEHICLE_MANIFEST_BOARDING_STATUS_SUCCESS =
  "CHANGE_VEHICLE_MANIFEST_BOARDING_STATUS_SUCCESS";
export const CHANGE_VEHICLE_MANIFEST_BOARDING_STATUS_ERROR =
  "CHANGE_VEHICLE_MANIFEST_BOARDING_STATUS_ERROR";

export const GET_TIMEZONE_SETTINGS_REQUEST = "GET_TIMEZONE_SETTINGS_REQUEST";
export const GET_TIMEZONE_SETTINGS_SUCCESS = "GET_TIMEZONE_SETTINGS_SUCCESS";
export const GET_TIMEZONE_SETTINGS_ERROR = "GET_TIMEZONE_SETTINGS_ERROR";
