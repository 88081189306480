export const refreshTripProgressInterval = 15000; // every 15 seconds make API request to get realtime data
export const refreshTripStatsInterval = 15000; // every 15 seconds make API request to get realtime data
export const apiRequestTimeout = 30000; // in milliseconds
export const maximumTipDuration = 2; // in hours

export const GET_TRIP_PROGRESS_REQUEST = "GET_TRIP_PROGRESS_REQUEST";
export const GET_TRIP_PROGRESS_SUCCESS = "GET_TRIP_PROGRESS_SUCCESS";
export const GET_TRIP_PROGRESS_ERROR = "GET_TRIP_PROGRESS_ERROR";

export const GET_BOARDING_PROGRESS_REQUEST = "GET_BOARDING_PROGRESS_REQUEST";
export const GET_BOARDING_PROGRESS_SUCCESS = "GET_BOARDING_PROGRESS_SUCCESS";
export const GET_BOARDING_PROGRESS_ERROR = "GET_BOARDING_PROGRESS_ERROR";

export const GET_TRIP_STATS_REQUEST = "GET_TRIP_STATS_REQUEST";
export const GET_TRIP_STATS_SUCCESS = "GET_TRIP_STATS_SUCCESS";
export const GET_TRIP_STATS_ERROR = "GET_TRIP_STATS_ERROR";

export const CLEAR_TRACKING_ACTION_STATUS = "CLEAR_TRACKING_ACTION_STATUS";
