// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";
import moment from "moment";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  GET_TRIP_MANIFEST_REQUEST,
  GET_TRIP_MANIFEST_SUCCESS,
  GET_TRIP_MANIFEST_ERROR,
  SEND_TRIP_MANIFEST_REQUEST,
  SEND_TRIP_MANIFEST_SUCCESS,
  SEND_TRIP_MANIFEST_ERROR,
  PASSENGER_CHECKING_REQUEST,
  PASSENGER_CHECKING_SUCCESS,
  PASSENGER_CHECKING_ERROR,
  CHANGE_MANIFEST_BOARDING_STATUS_REQUEST,
  CHANGE_MANIFEST_BOARDING_STATUS_SUCCESS,
  CHANGE_MANIFEST_BOARDING_STATUS_ERROR,
} from "../constants";

// get initial state
import initialState from "./manifestInitialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 50 * 1000; // 50 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case SEND_TRIP_MANIFEST_REQUEST:
      return {
        ...newState,
        manifestSent: false,
      };
    case SEND_TRIP_MANIFEST_SUCCESS:
      return {
        ...newState,
        manifestSent: true,
      };
    case SEND_TRIP_MANIFEST_ERROR:
      return {
        ...newState,
        manifestSent: false,
      };

    case PASSENGER_CHECKING_REQUEST:
      return {
        ...newState,
        checkedIn: false,
      };
    case PASSENGER_CHECKING_SUCCESS:
      return {
        ...newState,
        checkedIn: true,
      };
    case PASSENGER_CHECKING_ERROR:
      return {
        ...newState,
        checkedIn: false,
      };

    case GET_TRIP_MANIFEST_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "tripManifest",
                success: null,
                error: "",
              },
      };
    case GET_TRIP_MANIFEST_SUCCESS:
      return {
        ...newState,
        tripManifest: action.data,
        tripManifestCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "tripManifest",
                success: true,
                error: "",
              },
      };
    case GET_TRIP_MANIFEST_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "tripManifest",
          success: false,
          error: action.error,
        },
      };
    case CHANGE_MANIFEST_BOARDING_STATUS_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "change",
          entity: "boardingStatus",
          success: null,
          error: "",
        },
      };
    case CHANGE_MANIFEST_BOARDING_STATUS_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "change",
          entity: "boardingStatus",
          success: true,
          error: "",
        },
      };
    case CHANGE_MANIFEST_BOARDING_STATUS_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "change",
          entity: "boardingStatus",
          success: false,
          error: action.error,
        },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetManifest(action) {
  try {
    const { manifest } = yield race({
      manifest: call(api.get, "tripManifest", { ...action.params }),
      timeout: delay(parseResponseTimeout),
    });

    if (manifest) {
      // const manifestData = (manifest.data && manifest.data.data) || {};
      yield put({
        type: GET_TRIP_MANIFEST_SUCCESS,
        data: manifest.data && manifest.data.data,
        // data: manifestData.rows,
        // count: manifestData.count,
        filters: action.filters,
        source: action.source,
      });
    } else
      yield put({
        type: GET_TRIP_MANIFEST_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_TRIP_MANIFEST_ERROR,
      error:
        typeof error === "object" ? error : "Manifest could not be updated",
    });
  }
}
function* getManifest() {
  yield takeLatest(GET_TRIP_MANIFEST_REQUEST, runGetManifest);
}

function* runSendManifest(action) {
  try {
    const { manifest } = yield race({
      manifest: call(api.add, "sendTripManifest", { ...action.fields }),
      timeout: delay(parseResponseTimeout),
    });

    if (manifest)
      yield put({
        type: SEND_TRIP_MANIFEST_SUCCESS,
        manifest: manifest.data,
      });
    else
      yield put({
        type: SEND_TRIP_MANIFEST_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: SEND_TRIP_MANIFEST_ERROR,
      error: typeof error === "object" ? error : "Could not send manifest",
    });
  }
}
function* sendManifest() {
  yield takeLatest(SEND_TRIP_MANIFEST_REQUEST, runSendManifest);
}

function* runPassengerCheckIn(action) {
  try {
    const { manifest } = yield race({
      manifest: call(api.add, "checkIn", { ...action.fields }),
      timeout: delay(parseResponseTimeout),
    });

    if (manifest)
      yield put({
        type: PASSENGER_CHECKING_SUCCESS,
        manifest: manifest.data,
      });
    else
      yield put({
        type: PASSENGER_CHECKING_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: PASSENGER_CHECKING_ERROR,
      error: typeof error === "object" ? error : "Could not check passenger in",
    });
  }
}
function* checkInPassenger() {
  yield takeLatest(PASSENGER_CHECKING_REQUEST, runPassengerCheckIn);
}

function* runChangeBoardingStatus(action) {
  try {
    const { response } = yield race({
      response: call(api.update, `changeBoardingStatus`, {
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response) {
      const res = response.data ? response.data.data : [];
      yield put({
        type: CHANGE_MANIFEST_BOARDING_STATUS_SUCCESS,
        response: res.rows,
      });
    } else
      yield put({
        type: CHANGE_MANIFEST_BOARDING_STATUS_ERROR,
        error: errors.requestTimeout.message,
        entity: action.entity,
      });
  } catch (error) {
    // .log(error);
    yield put({
      type: CHANGE_MANIFEST_BOARDING_STATUS_ERROR,
      entity: action.entity,
      error: typeof error === "object" ? error : error,
    });
  }
}
function* changeBoardingStatus() {
  yield takeLatest(
    CHANGE_MANIFEST_BOARDING_STATUS_REQUEST,
    runChangeBoardingStatus
  );
}

export function* saga() {
  while (true) {
    yield all({
      getManifest: call(getManifest),
      sendManifest: call(sendManifest),
      checkInPassenger: call(checkInPassenger),
      changeBoardingStatus: call(changeBoardingStatus),
    });
  }
}
