export const SEND_PASSENGER_NOTIFICATION_REQUEST =
  "SEND_PASSENGER_NOTIFICATION_REQUEST";
export const SEND_PASSENGER_NOTIFICATION_SUCCESS =
  "SEND_PASSENGER_NOTIFICATION_SUCCESS";
export const SEND_PASSENGER_NOTIFICATION_ERROR =
  "SEND_PASSENGER_NOTIFICATION_ERROR";

export const SEND_PASSENGER_TRIP_NOTIFICATION_REQUEST =
  "SEND_PASSENGER_TRIP_NOTIFICATION_REQUEST";
export const SEND_PASSENGER_TRIP_NOTIFICATION_SUCCESS =
  "SEND_PASSENGER_TRIP_NOTIFICATION_SUCCESS";
export const SEND_PASSENGER_TRIP_NOTIFICATION_ERROR =
  "SEND_PASSENGER_TRIP_NOTIFICATION_ERROR";

export const GET_NOTIFICATION_REQUEST = "GET_NOTIFICATION_REQUEST";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_ERROR = "GET_NOTIFICATION_ERROR";

export const SEND_NOTIFICATION_REQUEST = "SEND_NOTIFICATION_REQUEST";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_ERROR = "SEND_NOTIFICATION_ERROR";

export const EDIT_NOTIFICATION_REQUEST = "EDIT_NOTIFICATION_REQUEST";
export const EDIT_NOTIFICATION_SUCCESS = "EDIT_NOTIFICATION_SUCCESS";
export const EDIT_NOTIFICATION_ERROR = "EDIT_NOTIFICATION_ERROR";

export const CLEAR_NOTIFICATION_STATUS = "CLEAR_NOTIFICATION_STATUS";

export const CANCEL_NOTIFICATION_REQUEST = "CANCEL_NOTIFICATION_REQUEST";
export const CANCEL_NOTIFICATION_SUCCESS = "CANCEL_NOTIFICATION_SUCCESS";
export const CANCEL_NOTIFICATION_ERROR = "CANCEL_NOTIFICATION_ERROR";
