export const GET_SINGLE_STOP_REQUEST = "GET_SINGLE_STOP_REQUEST";
export const GET_SINGLE_STOP_SUCCESS = "GET_SINGLE_STOP_SUCCESS";
export const GET_SINGLE_STOP_ERROR = "GET_SINGLE_STOP_ERROR";

export const GET_STOP_REQUEST = "GET_STOP_REQUEST";
export const GET_STOP_SUCCESS = "GET_STOP_SUCCESS";
export const GET_STOP_ERROR = "GET_STOP_ERROR";

export const GET_ALL_STOP_REQUEST = "GET_ALL_STOP_REQUEST";
export const GET_ALL_STOP_SUCCESS = "GET_ALL_STOP_SUCCESS";
export const GET_ALL_STOP_ERROR = "GET_ALL_STOP_ERROR";

export const ADD_STOP_REQUEST = "ADD_STOP_REQUEST";
export const ADD_STOP_SUCCESS = "ADD_STOP_SUCCESS";
export const ADD_STOP_ERROR = "ADD_STOP_ERROR";

export const EDIT_STOP_REQUEST = "EDIT_STOP_REQUEST";
export const EDIT_STOP_SUCCESS = "EDIT_STOP_SUCCESS";
export const EDIT_STOP_ERROR = "EDIT_STOP_ERROR";

export const DELETE_STOP_REQUEST = "DELETE_STOP_REQUEST";
export const DELETE_STOP_SUCCESS = "DELETE_STOP_SUCCESS";
export const DELETE_STOP_ERROR = "DELETE_STOP_ERROR";

export const DELETE_BULK_STOP_REQUEST = "DELETE_BULK_STOP_REQUEST";
export const DELETE_BULK_STOP_SUCCESS = "DELETE_BULK_STOP_SUCCESS";
export const DELETE_BULK_STOP_ERROR = "DELETE_BULK_STOP_ERROR";

export const SEARCH_STOP_REQUEST = "SEARCH_STOP_REQUEST";
export const SEARCH_STOP_SUCCESS = "SEARCH_STOP_SUCCESS";
export const SEARCH_STOP_ERROR = "SEARCH_STOP_ERROR";

export const CLEAR_STOPS_ACTION_STATUS = "CLEAR_STOPS_ACTION_STATUS";
