// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";
import moment from "moment";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  ADD_DIRECTORATE_REQUEST,
  ADD_DIRECTORATE_SUCCESS,
  ADD_DIRECTORATE_ERROR,
  EDIT_DIRECTORATE_REQUEST,
  EDIT_DIRECTORATE_SUCCESS,
  EDIT_DIRECTORATE_ERROR,
  GET_DIRECTORATE_REQUEST,
  GET_DIRECTORATE_SUCCESS,
  GET_DIRECTORATE_ERROR,
  DELETE_DIRECTORATE_REQUEST,
  DELETE_DIRECTORATE_SUCCESS,
  DELETE_DIRECTORATE_ERROR,
  DELETE_BULK_DIRECTORATE_REQUEST,
  DELETE_BULK_DIRECTORATE_SUCCESS,
  DELETE_BULK_DIRECTORATE_ERROR,
  // smsCodeTimeout
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 30 * 1000; // 30 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_DIRECTORATE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "directorate",
          success: null,
          error: "",
        },
      };
    case ADD_DIRECTORATE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "directorate",
          success: true,
          error: "",
        },
      };
    case ADD_DIRECTORATE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "directorate",
          success: false,
          error: action.error,
        },
      };
    case EDIT_DIRECTORATE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "directorate",
          success: null,
          error: "",
        },
      };
    case EDIT_DIRECTORATE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "directorate",
          success: true,
          error: "",
        },
      };
    case EDIT_DIRECTORATE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "directorate",
          success: false,
          error: action.error,
        },
      };
    case DELETE_DIRECTORATE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "directorate",
          success: null,
          error: "",
        },
      };
    case DELETE_DIRECTORATE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "directorate",
          success: true,
          error: "",
        },
      };
    case DELETE_DIRECTORATE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "directorate",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BULK_DIRECTORATE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "directorate",
          success: null,
          error: "",
        },
      };
    case DELETE_BULK_DIRECTORATE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "directorate",
          success: true,
          error: "",
        },
      };
    case DELETE_BULK_DIRECTORATE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "directorate",
          success: false,
          error: action.error,
        },
      };

    case GET_DIRECTORATE_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "directorate",
                success: null,
                error: "",
              },
      };
    case GET_DIRECTORATE_SUCCESS:
      return {
        ...newState,
        filters: action.source === "sidePanel" ? state.filters : action.filters,
        directorates: action.directorates,
        directoratesCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "directorate",
                success: true,
                error: "",
              },
      };
    case GET_DIRECTORATE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "directorate",
          success: false,
          error: action.error,
        },
      };

    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetDirectorates(action) {
  try {
    const { directorate } = yield race({
      directorate: call(api.get, "directorate", {
        lang: action.lang,
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (directorate) {
      const directorates = directorate.data ? directorate.data.data : [];
      yield put({
        type: GET_DIRECTORATE_SUCCESS,
        source: action.source,
        directorates: directorates.rows,
        count: directorates.count,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_DIRECTORATE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_DIRECTORATE_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Directorates could not be updated.",
    });
  }
}
function* getDirectorates() {
  yield takeLatest(GET_DIRECTORATE_REQUEST, runGetDirectorates);
}

function* runAddDirectorate(action) {
  try {
    const { directorate } = yield race({
      directorate: call(api.add, "directorate", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (directorate)
      yield put({
        type: ADD_DIRECTORATE_SUCCESS,
        ...directorate.data,
      });
    else
      yield put({
        type: ADD_DIRECTORATE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_DIRECTORATE_ERROR,
      error, //error: typeof error === "object" ? error : "Could not add directorate",
    });
  }
}
function* addDirectorate() {
  yield takeLatest(ADD_DIRECTORATE_REQUEST, runAddDirectorate);
}

function* runEditDirectorate(action) {
  try {
    const { directorate } = yield race({
      directorate: call(api.update, "directorate", {
        lang: action.lang,
        id: action.id,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (directorate)
      yield put({
        type: EDIT_DIRECTORATE_SUCCESS,
        ...directorate.data,
      });
    else
      yield put({
        type: EDIT_DIRECTORATE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_DIRECTORATE_ERROR,
      error, //error: typeof error === "object" ? error : "Could not edit directorate",
    });
  }
}
function* editDirectorate() {
  yield takeLatest(EDIT_DIRECTORATE_REQUEST, runEditDirectorate);
}

function* runDeleteDirectorate(action) {
  try {
    const { directorate } = yield race({
      directorate: call(api.delete, "directorate", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (directorate) yield put({ type: DELETE_DIRECTORATE_SUCCESS });
    else
      yield put({
        type: DELETE_DIRECTORATE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_DIRECTORATE_ERROR,
      error: typeof error === "object" ? error : "Could not delete directorate",
    });
  }
}
function* deleteDirectorate() {
  yield takeLatest(DELETE_DIRECTORATE_REQUEST, runDeleteDirectorate);
}

function* runDeleteBulkDirectorate(action) {
  try {
    const { directorate } = yield race({
      directorate: call(api.delete, "directorate", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (directorate) yield put({ type: DELETE_BULK_DIRECTORATE_SUCCESS });
    else
      yield put({
        type: DELETE_BULK_DIRECTORATE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BULK_DIRECTORATE_ERROR,
      error: typeof error === "object" ? error : "Could not delete directorate",
    });
  }
}
function* deleteBulkDirectorate() {
  yield takeEvery(DELETE_BULK_DIRECTORATE_REQUEST, runDeleteBulkDirectorate);
}

export function* saga() {
  while (true) {
    yield all({
      getDirectorates: call(getDirectorates),
      addDirectorate: call(addDirectorate),
      editDirectorate: call(editDirectorate),
      deleteDirectorate: call(deleteDirectorate),
      deleteBulkDirectorate: call(deleteBulkDirectorate),
    });
  }
}
