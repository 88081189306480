import { all, put, call, takeLatest, delay, race } from "redux-saga/effects";
import api from "../../../utils/api/general";
import {
  GET_REGION_REQUEST,
  GET_REGION_SUCCESS,
  GET_REGION_ERROR,
} from "../constants";
import initialState from "./initialState";
const parseResponseTimeout = 50 * 1000; // 50 seconds
const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

export function reducer(state = initialState, action) {
  const newState = Object.assign({}, state, {});
  switch (action.type) {
    case GET_REGION_REQUEST:
      return {
        ...newState,
        loading: true,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "region",
                success: null,
                error: "",
              },
      };
    case GET_REGION_SUCCESS:
      return {
        ...newState,
        loading: false,
        filters: {
          ...action.filters,
          extraData: action.extraData,
        },
        regions: action.region,
        regionCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "region",
                success: true,
                error: "",
              },
      };
    case GET_REGION_ERROR:
      return {
        ...newState,
        loading: false,
        actionStatus: {
          type: "fetch",
          entity: "region",
          success: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}

function* runGetRegion(action) {
  try {
    const { extraData, ...filters } = action?.filters || {};
    const { response } = yield race({
      response: call(api.get, "region", {
        lang: action.lang,
        ...action.params,
        ...filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response) {
      const res = response.data ? response.data.data : [];
      yield put({
        type: GET_REGION_SUCCESS,
        source: action.source,
        region: res.rows,
        count: res.count,
        extraData,
        filters,
      });
    } else
      yield put({
        type: GET_REGION_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_REGION_ERROR,
      error: typeof error === "object" ? error : "Region could not be fetched.",
    });
  }
}
function* getRegion() {
  yield takeLatest(GET_REGION_REQUEST, runGetRegion);
}

export function* saga() {
  while (true) {
    yield all({
      getRegion: call(getRegion),
    });
  }
}
