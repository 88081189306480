// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";

// internal imports
import api from "../../../utils/api/general";
import { replaceErrors } from "../../../utils/handleError";
// get action names
import {
  ADD_CONTRACTOR_REQUEST,
  ADD_CONTRACTOR_SUCCESS,
  ADD_CONTRACTOR_ERROR,
  ADD_BULK_CONTRACTOR_REQUEST,
  ADD_BULK_CONTRACTOR_SUCCESS,
  ADD_BULK_CONTRACTOR_ERROR,
  EDIT_CONTRACTOR_REQUEST,
  EDIT_CONTRACTOR_SUCCESS,
  EDIT_CONTRACTOR_ERROR,
  BULK_EDIT_CONTRACTOR_REQUEST,
  BULK_EDIT_CONTRACTOR_SUCCESS,
  BULK_EDIT_CONTRACTOR_ERROR,
  GET_CONTRACTOR_REQUEST,
  GET_CONTRACTOR_SUCCESS,
  GET_CONTRACTOR_ERROR,
  GET_SINGLE_CONTRACTOR_REQUEST,
  GET_SINGLE_CONTRACTOR_SUCCESS,
  GET_SINGLE_CONTRACTOR_ERROR,
  DELETE_CONTRACTOR_REQUEST,
  DELETE_CONTRACTOR_SUCCESS,
  DELETE_CONTRACTOR_ERROR,
  DELETE_BULK_CONTRACTOR_REQUEST,
  DELETE_BULK_CONTRACTOR_SUCCESS,
  DELETE_BULK_CONTRACTOR_ERROR,
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 30 * 1000; // 5 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_CONTRACTOR_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "contractor",
          success: null,
          error: "",
        },
      };
    case ADD_CONTRACTOR_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "contractor",
          success: true,
          error: "",
        },
      };
    case ADD_CONTRACTOR_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "contractor",
          success: false,
          error: action.error,
        },
      };
    case ADD_BULK_CONTRACTOR_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkAdd",
          entity: "contractor",
          success: null,
          error: "",
        },
      };
    case ADD_BULK_CONTRACTOR_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkAdd",
          entity: "contractor",
          success: true,
          error: "",
        },
      };
    case ADD_BULK_CONTRACTOR_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkAdd",
          entity: "contractor",
          success: false,
          error: action.error,
        },
      };
    case EDIT_CONTRACTOR_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "contractor",
          success: null,
          error: "",
        },
      };
    case EDIT_CONTRACTOR_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "contractor",
          success: true,
          error: "",
        },
      };
    case EDIT_CONTRACTOR_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "contractor",
          success: false,
          error: action.error,
        },
      };
    case BULK_EDIT_CONTRACTOR_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkEdit",
          entity: "contractor",
          success: null,
          error: "",
        },
      };
    case BULK_EDIT_CONTRACTOR_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkEdit",
          entity: "contractor",
          success: true,
          error: "",
        },
      };
    case BULK_EDIT_CONTRACTOR_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkEdit",
          entity: "contractor",
          success: false,
          error: action.error,
        },
      };
    case DELETE_CONTRACTOR_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "contractor",
          success: null,
          error: "",
        },
        userLinked: null,
      };
    case DELETE_CONTRACTOR_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "contractor",
          success: true,
          error: "",
        },
        userLinked: action.userLinked,
      };
    case DELETE_CONTRACTOR_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "contractor",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BULK_CONTRACTOR_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "contractor",
          success: null,
          error: "",
        },
      };
    case DELETE_BULK_CONTRACTOR_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "contractor",
          success: true,
          error: "",
        },
      };
    case DELETE_BULK_CONTRACTOR_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "contractor",
          success: false,
          error: action.error,
        },
      };
    case GET_CONTRACTOR_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "contractor",
                success: null,
                error: "",
              },
      };
    case GET_CONTRACTOR_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        contractors: action.contractors,
        contractorsCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "contractor",
                success: true,
                error: "",
              },
      };
    case GET_CONTRACTOR_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "contractor",
          success: false,
          error: action.error,
        },
      };

    case GET_SINGLE_CONTRACTOR_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "contractor",
          success: null,
          error: "",
        },
      };
    case GET_SINGLE_CONTRACTOR_SUCCESS:
      return {
        ...newState,
        contractors: {
          ...state.contractors,
          [action.id]: action.contractor,
        },
        actionStatus: {
          type: "fetch",
          entity: "contractor",
          success: true,
          error: "",
        },
      };
    case GET_SINGLE_CONTRACTOR_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "contractor",
          success: false,
          error: action.error,
        },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetSingleContractor(action) {
  try {
    const { contractor } = yield race({
      contractor: call(api.get, "contractor", {
        lang: action.lang,
        id: action.id,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (contractor)
      yield put({
        type: GET_SINGLE_CONTRACTOR_SUCCESS,
        contractor: contractor.data,
        id: action.id,
      });
    else
      yield put({
        type: GET_SINGLE_CONTRACTOR_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_SINGLE_CONTRACTOR_ERROR,
      error:
        typeof error === "object" ? error : "Contractor could not be updated.",
    });
  }
}
function* getSingleContractor() {
  yield takeLatest(GET_SINGLE_CONTRACTOR_REQUEST, runGetSingleContractor);
}

function* runGetContractors(action) {
  try {
    const { contractor } = yield race({
      contractor: call(api.get, "passenger", {
        lang: action.lang,
        type: action.userType,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (contractor) {
      const contractors = contractor.data ? contractor.data.data : [];
      yield put({
        type: GET_CONTRACTOR_SUCCESS,
        source: action.source,
        contractors: contractors.rows,
        count: contractors.count,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_CONTRACTOR_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_CONTRACTOR_ERROR,
      error,
      // error:        typeof error === "object" ? error : "Contractors could not be updated.",
    });
  }
}
function* getContractors() {
  yield takeLatest(GET_CONTRACTOR_REQUEST, runGetContractors);
}

function* runBulkAddContractor(action) {
  try {
    const { contractor } = yield race({
      contractor: call(api.upload, "bulkAddContractors", {
        lang: action.lang,
        id: action.id,
        file: action.file,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout * 5),
    });

    if (contractor)
      yield put({
        type: ADD_BULK_CONTRACTOR_SUCCESS,
        ...contractor.data,
      });
    else
      yield put({
        type: ADD_BULK_CONTRACTOR_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_BULK_CONTRACTOR_ERROR,
      // error: typeof error === "object" ? error : "Could not add contractors",
      error,
    });
  }
}
function* runBulkEditContractor(action) {
  try {
    const { contractor } = yield race({
      contractor: call(api.update, "bulkEditContractor", {
        lang: action.lang,
        id: action.id,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout * 5),
    });

    if (contractor)
      yield put({
        type: BULK_EDIT_CONTRACTOR_SUCCESS,
        ...contractor.data,
      });
    else
      yield put({
        type: BULK_EDIT_CONTRACTOR_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: BULK_EDIT_CONTRACTOR_ERROR,
      // error: typeof error === "object" ? error : "Could not add contractors",
      error,
    });
  }
}
function* bulkAddContractor() {
  yield takeLatest(ADD_BULK_CONTRACTOR_REQUEST, runBulkAddContractor);
}

function* runAddContractor(action) {
  try {
    const { contractor } = yield race({
      contractor: call(api.add, "passenger", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (contractor)
      yield put({
        type: ADD_CONTRACTOR_SUCCESS,
        ...contractor.data,
      });
    else
      yield put({
        type: ADD_CONTRACTOR_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_CONTRACTOR_ERROR,
      error,
      //error: typeof error === "object" ? error : "Could not add contractor",
    });
  }
}
function* addContractor() {
  yield takeLatest(ADD_CONTRACTOR_REQUEST, runAddContractor);
}

function* runEditContractor(action) {
  try {
    // Update contractor details
    const { contractor } = yield race({
      contractor: call(api.update, "passenger", {
        lang: action.lang,
        id: action.id,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (contractor)
      yield put({
        type: EDIT_CONTRACTOR_SUCCESS,
        ...contractor.data,
      });
    else
      yield put({
        type: EDIT_CONTRACTOR_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_CONTRACTOR_ERROR,
      error,
      //error: typeof error === "object" ? error : "Could not edit contractor",
    });
  }
}

function* editContractor() {
  yield takeLatest(EDIT_CONTRACTOR_REQUEST, runEditContractor);
}

function* bulkEditContractor() {
  yield takeLatest(BULK_EDIT_CONTRACTOR_REQUEST, runBulkEditContractor);
}

function* runDeleteContractor(action) {
  try {
    const { contractor } = yield race({
      contractor: call(
        api.delete,
        `passenger${action.params?.ids ? "Bulk" : ""}`,
        {
          id: action.params?.id || undefined,
          data: action.params?.ids || undefined,
          lang: action.lang,
        }
      ),
      timeout: delay(parseResponseTimeout),
    });

    if (contractor) {
      let obj = { type: DELETE_CONTRACTOR_SUCCESS };
      if (
        contractor?.data?.data &&
        Object.values(contractor?.data?.data).length > 0
      )
        obj.userLinked = {
          message: contractor.data.message,
          data: contractor.data.data,
        };
      yield put(obj);
    } else
      yield put({
        type: DELETE_CONTRACTOR_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_CONTRACTOR_ERROR,
      error: typeof error === "object" ? error : "Could not delete contractors",
    });
  }
}
function* deleteContractor() {
  yield takeLatest(DELETE_CONTRACTOR_REQUEST, runDeleteContractor);
}

function* runDeleteBulkContractor(action) {
  try {
    const { contractor } = yield race({
      contractor: call(api.delete, "bulkPassenger", {
        data: { ...action.params },
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (contractor) yield put({ type: DELETE_BULK_CONTRACTOR_SUCCESS });
    else
      yield put({
        type: DELETE_BULK_CONTRACTOR_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BULK_CONTRACTOR_ERROR,
      error: typeof error === "object" ? error : "Could not delete contractors",
    });
  }
}
function* deleteBulkContractor() {
  yield takeEvery(DELETE_BULK_CONTRACTOR_REQUEST, runDeleteBulkContractor);
}

export function* saga() {
  while (true) {
    yield all({
      getContractors: call(getContractors),
      getSingleContractor: call(getSingleContractor),
      addContractor: call(addContractor),
      bulkAddContractor: call(bulkAddContractor),
      editContractor: call(editContractor),
      bulkEditContractor: call(bulkEditContractor),
      deleteContractor: call(deleteContractor),
      deleteBulkContractor: call(deleteBulkContractor),
    });
  }
}
