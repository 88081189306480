export const GET_SINGLE_ROUTE_REQUEST = "GET_SINGLE_ROUTE_REQUEST";
export const GET_SINGLE_ROUTE_SUCCESS = "GET_SINGLE_ROUTE_SUCCESS";
export const GET_SINGLE_ROUTE_ERROR = "GET_SINGLE_ROUTE_ERROR";

export const GET_ROUTE_REQUEST = "GET_ROUTE_REQUEST";
export const GET_ROUTE_SUCCESS = "GET_ROUTE_SUCCESS";
export const GET_ROUTE_ERROR = "GET_ROUTE_ERROR";

export const ADD_ROUTE_REQUEST = "ADD_ROUTE_REQUEST";
export const ADD_ROUTE_SUCCESS = "ADD_ROUTE_SUCCESS";
export const ADD_ROUTE_ERROR = "ADD_ROUTE_ERROR";

export const EDIT_ROUTE_REQUEST = "EDIT_ROUTE_REQUEST";
export const EDIT_ROUTE_SUCCESS = "EDIT_ROUTE_SUCCESS";
export const EDIT_ROUTE_ERROR = "EDIT_ROUTE_ERROR";

export const DELETE_ROUTE_REQUEST = "DELETE_ROUTE_REQUEST";
export const DELETE_ROUTE_SUCCESS = "DELETE_ROUTE_SUCCESS";
export const DELETE_ROUTE_ERROR = "DELETE_ROUTE_ERROR";

export const DELETE_BULK_ROUTE_REQUEST = "DELETE_BULK_ROUTE_REQUEST";
export const DELETE_BULK_ROUTE_SUCCESS = "DELETE_BULK_ROUTE_SUCCESS";
export const DELETE_BULK_ROUTE_ERROR = "DELETE_BULK_ROUTE_ERROR";

export const GET_ROUTE_POI_REQUEST = "GET_ROUTE_POI_REQUEST";
export const GET_ROUTE_POI_SUCCESS = "GET_ROUTE_POI_SUCCESS";
export const GET_ROUTE_POI_ERROR = "GET_ROUTE_POI_ERROR";

export const ADD_ROUTE_POI_REQUEST = "ADD_ROUTE_POI_REQUEST";
export const ADD_ROUTE_POI_SUCCESS = "ADD_ROUTE_POI_SUCCESS";
export const ADD_ROUTE_POI_ERROR = "ADD_ROUTE_POI_ERROR";

export const EDIT_ROUTE_POI_REQUEST = "EDIT_ROUTE_POI_REQUEST";
export const EDIT_ROUTE_POI_SUCCESS = "EDIT_ROUTE_POI_SUCCESS";
export const EDIT_ROUTE_POI_ERROR = "EDIT_ROUTE_POI_ERROR";

export const DELETE_ROUTE_POI_REQUEST = "DELETE_ROUTE_POI_REQUEST";
export const DELETE_ROUTE_POI_SUCCESS = "DELETE_ROUTE_POI_SUCCESS";
export const DELETE_ROUTE_POI_ERROR = "DELETE_ROUTE_ERROR";

export const GET_STOPS_TIMING_REQUEST = "GET_STOPS_TIMING_REQUEST";
export const GET_STOPS_TIMING_SUCCESS = "GET_STOPS_TIMING_SUCCESS";
export const GET_STOPS_TIMING_ERROR = "GET_STOPS_TIMING_ERROR";

export const GET_RECOMMENDED_STOPS_REQUEST = "GET_RECOMMENDED_STOPS_REQUEST";
export const GET_RECOMMENDED_STOPS_SUCCESS = "GET_RECOMMENDED_STOPS_SUCCESS";
export const GET_RECOMMENDED_STOPS_ERROR = "GET_RECOMMENDED_STOPS_ERROR";

export const CLEAR_ROUTES_ACTION_STATUS = "CLEAR_ROUTES_ACTION_STATUS";

export const GET_ROUTE_GROUP_REQUEST = "GET_ROUTE_GROUP_REQUEST";
export const GET_ROUTE_GROUP_SUCCESS = "GET_ROUTE_GROUP_SUCCESS";
export const GET_ROUTE_GROUP_ERROR = "GET_ROUTE_GROUP_ERROR";

export const ADD_ROUTE_GROUP_REQUEST = "ADD_ROUTE_GROUP_REQUEST";
export const ADD_ROUTE_GROUP_SUCCESS = "ADD_ROUTE_GROUP_SUCCESS";
export const ADD_ROUTE_GROUP_ERROR = "ADD_ROUTE_GROUP_ERROR";

export const EDIT_ROUTE_GROUP_REQUEST = "EDIT_ROUTE_GROUP_REQUEST";
export const EDIT_ROUTE_GROUP_SUCCESS = "EDIT_ROUTE_GROUP_SUCCESS";
export const EDIT_ROUTE_GROUP_ERROR = "EDIT_ROUTE_GROUP_ERROR";

export const DELETE_ROUTE_GROUP_REQUEST = "DELETE_ROUTE_GROUP_REQUEST";
export const DELETE_ROUTE_GROUP_SUCCESS = "DELETE_ROUTE_GROUP_SUCCESS";
export const DELETE_ROUTE_GROUP_ERROR = "DELETE_ROUTE_GROUP_ERROR";

export const CLEAR_NEW_ROUTE_GROUP = "CLEAR_NEW_ROUTE_GROUP";
