import { reducer as manifestReducers } from "./manifestState";
// import { reducer as loggingUser } from './login'

const core = [
  manifestReducers,
  // loggingUser
];

export default function manifestReducer(state, action, i) {
  if (!Number.isInteger(i)) i = 0;
  if (i < core.length)
    return manifestReducer(core[i](state, action), action, i + 1);
  else return state;
}
