const initialState = {
  isLoggedIn: false,
  currentUser: null,
  numLoginAttempts: 0,
  locale: "en",
  activeSubMenu: "",
  expandMenu: true,
  serverDownErr: null,
  role: "",
};

export default initialState;
