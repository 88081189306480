// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";
import moment from "moment";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  ADD_STOP_REQUEST,
  ADD_STOP_SUCCESS,
  ADD_STOP_ERROR,
  GET_STOP_REQUEST,
  GET_STOP_SUCCESS,
  GET_STOP_ERROR,
  GET_SINGLE_STOP_REQUEST,
  GET_SINGLE_STOP_SUCCESS,
  GET_SINGLE_STOP_ERROR,
  GET_ALL_STOP_REQUEST,
  GET_ALL_STOP_ERROR,
  GET_ALL_STOP_SUCCESS,
  EDIT_STOP_REQUEST,
  EDIT_STOP_SUCCESS,
  EDIT_STOP_ERROR,
  DELETE_STOP_REQUEST,
  DELETE_STOP_SUCCESS,
  DELETE_STOP_ERROR,
  DELETE_BULK_STOP_REQUEST,
  DELETE_BULK_STOP_SUCCESS,
  DELETE_BULK_STOP_ERROR,
  SEARCH_STOP_REQUEST,
  SEARCH_STOP_SUCCESS,
  SEARCH_STOP_ERROR,
  CLEAR_STOPS_ACTION_STATUS,
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 30 * 1000; // 5 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_STOP_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "stop",
          success: null,
          error: "",
        },
      };
    case ADD_STOP_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "stop",
          success: true,
          error: "",
        },
      };
    case ADD_STOP_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "stop",
          success: false,
          error: action.error,
        },
      };
    case EDIT_STOP_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "stop",
          success: null,
          error: "",
          fieldValue: undefined,
        },
      };
    case EDIT_STOP_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "stop",
          success: true,
          error: "",
        },
      };
    case EDIT_STOP_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "stop",
          success: false,
          error: action.error,
          fieldValue: action.fieldValue,
        },
      };
    case DELETE_STOP_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "stop",
          success: null,
          error: "",
        },
      };
    case DELETE_STOP_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "stop",
          success: true,
          error: "",
        },
      };
    case DELETE_STOP_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "stop",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BULK_STOP_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "stop",
          success: null,
          error: "",
        },
      };
    case DELETE_BULK_STOP_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "stop",
          success: true,
          error: "",
        },
      };
    case DELETE_BULK_STOP_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "stop",
          success: false,
          error: action.error,
        },
      };

    case GET_STOP_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "stop",
                success: null,
                error: "",
              },
      };
    case GET_STOP_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        stops: action.stops,
        stopsCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "stop",
                success: true,
                error: "",
              },
      };
    case GET_STOP_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "stop",
          success: false,
          error: action.error,
        },
      };

    case GET_ALL_STOP_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "stop",
                success: null,
                error: "",
              },
      };
    case GET_ALL_STOP_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        stops: action.stops,
        stopsCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "stop",
                success: true,
                error: "",
              },
      };
    case GET_ALL_STOP_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "stop",
          success: false,
          error: action.error,
        },
      };

    case GET_SINGLE_STOP_REQUEST:
      return {
        ...newState,
        stopError: null,
      };
    case GET_SINGLE_STOP_SUCCESS:
      return {
        ...newState,
        stop: {
          ...state.stop,
          [action.id]: action.stop,
        },
        stopError: null,
      };
    case GET_SINGLE_STOP_ERROR:
      return {
        ...newState,
        stopError: action.error,
      };

    case SEARCH_STOP_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "search",
          entity: "stop",
          success: null,
          error: "",
        },
      };
    case SEARCH_STOP_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "search",
          entity: "stop",
          success: true,
          error: "",
        },
        searchResult: action.data,
      };
    case SEARCH_STOP_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "search",
          entity: "stop",
          success: false,
          error: action.error,
        },
      };
    case CLEAR_STOPS_ACTION_STATUS:
      return {
        ...newState,
        actionStatus: { type: "", entity: "", success: null, error: "" },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetSingleStop(action) {
  try {
    const { stop } = yield race({
      stop: call(api.get, "stop", {
        id: action.id,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (stop)
      yield put({
        type: GET_SINGLE_STOP_SUCCESS,
        stop: stop.data,
        id: action.id,
      });
    else
      yield put({
        type: GET_SINGLE_STOP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_SINGLE_STOP_ERROR,
      error: typeof error === "object" ? error : "Stops could not be updated.",
    });
  }
}
function* getSingleStop() {
  yield takeLatest(GET_SINGLE_STOP_REQUEST, runGetSingleStop);
}

function* runGetStops(action) {
  try {
    const { stop } = yield race({
      stop: call(api.get, "stop", {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (stop) {
      const stops = stop.data ? stop.data.data : [];
      yield put({
        type: GET_STOP_SUCCESS,
        source: action.source,
        stops: stops.rows,
        count: stops.count,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_STOP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_STOP_ERROR,
      error: typeof error === "object" ? error : "Stops could not be updated.",
    });
  }
}
function* getStops() {
  yield takeLatest(GET_STOP_REQUEST, runGetStops);
}

function* runGetAllStops(action) {
  try {
    const { stop } = yield race({
      stop: call(api.get, "allStop", {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (stop) {
      const stops = stop.data ? stop.data.data : [];
      yield put({
        type: GET_ALL_STOP_SUCCESS,
        source: action.source,
        stops: stops,
        count: stops.length,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_ALL_STOP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_ALL_STOP_ERROR,
      error: typeof error === "object" ? error : "Stops could not be updated.",
    });
  }
}
function* getAllStops() {
  yield takeLatest(GET_ALL_STOP_REQUEST, runGetAllStops);
}

function* runAddStop(action) {
  try {
    const { stop } = yield race({
      stop: call(api.add, "stop", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (stop)
      yield put({
        type: ADD_STOP_SUCCESS,
        ...stop.data,
      });
    else
      yield put({
        type: ADD_STOP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_STOP_ERROR,
      error, // error: typeof error === "object" ? error : "Could not add stop",
    });
  }
}
function* addStop() {
  yield takeLatest(ADD_STOP_REQUEST, runAddStop);
}

function* runEditStop(action) {
  // yield put({
  //   type: EDIT_STOP_ERROR,
  //   error: {
  //     has_routes: true,
  //     confirm_dialogue: true,
  //     message: "Stop cannot be deleted. Stop is linked to routes.",
  //     action: "edit",
  //     routes_count: 10,
  //     allow_action: true,
  //   },
  //   fieldValue: { id: action.id, ...action.fields },
  // });
  try {
    const { stop } = yield race({
      stop: call(api.update, "stop", {
        lang: action.lang,
        id: action.id,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (stop)
      yield put({
        type: EDIT_STOP_SUCCESS,
        ...stop.data,
      });
    else
      yield put({
        type: EDIT_STOP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_STOP_ERROR,
      error, //error: typeof error === "object" ? error : "Could not edit stop",
      fieldValue: { id: action.id, ...action.fields },
    });
  }
}
function* editStop() {
  yield takeLatest(EDIT_STOP_REQUEST, runEditStop);
}

function* runDeleteStop(action) {
  // yield put({
  //   type: DELETE_STOP_ERROR,
  //   error: {
  //     has_routes: true,
  //     confirm_dialogue: true,
  //     message: "Stop cannot be deleted. Stop is linked to routes.",
  //     action: "delete",
  //     routes_count: 10,
  //     allow_action: false,
  //   },
  // });
  try {
    const { stop } = yield race({
      stop: call(api.delete, "stop", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (stop) yield put({ type: DELETE_STOP_SUCCESS });
    else
      yield put({
        type: DELETE_STOP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_STOP_ERROR,
      error: typeof error === "object" ? error : "Could not delete stop",
    });
  }
}
function* deleteStop() {
  yield takeLatest(DELETE_STOP_REQUEST, runDeleteStop);
}

function* runDeleteBulkStop(action) {
  // yield put({
  //   type: DELETE_BULK_STOP_ERROR,
  //   error: {
  //     has_routes: true,
  //     confirm_dialogue: true,
  //     message: "Stop cannot be deleted. Stop is linked to routes.",
  //     action: "delete",
  //     routes_count: 10,
  //     allow_action: false,
  //   },
  // });
  try {
    const { stop } = yield race({
      stop: call(api.delete, "bulkStop", {
        data: { ...action.params },
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (stop) yield put({ type: DELETE_BULK_STOP_SUCCESS });
    else
      yield put({
        type: DELETE_BULK_STOP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BULK_STOP_ERROR,
      error: typeof error === "object" ? error : "Could not delete stop",
    });
  }
}
function* deleteBulkStop() {
  yield takeEvery(DELETE_BULK_STOP_REQUEST, runDeleteBulkStop);
}

function* runSearchStop(action) {
  try {
    const { stop } = yield race({
      stop: call(api.get, "searchPoi", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (stop) {
      yield put({
        type: SEARCH_STOP_SUCCESS,
        source: action.source,
        data: stop.data ? stop.data.data : [],
        filters: action.filters,
      });
    } else
      yield put({
        type: SEARCH_STOP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: SEARCH_STOP_ERROR,
      error:
        typeof error === "object" ? error : "Results could not be updated.",
    });
  }
}
function* searchStop() {
  yield takeLatest(SEARCH_STOP_REQUEST, runSearchStop);
}

export function* saga() {
  while (true) {
    yield all({
      getStops: call(getStops),
      getAllStops: call(getAllStops),
      getSingleStop: call(getSingleStop),
      addStop: call(addStop),
      editStop: call(editStop),
      deleteStop: call(deleteStop),
      deleteBulkStop: call(deleteBulkStop),
      searchStop: call(searchStop),
    });
  }
}
