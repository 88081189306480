// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";
import moment from "moment";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  ADD_TRIP_REQUEST,
  ADD_TRIP_SUCCESS,
  ADD_TRIP_ERROR,
  ADD_RECURRING_TRIP_REQUEST,
  ADD_RECURRING_TRIP_SUCCESS,
  ADD_RECURRING_TRIP_ERROR,
  EDIT_TRIP_REQUEST,
  EDIT_TRIP_SUCCESS,
  EDIT_TRIP_ERROR,
  GET_TRIP_REQUEST,
  GET_TRIP_SUCCESS,
  GET_TRIP_ERROR,
  DELETE_TRIP_REQUEST,
  DELETE_TRIP_SUCCESS,
  DELETE_TRIP_ERROR,
  DELETE_BULK_TRIP_REQUEST,
  DELETE_BULK_TRIP_SUCCESS,
  DELETE_BULK_TRIP_ERROR,
  CLEAR_TRIPS_ACTION_STATUS,
  BULK_EDIT_TRIPS_FIELDS,
  // smsCodeTimeout
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 30 * 1000; // 5 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_RECURRING_TRIP_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkAdd",
          entity: "trip",
          success: null,
          error: "",
        },
      };
    case ADD_RECURRING_TRIP_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkAdd",
          entity: "trip",
          success: true,
          error: "",
        },
      };
    case ADD_RECURRING_TRIP_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkAdd",
          entity: "trip",
          success: false,
          error: action.error,
        },
      };
    case ADD_TRIP_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "trip",
          success: null,
          error: "",
        },
      };
    case ADD_TRIP_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "trip",
          success: true,
          error: "",
        },
      };
    case ADD_TRIP_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "trip",
          success: false,
          error: action.error,
        },
      };
    case EDIT_TRIP_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "trip",
          success: null,
          error: "",
          fieldValue: undefined,
        },
      };
    case EDIT_TRIP_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "trip",
          success: true,
          error: "",
        },
      };
    case EDIT_TRIP_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "trip",
          success: false,
          error: action.error,
          fieldValue: action.fieldValue,
        },
      };
    case DELETE_TRIP_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "trip",
          success: null,
          error: "",
        },
      };
    case DELETE_TRIP_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "trip",
          success: true,
          error: "",
        },
      };
    case DELETE_TRIP_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "trip",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BULK_TRIP_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "trip",
          success: null,
          error: "",
        },
      };
    case DELETE_BULK_TRIP_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "trip",
          success: true,
          error: "",
        },
      };
    case DELETE_BULK_TRIP_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "trip",
          success: false,
          error: action.error,
        },
      };

    case GET_TRIP_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "trip",
                success: null,
                error: "",
              },
      };
    case GET_TRIP_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        trips: action.trips,
        tripsCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "trip",
                success: true,
                error: "",
              },
      };
    case GET_TRIP_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "trip",
          success: false,
          error: action.error,
        },
      };
    case CLEAR_TRIPS_ACTION_STATUS:
      return {
        ...newState,
        actionStatus: { type: "", entity: "", success: null, error: "" },
      };

    case BULK_EDIT_TRIPS_FIELDS:
      return {
        ...newState,
        bulkEditValues: action.fields,
        actionStatus: { type: "", entity: "", success: true, error: "" },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetTrips(action) {
  try {
    let url =
      action.params.filterFor === "routes"
        ? "ByRoute"
        : action.params.filterFor === "vehicles"
        ? "ByVehicle"
        : "";

    const { trip } = yield race({
      trip: call(api.get, `trip${url}`, {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (trip) {
      const trips = trip.data ? trip.data.data : [];
      yield put({
        type: GET_TRIP_SUCCESS,
        source: action.source,
        trips: trips.rows,
        count: trips.count,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_TRIP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_TRIP_ERROR,
      error: typeof error === "object" ? error : "Trips could not be updated.",
    });
  }
}
function* getTrips() {
  yield takeLatest(GET_TRIP_REQUEST, runGetTrips);
}

function* runAddTrip(action) {
  try {
    const { trip } = yield race({
      trip: call(api.add, "trip", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (trip)
      yield put({
        type: ADD_TRIP_SUCCESS,
        ...trip.data,
      });
    else
      yield put({
        type: ADD_TRIP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_TRIP_ERROR,
      error, //error: typeof error === "object" ? error : "Could not add trip",
    });
  }
}
function* addTrip() {
  yield takeLatest(ADD_TRIP_REQUEST, runAddTrip);
}

function* runAddRecurringTrip(action) {
  try {
    const { trip } = yield race({
      trip: call(api.add, "trip", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (trip)
      yield put({
        type: ADD_RECURRING_TRIP_SUCCESS,
        ...trip.data,
      });
    else
      yield put({
        type: ADD_RECURRING_TRIP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_RECURRING_TRIP_ERROR,
      error, // error: typeof error === "object" ? error : "Could not add trip",
    });
  }
}
function* addRecurringTrip() {
  yield takeEvery(ADD_RECURRING_TRIP_REQUEST, runAddRecurringTrip);
}

function* runEditTrip(action) {
  try {
    const { bulkEdit } = action?.fields || {};
    const { trip } = yield race({
      trip: call(api.update, `trip${bulkEdit ? "Bulk" : ""}`, {
        lang: action.lang,
        id: action?.id,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (trip)
      yield put({
        type: EDIT_TRIP_SUCCESS,
        ...trip.data,
      });
    else
      yield put({
        type: EDIT_TRIP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_TRIP_ERROR,
      error, // error: typeof error === "object" ? error : "Could not edit trip",
      fieldValue: { id: action.id, ...action.fields },
    });
  }
}
function* editTrip() {
  yield takeLatest(EDIT_TRIP_REQUEST, runEditTrip);
}

function* runDeleteTrip(action) {
  try {
    console.log({ action });
    const { trip } = yield race({
      trip: call(api.delete, "trip", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (trip) yield put({ type: DELETE_TRIP_SUCCESS });
    else
      yield put({
        type: DELETE_TRIP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_TRIP_ERROR,
      error: typeof error === "object" ? error : "Could not delete trip",
    });
  }
}
function* deleteTrip() {
  yield takeLatest(DELETE_TRIP_REQUEST, runDeleteTrip);
}

function* runDeleteBulkTrip(action) {
  // yield put({
  //   type: DELETE_BULK_TRIP_ERROR,
  //   error: {
  //     hasBookings: true,
  //     confirmDialogue: true,
  //     message: "Future bookings exists for this trip.",
  //     action: "edit",
  //     count: 15,
  //   },
  // });
  try {
    const { trip } = yield race({
      trip: call(api.delete, "tripBulk", {
        lang: action.lang,
        data: { ...action.params },
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (trip) yield put({ type: DELETE_BULK_TRIP_SUCCESS });
    else
      yield put({
        type: DELETE_BULK_TRIP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BULK_TRIP_ERROR,
      error: typeof error === "object" ? error : "Could not delete trip",
    });
  }
}
function* deleteBulkTrip() {
  yield takeEvery(DELETE_BULK_TRIP_REQUEST, runDeleteBulkTrip);
}

export function* saga() {
  while (true) {
    yield all({
      getTrips: call(getTrips),
      addTrip: call(addTrip),
      addRecurringTrip: call(addRecurringTrip),
      editTrip: call(editTrip),
      deleteTrip: call(deleteTrip),
      deleteBulkTrip: call(deleteBulkTrip),
    });
  }
}
