import { reducer as reportReducers } from "./reportState";
import { reducer as utilizationReducers } from "./utilizationState";
// import { reducer as loggingUser } from './login'

const core = [reportReducers, utilizationReducers];

export default function reportReducer(state, action, i) {
  if (!Number.isInteger(i)) i = 0;
  if (i < core.length)
    return reportReducer(core[i](state, action), action, i + 1);
  else return state;
}
