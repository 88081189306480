// external import

import {
  all,
  delay,
  put,
  fork,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  ADD_ROUTE_REQUEST,
  ADD_ROUTE_SUCCESS,
  ADD_ROUTE_ERROR,
  ADD_ROUTE_POI_REQUEST,
  ADD_ROUTE_POI_SUCCESS,
  ADD_ROUTE_POI_ERROR,
  GET_ROUTE_REQUEST,
  GET_ROUTE_SUCCESS,
  GET_ROUTE_ERROR,
  GET_ROUTE_POI_REQUEST,
  GET_ROUTE_POI_SUCCESS,
  GET_ROUTE_POI_ERROR,
  GET_SINGLE_ROUTE_REQUEST,
  GET_SINGLE_ROUTE_SUCCESS,
  GET_SINGLE_ROUTE_ERROR,
  EDIT_ROUTE_REQUEST,
  EDIT_ROUTE_SUCCESS,
  EDIT_ROUTE_ERROR,
  EDIT_ROUTE_POI_REQUEST,
  EDIT_ROUTE_POI_SUCCESS,
  EDIT_ROUTE_POI_ERROR,
  DELETE_ROUTE_REQUEST,
  DELETE_ROUTE_SUCCESS,
  DELETE_ROUTE_ERROR,
  DELETE_BULK_ROUTE_REQUEST,
  DELETE_BULK_ROUTE_SUCCESS,
  DELETE_BULK_ROUTE_ERROR,
  DELETE_ROUTE_POI_REQUEST,
  DELETE_ROUTE_POI_SUCCESS,
  DELETE_ROUTE_POI_ERROR,
  GET_STOPS_TIMING_REQUEST,
  GET_STOPS_TIMING_SUCCESS,
  GET_STOPS_TIMING_ERROR,
  GET_RECOMMENDED_STOPS_REQUEST,
  GET_RECOMMENDED_STOPS_SUCCESS,
  GET_RECOMMENDED_STOPS_ERROR,
  CLEAR_ROUTES_ACTION_STATUS,
  GET_ROUTE_GROUP_REQUEST,
  GET_ROUTE_GROUP_SUCCESS,
  GET_ROUTE_GROUP_ERROR,
  ADD_ROUTE_GROUP_REQUEST,
  ADD_ROUTE_GROUP_SUCCESS,
  ADD_ROUTE_GROUP_ERROR,
  EDIT_ROUTE_GROUP_REQUEST,
  EDIT_ROUTE_GROUP_SUCCESS,
  EDIT_ROUTE_GROUP_ERROR,
  DELETE_ROUTE_GROUP_REQUEST,
  DELETE_ROUTE_GROUP_SUCCESS,
  DELETE_ROUTE_GROUP_ERROR,
  CLEAR_NEW_ROUTE_GROUP,
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 50 * 1000; // 50 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_ROUTE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "route",
          success: null,
          error: "",
        },
      };
    case ADD_ROUTE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "route",
          success: true,
          error: "",
        },
      };
    case ADD_ROUTE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "route",
          success: false,
          error: action.error,
        },
      };
    case EDIT_ROUTE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "route",
          success: null,
          error: "",
          fieldValue: undefined,
        },
      };
    case EDIT_ROUTE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "route",
          success: true,
          error: "",
        },
      };
    case EDIT_ROUTE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "route",
          success: false,
          error: action.error,
          fieldValue: action.fieldValue,
        },
      };
    case DELETE_ROUTE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "route",
          success: null,
          error: "",
        },
      };
    case DELETE_ROUTE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "route",
          success: true,
          error: "",
        },
      };
    case DELETE_ROUTE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "route",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BULK_ROUTE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "route",
          success: null,
          error: "",
        },
      };
    case DELETE_BULK_ROUTE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "route",
          success: true,
          error: "",
        },
      };
    case DELETE_BULK_ROUTE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "route",
          success: false,
          error: action.error,
        },
      };

    case GET_ROUTE_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "route",
                success: null,
                error: "",
              },
      };
    case GET_ROUTE_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        routes: action.routes,
        routesCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "route",
                success: true,
                error: "",
              },
      };
    case GET_ROUTE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "route",
          success: false,
          error: action.error,
        },
      };

    case GET_ROUTE_POI_REQUEST:
      return {
        ...newState,
        routesPOIError: null,
      };
    case GET_ROUTE_POI_SUCCESS:
      return {
        ...newState,
        routesPoi: {
          ...state.routesPoi,
          [action.route_id]: action.poi,
        },
        routesPOIError: null,
      };
    case GET_ROUTE_POI_ERROR:
      return {
        ...newState,
        routesPOIError: action.error,
      };

    case ADD_ROUTE_POI_REQUEST:
      return {
        ...newState,
        routePoiAdded: false,
        routesPOIError: null,
      };
    case ADD_ROUTE_POI_SUCCESS:
      return {
        ...newState,
        routePoiAdded: true,
        routesPOIError: null,
      };
    case ADD_ROUTE_POI_ERROR:
      return {
        ...newState,
        routePoiAdded: false,
        routesPOIError: action.error,
      };
    case EDIT_ROUTE_POI_REQUEST:
      return {
        ...newState,
        routePoiEdited: false,
        routeEdited: false,
        routesPOIError: null,
      };
    case EDIT_ROUTE_POI_SUCCESS:
      return {
        ...newState,
        routePoiEdited: true,
        routeEdited: true,
        routesPOIError: null,
      };
    case EDIT_ROUTE_POI_ERROR:
      return {
        ...newState,
        routePoiEdited: false,
        routeEdited: false,
        routesPOIError: action.error,
      };
    case DELETE_ROUTE_POI_REQUEST:
      return {
        ...newState,
        routePoiDeleted: false,
        routesPOIError: null,
      };
    case DELETE_ROUTE_POI_SUCCESS:
      return {
        ...newState,
        routePoiDeleted: true,
        routesPOIError: null,
      };
    case DELETE_ROUTE_POI_ERROR:
      return {
        ...newState,
        routePoiDeleted: false,
        routesPOIError: action.error,
      };

    case GET_SINGLE_ROUTE_SUCCESS:
      return {
        ...newState,
        routes: {
          ...state.routes,
          [action.id]: action.route,
          routesError: null,
        },
      };
    case GET_SINGLE_ROUTE_ERROR:
      return {
        ...newState,
        routesError: action.error,
      };

    case GET_STOPS_TIMING_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "stopTiming",
          success: null,
          error: "",
        },
      };
    case GET_STOPS_TIMING_SUCCESS:
      return {
        ...newState,
        // stopTiming: {
        //   ...state.stopTiming,
        //   [action.key]: action.stopTiming,
        // },
        stopTiming: action.data,
        actionStatus: {
          type: "fetch",
          entity: "stopTiming",
          success: true,
          error: "",
        },
      };
    case GET_STOPS_TIMING_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "stopTiming",
          success: false,
          error: action.error,
        },
      };

    case GET_RECOMMENDED_STOPS_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "recommendedStops",
          success: null,
          error: "",
        },
      };
    case GET_RECOMMENDED_STOPS_SUCCESS:
      return {
        ...newState,
        recommendedStops: action.data,
        actionStatus: {
          type: "fetch",
          entity: "recommendedStops",
          success: true,
          error: "",
        },
      };
    case GET_RECOMMENDED_STOPS_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "recommendedStops",
          success: false,
          error: action.error,
        },
      };
    case CLEAR_ROUTES_ACTION_STATUS:
      return {
        ...newState,
        actionStatus: { type: "", entity: "", success: null, error: "" },
      };

    case ADD_ROUTE_GROUP_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "routeGroup",
          success: null,
          error: "",
        },
      };
    case ADD_ROUTE_GROUP_SUCCESS:
      return {
        ...newState,
        newRouteGroup: action.newRouteGroup,
        actionStatus: {
          type: "add",
          entity: "routeGroup",
          success: true,
          error: "",
        },
      };
    case ADD_ROUTE_GROUP_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "routeGroup",
          success: false,
          error: action.error,
        },
      };
    case EDIT_ROUTE_GROUP_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "routeGroup",
          success: null,
          error: "",
          fieldValue: undefined,
        },
      };
    case EDIT_ROUTE_GROUP_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "routeGroup",
          success: true,
          error: "",
        },
      };
    case EDIT_ROUTE_GROUP_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "routeGroup",
          success: false,
          error: action.error,
          fieldValue: action.fieldValue,
        },
      };
    case DELETE_ROUTE_GROUP_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "routeGroup",
          success: null,
          error: "",
        },
      };
    case DELETE_ROUTE_GROUP_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "routeGroup",
          success: true,
          error: "",
        },
      };
    case DELETE_ROUTE_GROUP_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "routeGroup",
          success: false,
          error: action.error,
        },
      };

    case GET_ROUTE_GROUP_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "routeGroup",
                success: null,
                error: "",
              },
      };
    case GET_ROUTE_GROUP_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        routeGroup: action.routeGroup,
        routeGroupCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "routeGroup",
                success: true,
                error: "",
              },
      };
    case GET_ROUTE_GROUP_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "routeGroup",
          success: false,
          error: action.error,
        },
      };
    case CLEAR_NEW_ROUTE_GROUP:
      return {
        ...newState,
        newRouteGroup: undefined,
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetRecommendedStops(action) {
  try {
    const { stops } = yield race({
      stops: call(api.get, "recommendedStop", {
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (stops)
      yield put({
        type: GET_RECOMMENDED_STOPS_SUCCESS,
        data: stops.data && stops.data.data,
      });
    else
      yield put({
        type: GET_RECOMMENDED_STOPS_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_RECOMMENDED_STOPS_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Recommended stops could not be updated.",
    });
  }
}
function* getRecommendedStops() {
  yield takeLatest(GET_RECOMMENDED_STOPS_REQUEST, runGetRecommendedStops);
}

function* runGetStopTiming(action) {
  try {
    const { route } = yield race({
      route: call(api.get, "stopTiming", {
        service: "routing",
        isAuth: false,
        id: action.coordinates.join(";"),
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (route) {
      const routeData = route.data && route.data.routes && route.data.routes[0];
      yield put({
        type: GET_STOPS_TIMING_SUCCESS,
        data: routeData,
      });
    } else
      yield put({
        type: GET_STOPS_TIMING_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_STOPS_TIMING_ERROR,
      error:
        typeof error === "object" ? error : "Stop stats could not be updated.",
    });
  }
}
function* getStopTiming() {
  yield takeEvery(GET_STOPS_TIMING_REQUEST, runGetStopTiming);
}

function* runGetRoutePoi(action) {
  try {
    const { route } = yield race({
      route: call(api.get, "routePoi", {
        route_id: action.id,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (route)
      yield put({
        type: GET_ROUTE_POI_SUCCESS,
        poi: route.data,
        route_id: action.id,
      });
    else
      yield put({
        type: GET_ROUTE_POI_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_ROUTE_POI_ERROR,
      error: typeof error === "object" ? error : "Stops could not be updated.",
    });
  }
}
function* getRoutePoi() {
  yield takeEvery(GET_ROUTE_POI_REQUEST, runGetRoutePoi);
}

function* runGetSingleRoute(action) {
  try {
    const { route } = yield race({
      route: call(api.get, "route", {
        lang: action.lang,
        id: action.id,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (route)
      yield put({
        type: GET_SINGLE_ROUTE_SUCCESS,
        route: route.data,
        id: action.id,
      });
    else
      yield put({
        type: GET_SINGLE_ROUTE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_SINGLE_ROUTE_ERROR,
      error: typeof error === "object" ? error : "Route could not be updated.",
    });
  }
}
function* getSingleRoute() {
  yield takeLatest(GET_SINGLE_ROUTE_REQUEST, runGetSingleRoute);
}

function* runGetRoutes(action) {
  try {
    let url = action.params.filterFor === "stops" ? "ByStop" : "";

    const { route } = yield race({
      route: call(api.get, `route${url}`, {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (route) {
      const routes = route.data ? route.data.data : [];
      yield put({
        type: GET_ROUTE_SUCCESS,
        source: action.source,
        routes: routes.rows,
        count: routes.count,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_ROUTE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_ROUTE_ERROR,
      error: typeof error === "object" ? error : "Routes could not be updated.",
    });
  }
}

function* getRoutes() {
  yield takeLatest(GET_ROUTE_REQUEST, runGetRoutes);
}

function* runAddRoute(action) {
  try {
    const { route } = yield race({
      route: call(api.add, "route", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (route) {
      yield put({
        type: ADD_ROUTE_SUCCESS,
        ...route.data,
      });
    } else
      yield put({
        type: ADD_ROUTE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_ROUTE_ERROR,
      error, //error: typeof error === "object" ? error : "Could not add route",
    });
  }
}
function* addRoute() {
  yield takeLatest(ADD_ROUTE_REQUEST, runAddRoute);
}

function* runAddChildRoutePoi({ poi_id, route_id, order, arrival, departure }) {
  try {
    const { routePoi } = yield race({
      routePoi: call(api.add, "routePoi", {
        route_id,
        poi_id,
        poi_order: order,
        departure: departure * 60,
        arrival: arrival * 60,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (routePoi) {
      yield put({
        type: ADD_ROUTE_POI_SUCCESS,
      });
    } else
      yield put({
        type: ADD_ROUTE_POI_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_ROUTE_POI_ERROR,
      error, //error: typeof error === "object" ? error : "Could not add stop",
    });
  }
}

function* routePoi(item, route_id, order) {
  const task = yield fork(runAddChildRoutePoi, {
    poi_id: item.id,
    arrival: item.arrival,
    departure: item.departure,
    route_id,
    order,
  });

  const { error } = yield race({
    success: take(ADD_ROUTE_POI_SUCCESS),
    error: take(ADD_ROUTE_POI_ERROR),
  });

  if (error) {
    yield cancel(task);
  }
}

function* addChildRoutePoi(items, routeId) {
  yield items.map((item, order) => call(routePoi, item, routeId, order));
}

function* runAddRoutePoi(action) {
  try {
    const { route } = yield race({
      route: call(api.add, "routePoi", {
        ...action.fields,
        departure: action.fields.departure * 60,
        arrival: action.fields.arrival * 60,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (route) yield put({ type: ADD_ROUTE_POI_SUCCESS });
    else
      yield put({
        type: ADD_ROUTE_POI_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_ROUTE_POI_ERROR,
      error, // error: typeof error === "object" ? error : "Could not add stop",
    });
  }
}
function* addRoutePoi() {
  yield takeEvery(ADD_ROUTE_POI_REQUEST, runAddRoutePoi);
}

function* runEditRoute(action) {
  // yield put({
  //   type: EDIT_ROUTE_ERROR,
  //   error: {
  //     has_trips: true,
  //     confirm_dialogue: true,
  //     message: "Route cannot be edited. Route is linked to trips.",
  //     action: "edit",
  //     trips_count: 10,
  //     allow_action: true,
  //   },
  //   fieldValue: { id: action.id, ...action.fields },
  // });
  try {
    const { route } = yield race({
      route: call(api.update, "route", {
        lang: action.lang,
        id: action.id,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (route) {
      yield put({
        type: EDIT_ROUTE_SUCCESS,
        ...route.data,
      });
    } else
      yield put({
        type: EDIT_ROUTE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_ROUTE_ERROR,
      error, //error: typeof error === "object" ? error : "Could not edit route",
      fieldValue: { id: action.id, ...action.fields },
    });
  }
}
function* editRoute() {
  yield takeLatest(EDIT_ROUTE_REQUEST, runEditRoute);
}

function* runEditRoutePoi(action) {
  try {
    const { route } = yield race({
      route: call(api.update, "routePoi", {
        ...action.fields,
        departure: action.fields.departure * 60,
        arrival: action.fields.arrival * 60,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (route) yield put({ type: EDIT_ROUTE_POI_SUCCESS });
    else
      yield put({
        type: EDIT_ROUTE_POI_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_ROUTE_POI_ERROR,
      error, //error: typeof error === "object" ? error : "Could not edit stop",
    });
  }
}
function* editRoutePoi() {
  yield takeEvery(EDIT_ROUTE_POI_REQUEST, runEditRoutePoi);
}

function* runDeleteRoute(action) {
  // yield put({
  //   type: DELETE_ROUTE_ERROR,
  //   error: {
  //     has_trips: true,
  //     confirm_dialogue: true,
  //     message: "Route cannot be deleted. Route is linked to trips.",
  //     action: "delete",
  //     trips_count: 10,
  //     allow_action: false,
  //   },
  // });
  try {
    const { route } = yield race({
      route: call(api.delete, "route", { lang: action.lang, id: action.id }),
      timeout: delay(parseResponseTimeout),
    });

    if (route) yield put({ type: DELETE_ROUTE_SUCCESS });
    else
      yield put({
        type: DELETE_ROUTE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_ROUTE_ERROR,
      error: typeof error === "object" ? error : "Could not delete route",
    });
  }
}
function* deleteRoute() {
  yield takeLatest(DELETE_ROUTE_REQUEST, runDeleteRoute);
}

function* runDeleteBulkRoute(action) {
  // yield put({
  //   type: DELETE_BULK_ROUTE_ERROR,
  //   error: {
  //     has_trips: true,
  //     confirm_dialogue: true,
  //     message: "Route cannot be deleted. Route is linked to trips.",
  //     action: "delete",
  //     trips_count: 10,
  //     allow_action: false,
  //   },
  // });
  try {
    const { route } = yield race({
      route: call(api.delete, "bulkRoute", {
        lang: action.lang,
        data: { ...action.params },
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (route) yield put({ type: DELETE_BULK_ROUTE_SUCCESS });
    else
      yield put({
        type: DELETE_BULK_ROUTE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BULK_ROUTE_ERROR,
      error: typeof error === "object" ? error : "Could not delete route",
    });
  }
}
function* deleteBulkRoute() {
  yield takeEvery(DELETE_BULK_ROUTE_REQUEST, runDeleteBulkRoute);
}

function* runDeleteRoutePoi(action) {
  try {
    const { route } = yield race({
      route: call(api.delete, "routePoi", action.id),
      timeout: delay(parseResponseTimeout),
    });

    if (route) yield put({ type: DELETE_ROUTE_POI_SUCCESS });
    else
      yield put({
        type: DELETE_ROUTE_POI_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_ROUTE_POI_ERROR,
      error: typeof error === "object" ? error : "Could not delete route poi",
    });
  }
}
function* deleteRoutePoi() {
  yield takeEvery(DELETE_ROUTE_POI_REQUEST, runDeleteRoutePoi);
}

function* runGetRouteGroups(action) {
  try {
    const { routeGroup } = yield race({
      routeGroup: call(api.get, "routeGroup", {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (routeGroup) {
      yield put({
        type: GET_ROUTE_GROUP_SUCCESS,
        source: action.source,
        routeGroup: routeGroup?.data?.data?.rows || [],
        count: routeGroup?.data?.data?.count || 0,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_ROUTE_GROUP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_ROUTE_GROUP_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Route groups could not be updated.",
    });
  }
}
function* getRouteGroup() {
  yield takeLatest(GET_ROUTE_GROUP_REQUEST, runGetRouteGroups);
}

function* runAddRouteGroup(action) {
  const { persist, ...rest } = action;
  try {
    let extraUrl = "";
    const { routeGroup } = yield race({
      routeGroup: call(api.add, `routeGroup${extraUrl}`, {
        ...rest.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (routeGroup) {
      yield put({
        type: ADD_ROUTE_GROUP_SUCCESS,
        ...routeGroup.data,
        newRouteGroup: persist ? routeGroup?.data?.data : undefined,
      });
    } else
      yield put({
        type: ADD_ROUTE_GROUP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_ROUTE_GROUP_ERROR,
      error,
      //error: typeof error === "object" ? error : "Could not add routeGroup",
    });
  }
}

function* addRouteGroup() {
  yield takeLatest(ADD_ROUTE_GROUP_REQUEST, runAddRouteGroup);
}

function* runEditRouteGroup(action) {
  try {
    // Separate routeGroup fields from user fields

    // Update user details
    const { users } = yield race({
      users: call(api.update, "routeGroup", {
        lang: action.lang,
        id: action.id,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (users)
      yield put({
        type: EDIT_ROUTE_GROUP_SUCCESS,
        ...users.data,
      });
    else
      yield put({
        type: EDIT_ROUTE_GROUP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_ROUTE_GROUP_ERROR,
      error,
      //error: typeof error === "object" ? error : "Could not edit routeGroup",
    });
  }
}
function* editRouteGroup() {
  yield takeLatest(EDIT_ROUTE_GROUP_REQUEST, runEditRouteGroup);
}

function* runDeleteRouteGroup(action) {
  try {
    const { routeGroup } = yield race({
      routeGroup: call(
        api.delete,
        `routeGroup${action.params?.ids ? "Bulk" : ""}`,
        {
          id: action.params?.id || undefined,
          data: action.params?.ids || undefined,
          lang: action.lang,
        }
      ),
      timeout: delay(parseResponseTimeout),
    });

    if (routeGroup) {
      let obj = { type: DELETE_ROUTE_GROUP_SUCCESS };
      yield put(obj);
    } else
      yield put({
        type: DELETE_ROUTE_GROUP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_ROUTE_GROUP_ERROR,
      error: typeof error === "object" ? error : "Could not delete routeGroups",
    });
  }
}
function* deleteRouteGroup() {
  yield takeLatest(DELETE_ROUTE_GROUP_REQUEST, runDeleteRouteGroup);
}

export function* saga() {
  while (true) {
    yield all({
      getRoutes: call(getRoutes),
      getSingleRoute: call(getSingleRoute),
      addRoute: call(addRoute),
      editRoute: call(editRoute),
      deleteRoute: call(deleteRoute),
      deleteBulkRoute: call(deleteBulkRoute),
      getRoutePoi: call(getRoutePoi),
      addRoutePoi: call(addRoutePoi),
      editRoutePoi: call(editRoutePoi),
      deleteRoutePoi: call(deleteRoutePoi),
      getStopTiming: call(getStopTiming),
      getRecommendedStops: call(getRecommendedStops),
      getRouteGroup: call(getRouteGroup),
      addRouteGroup: call(addRouteGroup),
      editRouteGroup: call(editRouteGroup),
      deleteRouteGroup: call(deleteRouteGroup),
    });
  }
}
