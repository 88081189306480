// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";
import moment from "moment";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  NOTIFICATION_SUBSCRIBE_REQUEST,
  NOTIFICATION_SUBSCRIBE_SUCCESS,
  NOTIFICATION_SUBSCRIBE_ERROR,
  NOTIFICATION_UNSUBSCRIBE_REQUEST,
  NOTIFICATION_UNSUBSCRIBE_SUCCESS,
  NOTIFICATION_UNSUBSCRIBE_ERROR,
  SETTING_REQUEST,
  SETTING_SUCCESS,
  SETTING_ERROR,
  EDIT_SETTING_REQUEST,
  EDIT_SETTING_SUCCESS,
  EDIT_SETTING_ERROR,
  CLEAR_SETTING_ACTION_STATUS,
  UPLOAD_MANIFEST_REQUEST,
  UPLOAD_MANIFEST_SUCCESS,
  UPLOAD_MANIFEST_ERROR,
  GET_MANIFEST_SUCCESS,
  GET_MANIFEST_ERROR,
  GET_MANIFEST_REQUEST,
  REMOVE_MANIFEST_REQUEST,
  REMOVE_MANIFEST_SUCCESS,
  REMOVE_MANIFEST_ERROR,
  // CONTACT_INFO_REQUEST,
  // CONTACT_INFO_SUCCESS,
  // CONTACT_INFO_ERROR,
} from "../constants";

// get initial state
import initialState from "./initialState";
import { CHECK_APP_VERSION_SUCCESS } from "../../core/constants";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 50 * 1000; // 50 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case NOTIFICATION_SUBSCRIBE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "subscribe",
          entity: "notification",
          success: null,
          error: "",
        },
      };
    case NOTIFICATION_SUBSCRIBE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "subscribe",
          entity: "notification",
          success: true,
          error: "",
        },
      };
    case NOTIFICATION_SUBSCRIBE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "subscribe",
          entity: "notification",
          success: false,
          error: action.error,
        },
      };

    case NOTIFICATION_UNSUBSCRIBE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "unsubscribe",
          entity: "notification",
          success: null,
          error: "",
        },
      };
    case NOTIFICATION_UNSUBSCRIBE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "unsubscribe",
          entity: "notification",
          success: true,
          error: "",
        },
      };
    case NOTIFICATION_UNSUBSCRIBE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "unsubscribe",
          entity: "notification",
          success: false,
          error: action.error,
        },
      };
    case SETTING_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "setting",
                success: null,
                error: "",
              },
      };
    case SETTING_SUCCESS:
      return {
        ...newState,
        setting: action.setting,
        actionStatus: {
          type: "fetch",
          entity: "setting",
          success: true,
          error: " ",
        },
      };
    case SETTING_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "setting",
          success: false,
          error: action.error,
        },
      };
    case EDIT_SETTING_REQUEST:
      return {
        ...newState,
        // setting: { ...newState.setting, ...action.editedData },
        actionStatus: {
          type: action?.actionType || "edit",
          entity: "setting",
          success: null,
          error: "",
        },
      };
    case EDIT_SETTING_SUCCESS:
      return {
        ...newState,
        setting: null,
        actionStatus: {
          type: action?.actionType || "edit",
          entity: "setting",
          success: true,
          error: "",
        },
      };
    case EDIT_SETTING_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: action?.actionType || "edit",
          entity: "setting",
          success: false,
          error: action.error,
        },
      };

    case UPLOAD_MANIFEST_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "upload",
          entity: "uploadManifest",
          success: null,
          error: "",
        },
      };
    case UPLOAD_MANIFEST_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "upload",
          entity: "uploadManifest",
          success: true,
          error: "",
        },
      };
    case UPLOAD_MANIFEST_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "upload",
          entity: "uploadManifest",
          success: false,
          error: action.error,
        },
      };

    case GET_MANIFEST_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "uploadManifest",
          success: null,
          error: "",
        },
      };
    case GET_MANIFEST_SUCCESS:
      return {
        ...newState,
        busManifest: action.data,
        manifestUrl: action.data?.manifest_file,
        actionStatus: {
          type: "fetch",
          entity: "uploadManifest",
          success: true,
          error: "",
        },
      };
    case GET_MANIFEST_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "uploadManifest",
          success: false,
          error: action.error,
        },
      };

    case REMOVE_MANIFEST_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "uploadManifest",
          success: null,
          error: "",
        },
      };
    case REMOVE_MANIFEST_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "uploadManifest",
          success: true,
          error: "",
        },
      };
    case REMOVE_MANIFEST_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "uploadManifest",
          success: false,
          error: action.error,
        },
      };

    case CLEAR_SETTING_ACTION_STATUS:
      return {
        ...newState,
        actionStatus: {
          type: "",
          entity: "",
          success: null,
          error: null,
        },
      };

    case CHECK_APP_VERSION_SUCCESS:
      let prevManifestUrl = newState?.manifestUrl,
        newManifestUrl = action?.tripManifest;
      if (prevManifestUrl !== newManifestUrl) {
        return { ...newState, manifestUrl: newManifestUrl };
      } else return { ...newState };

    // case CONTACT_INFO_SUCCESS:
    //   return {
    //     ...newState,
    //     contactInfo: action.contactInfo,
    //     actionStatus: {
    //       type: "fetch",
    //       entity: "contactInfo",
    //       success: true,
    //       error: " ",
    //     },
    //   };
    // case CONTACT_INFO_ERROR:
    //   return {
    //     ...newState,
    //     actionStatus: {
    //       type: "fetch",
    //       entity: "contactInfo",
    //       success: false,
    //       error: action.error,
    //     },
    //   };

    case "LOGOUT_SUCCESS":
      return {};
    case "CLS_TOK":
      return {
        ...newState,
        tkn: "",
        r_tkn: "",
        err: "",
        errV: "",
      };
    case "REQ_TOK":
      return {
        ...newState,
        tkn: "",
        err: action.err || "",
      };
    case "REQ_TOK_SUC":
      return {
        ...newState,
        tkn: action.tkn.token,
        err: action.err || "",
        rfs: "",
      };
    case "REQ_TOK_ERR":
      return {
        ...newState,
        err: action.err || "",
        rfs: "",
      };
    case "HIT_API_REQ":
      return {
        ...newState,
        err: action.err || "",
      };
    case "HIT_API_SUC":
      return {
        ...newState,
        err: action.err || "",
        suc: action.suc || "",
      };
    case "HIT_API_ERR":
      return {
        ...newState,
        err: action.err || "",
      };
    case "UPDATE_TOKEN":
      return {
        ...newState,
        tkn: action.token,
        rfs: action.rfs,
      };
    case "VALIDATION_ERROR":
      return {
        ...newState,
        errV: action.err || "",
      };
    default:
      return state;
  }
}

function* runSubscribeToTopic(action) {
  try {
    const { subscription } = yield race({
      subscription: call(api.add, "subscribeToTopic", {
        lang: action.lang,
        admin: false,
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (subscription) {
      yield put({
        type: NOTIFICATION_SUBSCRIBE_SUCCESS,
      });
    } else
      yield put({
        type: NOTIFICATION_SUBSCRIBE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: NOTIFICATION_SUBSCRIBE_ERROR,
      error: typeof error === "object" ? error : "Could not subscribe to topic",
    });
  }
}
function* subscribeToTopic() {
  yield takeLatest(NOTIFICATION_SUBSCRIBE_REQUEST, runSubscribeToTopic);
}

function* runUnsubscribeToTopic(action) {
  try {
    const { subscription } = yield race({
      subscription: call(api.add, "unsubscribeFromTopic", {
        lang: action.lang,
        admin: false,
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (subscription) {
      yield put({
        type: NOTIFICATION_UNSUBSCRIBE_SUCCESS,
      });
    } else
      yield put({
        type: NOTIFICATION_UNSUBSCRIBE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: NOTIFICATION_UNSUBSCRIBE_ERROR,
      error:
        typeof error === "object" ? error : "Could not unsubscribe from topic",
    });
  }
}
function* unsubscribeToTopic() {
  yield takeLatest(NOTIFICATION_UNSUBSCRIBE_REQUEST, runUnsubscribeToTopic);
}

function* runGetTkn(action) {
  try {
    const { tkn } = yield race({
      tkn: call(api.getT, "getTestToken", {}),
      timeout: delay(parseResponseTimeout),
    });

    if (tkn) {
      yield put({
        type: "REQ_TOK_SUC",
        tkn: tkn.data.data,
        err: "",
      });
    } else
      yield put({
        type: "REQ_TOK_ERR",
        err: "error requesting token",
      });
  } catch (error) {
    yield put({
      type: "REQ_TOK_ERR",
      err: error.error,
    });
  }
}
function* runHitApi(action) {
  try {
    const { tkn } = yield race({
      tkn: call(api.getT, "checkTestToken", {
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (tkn) {
      yield put({
        type: "HIT_API_SUC",
        err: "",
        suc: tkn.data.message,
      });
    } else
      yield put({
        type: "HIT_API_ERR",
        err: error?.message || error,
      });
  } catch (error) {
    yield put({
      type: "HIT_API_ERR",
      err: error.error,
    });
  }
}

// function* runGetContactInfo(action) {
//   try {
//     const { res } = yield race({
//       res: call(api.get, "setting", {
//         lang: action.lang,
//       }),
//       timeout: delay(parseResponseTimeout),
//     });

//     if (res) {
//       yield put({
//         type: CONTACT_INFO_SUCCESS,
//         error: "",
//         contactInfo: res.data,
//       });
//     } else
//       yield put({
//         type: CONTACT_INFO_ERROR,
//         error: error?.message || error,
//       });
//   } catch (error) {
//     yield put({
//       type: CONTACT_INFO_ERROR,
//       error: error.error,
//     });
//   }
// }

// function* callGetContactInfo() {
//   yield takeLatest(CONTACT_INFO_REQUEST, runGetContactInfo);
// }

function* runGetSetting(action) {
  try {
    const { res } = yield race({
      res: call(api.get, "settingDetail", {
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (res) {
      yield put({
        type: SETTING_SUCCESS,
        error: "",
        setting: res?.data?.data,
      });
    } else
      yield put({
        type: SETTING_ERROR,
        error: error?.message || error,
      });
  } catch (error) {
    yield put({
      type: SETTING_ERROR,
      error: error.error,
    });
  }
}

function* getSetting() {
  yield takeLatest(SETTING_REQUEST, runGetSetting);
}

function* runEditSetting(action) {
  try {
    const { setting } = yield race({
      setting: call(api.update, "setting", {
        lang: action.lang,
        id: action.id,
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (setting)
      yield put({
        type: EDIT_SETTING_SUCCESS,
        editedData: { ...action.params },
        actionType: action?.actionType,
        ...setting.data,
      });
    else
      yield put({
        type: EDIT_SETTING_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_SETTING_ERROR,
      error, //error: typeof error === "object" ? error : "Could not edit setting",
      actionType: action?.actionType,
    });
  }
}

function* editSetting() {
  yield takeLatest(EDIT_SETTING_REQUEST, runEditSetting);
}

function* runUploadManifest(action) {
  try {
    const { response } = yield race({
      response: call(api.upload, "uploadManifest", {
        lang: action.lang,
        file: action.file,
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout * 5),
    });

    if (response)
      yield put({
        type: UPLOAD_MANIFEST_SUCCESS,
        ...response.data,
      });
    else
      yield put({
        type: UPLOAD_MANIFEST_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: UPLOAD_MANIFEST_ERROR,
      error,
    });
  }
}
function* uploadManifest() {
  yield takeLatest(UPLOAD_MANIFEST_REQUEST, runUploadManifest);
}

function* runGetManifest(action) {
  try {
    const { response } = yield race({
      response: call(api.get, "getManifest", {
        lang: action.lang,
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response)
      yield put({
        type: GET_MANIFEST_SUCCESS,
        data: response?.data?.data,
      });
    else
      yield put({
        type: GET_MANIFEST_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_MANIFEST_ERROR,
      error,
    });
  }
}
function* getManifest() {
  yield takeLatest(GET_MANIFEST_REQUEST, runGetManifest);
}

function* runRemoveManifest(action) {
  try {
    const { response } = yield race({
      response: call(api.delete, "removeManifest", {
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response)
      yield put({
        type: REMOVE_MANIFEST_SUCCESS,
      });
    else
      yield put({
        type: REMOVE_MANIFEST_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: REMOVE_MANIFEST_ERROR,
      error,
    });
  }
}
function* removeManifest() {
  yield takeLatest(REMOVE_MANIFEST_REQUEST, runRemoveManifest);
}

function* getTkn() {
  yield takeLatest("REQ_TOK", runGetTkn);
}
function* hitApi() {
  yield takeLatest("HIT_API_REQ", runHitApi);
}

export function* saga() {
  while (true) {
    yield all({
      subscribeToTopic: call(subscribeToTopic),
      unsubscribeToTopic: call(unsubscribeToTopic),
      getTkn: call(getTkn),
      hitApi: call(hitApi),
      getSetting: call(getSetting),
      editSetting: call(editSetting),
      uploadManifest: call(uploadManifest),
      getManifest: call(getManifest),
      removeManifest: call(removeManifest),
      // getContactInfo: call(callGetContactInfo),
    });
  }
}
