import { reducer as settingsReducers } from "./settingsState";
// import { reducer as loggingUser } from './login'

const core = [
  settingsReducers,
  // loggingUser
];

export default function settingsReducer(state, action, i) {
  if (!Number.isInteger(i)) i = 0;
  if (i < core.length)
    return settingsReducer(core[i](state, action), action, i + 1);
  else return state;
}
