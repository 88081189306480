// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";
import moment from "moment";

// internal imports
import api from "../../../utils/api/general";
import { replaceErrors } from "../../../utils/handleError";
// get action names
import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  GET_PASSENGER_REQUEST,
  GET_PASSENGER_SUCCESS,
  GET_PASSENGER_ERROR,
  CLEAR_USERS_ACTION_STATUS,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  CLEAR_USERS_LIST,
  GET_ADMIN_REQUEST,
  GET_ADMIN_SUCCESS,
  GET_ADMIN_ERROR,
  CLEAR_LINKED_USER,
  DELETE_PASSENGER_RELATED_ENTITY_REQUEST,
  DELETE_PASSENGER_RELATED_ENTITY_ERROR,
  DELETE_PASSENGER_RELATED_ENTITY_SUCCESS,
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 30 * 1000; // 5 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case GET_PASSENGER_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "passenger",
                success: null,
                error: "",
              },
      };
    case GET_PASSENGER_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        passengers: action.passengers,
        passengersCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "passenger",
                success: true,
                error: "",
              },
      };
    case GET_PASSENGER_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "passenger",
          success: false,
          error: action.error,
        },
      };
    case CLEAR_USERS_LIST:
      return {
        ...newState,
        users: [],
        userCount: 0,
        actionStatus: {},
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "resetPassword",
          entity: action.user,
          success: null,
          error: "",
        },
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "resetPassword",
          entity: action.user,
          success: true,
          error: "",
        },
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "resetPassword",
          entity: action.user,
          success: false,
          error: action.error,
        },
      };

    case GET_USERS_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "users",
                success: null,
                error: "",
              },
      };

    case GET_USERS_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        users: action.users,
        userCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "users",
                success: true,
                error: "",
              },
      };
    case GET_USERS_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "users",
          success: false,
          error: action.error,
        },
      };

    case GET_ADMIN_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "admin",
                success: null,
                error: "",
              },
      };

    case GET_ADMIN_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        admin: action.users,
        adminCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "admin",
                success: true,
                error: "",
              },
      };
    case GET_ADMIN_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "admin",
          success: false,
          error: action.error,
        },
      };

    case CLEAR_USERS_ACTION_STATUS:
      return {
        ...newState,
        actionStatus: { type: "", entity: "", success: null, error: "" },
      };
    case CLEAR_LINKED_USER:
      return {
        ...newState,
        userLinked: null,
      };
    case DELETE_PASSENGER_RELATED_ENTITY_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "relatedPassenger",
          success: null,
          error: "",
        },
      };
    case DELETE_PASSENGER_RELATED_ENTITY_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "relatedPassenger",
          success: true,
          error: "",
        },
      };
    case DELETE_PASSENGER_RELATED_ENTITY_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "relatedPassenger",
          success: false,
          error: action.error,
        },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetPassengers(action) {
  try {
    const { passenger } = yield race({
      passenger: call(api.get, `passenger${action.search ? "Search" : ""}`, {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (passenger) {
      const passengers = passenger.data ? passenger.data.data : [];

      yield put({
        type: GET_PASSENGER_SUCCESS,
        source: action.source,
        passengers: passengers.rows,
        count: passengers.count,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_PASSENGER_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_PASSENGER_ERROR,
      error:
        typeof error === "object" ? error : "Passengers could not be updated",
    });
  }
}
function* getPassengers() {
  yield takeLatest(GET_PASSENGER_REQUEST, runGetPassengers);
}

function* runGetUsers(action) {
  try {
    let url = "";
    if (action.filterFor === "bus-operator") url = "FilterBo";
    if (action.filterFor === "staff") url = "Search";
    const { users } = yield race({
      users: call(api.get, `passenger${url}`, {
        lang: action.lang,
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (users) {
      const userObj = users.data ? users.data.data : {};

      yield put({
        type: GET_USERS_SUCCESS,
        source: action.source,
        users: userObj.rows,
        count: userObj.count,
        filters: action.filters,
        filter: action.filter,
      });
    } else
      yield put({
        type: GET_USERS_ERROR,
        error: errors.requestTimeout.message,
        filter: action.filter,
      });
  } catch (error) {
    yield put({
      type: GET_USERS_ERROR,
      error: typeof error === "object" ? error : "Users could not be fetched.",
      filter: action.filter,
    });
  }
}
function* getUsers() {
  yield takeLatest(GET_USERS_REQUEST, runGetUsers);
}

function* runGetAdmins(action) {
  try {
    // let url = "";
    // if (action.filterFor === "bus-operator") url = "FilterBo";
    // if (action.filterFor === "staff") url = "Search";
    const { users } = yield race({
      users: call(api.get, `adminSearch`, {
        lang: action.lang,
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (users) {
      const userObj = users.data ? users.data.data : {};

      yield put({
        type: GET_ADMIN_SUCCESS,
        source: action.source,
        users: userObj.rows,
        count: userObj.count,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_ADMIN_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_ADMIN_ERROR,
      error: typeof error === "object" ? error : "Admin could not be fetched.",
    });
  }
}

function* getAdmins() {
  yield takeLatest(GET_ADMIN_REQUEST, runGetAdmins);
}

function* runResetPassword(action) {
  try {
    const { response } = yield race({
      response: call(api.update, "focalPoint", {
        lang: action.lang,
        id: action.id,
        service: "users",
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response)
      yield put({
        type: RESET_PASSWORD_SUCCESS,
        user: action.user,
        ...response.data,
      });
    else
      yield put({
        type: RESET_PASSWORD_ERROR,
        user: action.user,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: RESET_PASSWORD_ERROR,
      user: action.user,
      error: typeof error === "object" ? error : "Could not reset password",
    });
  }
}
function* resetPassword() {
  yield takeLatest(RESET_PASSWORD_REQUEST, runResetPassword);
}

function* runDeletePassengerRelatedEntity(action) {
  try {
    console.log({ action });
    const { response } = yield race({
      response: call(
        api.delete,
        `relatedPassenger${action.params?.user_ids ? "Bulk" : ""}`,
        {
          lang: action.lang,
          data: { ...action.params },
        }
      ),
      timeout: delay(parseResponseTimeout),
    });

    if (response)
      yield put({
        type: DELETE_PASSENGER_RELATED_ENTITY_SUCCESS,
        ...response.data,
      });
    else
      yield put({
        type: DELETE_PASSENGER_RELATED_ENTITY_ERROR,
        user: action.user,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_PASSENGER_RELATED_ENTITY_ERROR,
      user: action.user,
      error:
        typeof error === "object" ? error : "Could not delete linked users",
    });
  }
}
function* deletePassengerRelatedEntity() {
  yield takeLatest(
    DELETE_PASSENGER_RELATED_ENTITY_REQUEST,
    runDeletePassengerRelatedEntity
  );
}

export function* saga() {
  while (true) {
    yield all({
      getPassengers: call(getPassengers),
      resetPassword: call(resetPassword),
      getUsers: call(getUsers),
      getAdmins: call(getAdmins),
      deletePassengerRelatedEntity: call(deletePassengerRelatedEntity),
    });
  }
}
