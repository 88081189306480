// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  ADD_HARDWARE_REQUEST,
  ADD_HARDWARE_SUCCESS,
  ADD_HARDWARE_ERROR,
  EDIT_HARDWARE_REQUEST,
  EDIT_HARDWARE_SUCCESS,
  EDIT_HARDWARE_ERROR,
  GET_HARDWARE_REQUEST,
  GET_HARDWARE_SUCCESS,
  GET_HARDWARE_ERROR,
  DELETE_HARDWARE_REQUEST,
  DELETE_HARDWARE_SUCCESS,
  DELETE_HARDWARE_ERROR,
  DELETE_BULK_HARDWARE_REQUEST,
  DELETE_BULK_HARDWARE_SUCCESS,
  DELETE_BULK_HARDWARE_ERROR,
  CLEAR_HARDWARE_ACTION_STATUS,
  // smsCodeTimeout
} from "../constants";

// get initial state
import initialState from "./initialState";
import { CLEAR_CONTRACTS_ACTION_STATUS } from "../../contracts/constants";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 50 * 1000; // 50 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_HARDWARE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "hardware",
          success: null,
          error: "",
        },
      };
    case ADD_HARDWARE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "hardware",
          success: true,
          error: "",
        },
      };
    case ADD_HARDWARE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "hardware",
          success: false,
          error: action.error,
        },
      };

    case EDIT_HARDWARE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "hardware",
          success: null,
          error: "",
        },
      };
    case EDIT_HARDWARE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "hardware",
          success: true,
          error: "",
        },
      };
    case EDIT_HARDWARE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "hardware",
          success: false,
          error: action.error,
        },
      };
    case DELETE_HARDWARE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "hardware",
          success: null,
          error: "",
        },
      };
    case DELETE_HARDWARE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "hardware",
          success: true,
          error: "",
        },
      };
    case DELETE_HARDWARE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "hardware",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BULK_HARDWARE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "hardware",
          success: null,
          error: "",
        },
      };
    case DELETE_BULK_HARDWARE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "hardware",
          success: true,
          error: "",
        },
      };
    case DELETE_BULK_HARDWARE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "hardware",
          success: false,
          error: action.error,
        },
      };

    case GET_HARDWARE_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "hardware",
                success: null,
                error: "",
              },
      };
    case GET_HARDWARE_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        hardware: action.hardware,
        hardwareCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "hardware",
                success: true,
                error: "",
              },
      };
    case GET_HARDWARE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "hardware",
          success: false,
          error: action.error,
        },
      };
    case CLEAR_HARDWARE_ACTION_STATUS:
      return {
        ...newState,
        actionStatus: {
          type: "",
          entity: "",
          success: null,
          error: "",
        },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetHardware(action) {
  try {
    const { hardware } = yield race({
      hardware: call(api.get, "hardware", {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (hardware) {
      const hardwareRaw = hardware.data ? hardware.data.data : [];
      yield put({
        type: GET_HARDWARE_SUCCESS,
        source: action.source,
        hardware: hardwareRaw.rows,
        count: hardwareRaw.count,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_HARDWARE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_HARDWARE_ERROR,
      error:
        typeof error === "object" ? error : "Hardware could not be updated.",
    });
  }
}
function* getHardware() {
  yield takeLatest(GET_HARDWARE_REQUEST, runGetHardware);
}

function* runAddHardware(action) {
  try {
    const { hardware } = yield race({
      hardware: call(api.add, "hardware", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (hardware)
      yield put({
        type: ADD_HARDWARE_SUCCESS,
        ...hardware.data,
      });
    else
      yield put({
        type: ADD_HARDWARE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_HARDWARE_ERROR,
      // error: typeof error === "object" ? error : "Could not add hardware",
      error,
    });
  }
}
function* addHardware() {
  yield takeLatest(ADD_HARDWARE_REQUEST, runAddHardware);
}

function* runEditHardware(action) {
  try {
    const { hardware } = yield race({
      hardware: call(api.update, "hardware", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (hardware)
      yield put({
        type: EDIT_HARDWARE_SUCCESS,
        ...hardware.data,
      });
    else
      yield put({
        type: EDIT_HARDWARE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_HARDWARE_ERROR,
      // error: typeof error === "object" ? error : "Could not edit hardware",
      error,
    });
  }
}
function* editHardware() {
  yield takeLatest(EDIT_HARDWARE_REQUEST, runEditHardware);
}

function* runDeleteHardware(action) {
  try {
    const { hardware } = yield race({
      hardware: call(api.delete, "hardware", {
        id: action.id,
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (hardware) yield put({ type: DELETE_HARDWARE_SUCCESS });
    else
      yield put({
        type: DELETE_HARDWARE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_HARDWARE_ERROR,
      error: typeof error === "object" ? error : "Could not delete hardware",
    });
  }
}
function* deleteHardware() {
  yield takeLatest(DELETE_HARDWARE_REQUEST, runDeleteHardware);
}

function* runDeleteBulkHardware(action) {
  try {
    const { hardware } = yield race({
      hardware: call(api.delete, "hardwareBulk", {
        lang: action.lang,
        data: { ...action.fields },
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (hardware) yield put({ type: DELETE_BULK_HARDWARE_SUCCESS });
    else
      yield put({
        type: DELETE_BULK_HARDWARE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BULK_HARDWARE_ERROR,
      error: typeof error === "object" ? error : "Could not delete hardware",
    });
  }
}
function* deleteBulkHardware() {
  yield takeEvery(DELETE_BULK_HARDWARE_REQUEST, runDeleteBulkHardware);
}

export function* saga() {
  while (true) {
    yield all({
      getHardware: call(getHardware),
      addHardware: call(addHardware),
      editHardware: call(editHardware),
      deleteHardware: call(deleteHardware),
      deleteBulkHardware: call(deleteBulkHardware),
    });
  }
}
