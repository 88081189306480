export const endPoints = {
  organization: "organization",
  department: "department",
  costCenter: "costCenter",
  costCenterDepartment: "department/cost-center",
  costCenterContract: "contract/cost-center",
  directorate: "department/directorate-list",
  trip: "trip",
  tripByRoute: "/trip/future/by-route",
  tripByVehicle: "/trip/by-vehicle",
  tripBulk: "trip/bulk",
  tripAvailable: "trip/available-trips",
  possibleRoutes: "stop/spoke-combination",
  futureTripDate: "trip/future-dates",
  // prebookSummary: "trip/prebook-summary",
  checkMoveBookingsWaiting: "booking/check-move-pending",
  checkMoveBookingsUpcoming: "booking/check-move-confirmed",
  movePassengersWaiting: "booking/move-pending",
  movePassengersUpcoming: "booking/move-confirmed",
  checkMoveBookings: "booking/check-move-pending",
  editCheck: "booking/edit-check",
  editBooking: "/booking/edit",
  checkShiftTemplates: "booking/check-shift-template",
  movePassengers: "booking/move-pending",
  boardingProgress: "trip/boarding-progress",
  tripProgress: "trip/progress",
  tripStats: "vehicle/current-onboard",
  booking: "booking",
  bookingByTrip: "booking/by-trips",
  bookingByPassenger: "booking/by-passenger",
  bulkBooking: "booking/bulk",
  shiftBooking: "booking/book-shift-template",
  cancelBooking: "booking/cancel",
  cancelBulkBooking: "booking/bulk-cancel",
  shiftTemplate: "shift-template",
  shiftTemplateBulk: "shift-template/bulk",
  shiftTemplatePassenger: "shift-template/passenger",
  shiftTemplatePassengerAdd: "shift-template/add-passenger",
  shiftTemplatePassengerRemove: "shift-template/remove-passenger",
  shiftTemplatePassengerRemoveBulk: "shift-template/bulk-remove-passenger",
  route: "route",
  routeByStop: "route/by-stops",
  bulkRoute: "route/bulk",
  routeGroup: "route-group",
  routeGroupBulk: "route-group/bulk",
  contract: "contract",
  contractHolder: "contract/contract-holder",
  passenger: "passenger",
  bulkPassenger: "passenger/bulk",
  passengerBulk: "passenger/bulk",
  adminSearch: "user/search",
  passengerSearch: "passenger/search-user",
  passengerFilterBo: "passenger/allowed-users-as-buss-operators",
  pendingRegistration: "passenger/pending-registration",
  approveRegistration: "passenger/approve-registration",
  approveRegistrationAdmin: "passenger/admin-approve-registration",
  relatedPassenger: "passenger/related-entity",
  relatedPassengerBulk: "passenger/bulk-related-entity",
  rejectRegistration: "passenger/update-rejected",
  focalPoint: "focalpoint",
  focalPointSearch: "focalpoint/search-user",
  focalPointBulk: "focalpoint/bulk",
  focalPointStaff: "focalpoint/add-user",
  approver: "approver",
  approverBulk: "approver/bulk",
  approverStaff: "approver/add-user",
  adminUser: "admin",
  adminUserBulk: "admin/bulk",
  adminUserStaff: "admin/add-user",
  driver: "driver",
  driverBulk: "driver/bulk",
  driverSearch: "driver/search",
  busOperator: "bus-operator",
  busOperatorBulk: "bus-operator/bulk",
  busOperatorStaff: "bus-operator/add-user",
  contractFocalPoint: "contract-focalpoint",
  contractFocalPointSearch: "contract-focalpoint/search-user",
  contractFocalPointBulk: "contract-focalpoint/bulk",
  contractFocalPointStaff: "contract-focalpoint/add-user",
  vehicle: "vehicle",
  vehicleSearch: "vehicle/search",
  vehicleByHardware: "vehicle/by-hardware",
  bulkDeleteVehicle: "vehicle/bulk",
  hardware: "hardware",
  hardwareBulk: "hardware/bulk",
  stop: "stop",
  allStop: "stop/allStops",
  bulkStop: "stop/bulk",
  searchPoi: "stop/geocode",
  stopTiming: "route/v1/driving",
  bookingStatistics: "booking/statistics",
  contractorBilling: "booking/contractor-billing",
  departmentBilling: "booking/department-billing",
  updateBulkBookingAcceptence: "booking/update-bulk-booking-acceptence",
  changeBoardingStatus: "booking/boarding-statusV2",
  bookingStatisticsDownload: "booking/send-statistics-csv",
  contractorBillingDownload: "booking/send-contractor-billing-csv",
  departmentBillingDownload: "booking/send-department-billing-csv",
  report1: "booking/report1",
  report2: "booking/report2",
  report3: "booking/report3",
  report4: "booking/report4",
  recommendedStop: "stop/recommended",
  bulkAddContractors: "passenger/contractor/bulk",
  bulkEditContractor: "passenger/bulk",
  subscribeToTopic: "subscribe-to-topic",
  unsubscribeFromTopic: "unsubscribe-to-topic",
  boardingLog: "check-boarding",
  tripManifest: "trip/manifest",
  staffPhoneActivation: "auth/token",
  pendingApproverRegistration: "approver/pending/",
  pendingApproverRegistrationAdmin: "passenger/pending-registration-admin",
  pendingApproverRegistrationCfp: "passenger/pending-registration-cfp",
  notification: "notification",
  notificationBulk: "notification/bulk",
  region: "region",
  costStat: "booking/cost-statistics",
  routeStat: "booking/route-statistics",
  bookingStat: "booking/status-statistics",
  userTypeStat: "booking/user-type-statistics",
  seatUtilStat: "booking/seat-utilization-statistics",
  seatUtilChartStat: "booking/seat-utilization-chart",

  permission: "permission", // accounts micro-service
  resetPassword: "reset-password", // accounts micro-service
  changePassword: "change-password", // accounts micro-service
  checkSession: "auth/check-session",
  appVersion: "app/check-app-version",

  setting: "setting",
  settingDetail: "setting/detail",
  uploadManifest: "trip/upload-manifest",
  getManifest: "trip/download-manifest",
  removeManifest: "trip/remove-manifest",

  getTestToken: "auth/create-test-token", //only for test
  checkTestToken: "auth/check-test-token", // only for test
  refreshToken: "auth/refresh-token", // only for test

  park: "parkandride",
  parkBulk: "parkandride/bulk",

  parkRideBooking: "parkingBooking",
  editParkRideBookingStatus: "parkingBooking/admin/parking-status",
  scheduleMaintenance: "parkingOperations",

  feedback: "feedback",
};
