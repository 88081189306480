import { reducer as organizationReducers } from "./organizationState";
import { reducer as departmentReducers } from "./departmentState";
import { reducer as directorateReducers } from "./directorateState";
// import { reducer as loggingUser } from './login'

const core = [
  organizationReducers,
  departmentReducers,
  directorateReducers,
  // loggingUser
];

export default function organizationReducer(state, action, i) {
  if (!Number.isInteger(i)) i = 0;
  if (i < core.length)
    return organizationReducer(core[i](state, action), action, i + 1);
  else return state;
}
