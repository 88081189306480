// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";

// internal imports
import api from "../../../utils/api/general";
import { replaceErrors } from "../../../utils/handleError";
// get action names
import {
  ADD_FOCAL_POINT_REQUEST,
  ADD_FOCAL_POINT_SUCCESS,
  ADD_FOCAL_POINT_ERROR,
  EDIT_FOCAL_POINT_REQUEST,
  EDIT_FOCAL_POINT_SUCCESS,
  EDIT_FOCAL_POINT_ERROR,
  GET_FOCAL_POINT_REQUEST,
  GET_FOCAL_POINT_SUCCESS,
  GET_FOCAL_POINT_ERROR,
  GET_SINGLE_FOCAL_POINT_REQUEST,
  GET_SINGLE_FOCAL_POINT_SUCCESS,
  GET_SINGLE_FOCAL_POINT_ERROR,
  DELETE_FOCAL_POINT_REQUEST,
  DELETE_FOCAL_POINT_SUCCESS,
  DELETE_FOCAL_POINT_ERROR,
  DELETE_BULK_FOCAL_POINT_REQUEST,
  DELETE_BULK_FOCAL_POINT_SUCCESS,
  DELETE_BULK_FOCAL_POINT_ERROR,
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 30 * 1000; // 5 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_FOCAL_POINT_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "focalPoint",
          success: null,
          error: "",
        },
      };
    case ADD_FOCAL_POINT_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "focalPoint",
          success: true,
          error: "",
        },
      };
    case ADD_FOCAL_POINT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "focalPoint",
          success: false,
          error: action.error,
        },
      };

    case EDIT_FOCAL_POINT_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "focalPoint",
          success: null,
          error: "",
        },
      };
    case EDIT_FOCAL_POINT_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "focalPoint",
          success: true,
          error: "",
        },
      };
    case EDIT_FOCAL_POINT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "focalPoint",
          success: false,
          error: action.error,
        },
      };
    case DELETE_FOCAL_POINT_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "focalPoint",
          success: null,
          error: "",
        },
        userLinked: null,
      };
    case DELETE_FOCAL_POINT_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "focalPoint",
          success: true,
          error: "",
        },
        userLinked: action.userLinked,
      };
    case DELETE_FOCAL_POINT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "focalPoint",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BULK_FOCAL_POINT_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "focalPoint",
          success: null,
          error: "",
        },
      };
    case DELETE_BULK_FOCAL_POINT_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "focalPoint",
          success: true,
          error: "",
        },
      };
    case DELETE_BULK_FOCAL_POINT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "focalPoint",
          success: false,
          error: action.error,
        },
      };
    case GET_FOCAL_POINT_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "focalPoint",
                success: null,
                error: "",
              },
      };
    case GET_FOCAL_POINT_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        focalPoints: action.focalPoints,
        focalPointsCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "focalPoint",
                success: true,
                error: "",
              },
      };
    case GET_FOCAL_POINT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "focalPoint",
          success: false,
          error: action.error,
        },
      };

    case GET_SINGLE_FOCAL_POINT_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "focalPoint",
          success: null,
          error: "",
        },
      };
    case GET_SINGLE_FOCAL_POINT_SUCCESS:
      return {
        ...newState,
        focalPoints: {
          ...state.focalPoints,
          [action.id]: action.focalPoint,
        },
        actionStatus: {
          type: "fetch",
          entity: "focalPoint",
          success: true,
          error: "",
        },
      };
    case GET_SINGLE_FOCAL_POINT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "focalPoint",
          success: false,
          error: "",
        },
      };
    case "LOGOUT_SUCCESS":
      return {};

    default:
      return state;
  }
}

function* runGetSingleFocalPoint(action) {
  try {
    const { focalPoint } = yield race({
      focalPoint: call(api.get, "focalPoint", {
        lang: action.lang,
        id: action.id,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (focalPoint)
      yield put({
        type: GET_SINGLE_FOCAL_POINT_SUCCESS,
        focalPoint: focalPoint.data,
        id: action.id,
      });
    else
      yield put({
        type: GET_SINGLE_FOCAL_POINT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_SINGLE_FOCAL_POINT_ERROR,
      error:
        typeof error === "object" ? error : "Focal point could not be updated.",
    });
  }
}
function* getSingleFocalPoint() {
  yield takeLatest(GET_SINGLE_FOCAL_POINT_REQUEST, runGetSingleFocalPoint);
}

function* runGetFocalPoints(action) {
  try {
    const { focalPoint } = yield race({
      focalPoint: call(
        api.get,
        `focalPoint${action.params?.search ? "Search" : ""}`,
        {
          lang: action.lang,
          ...action.params,
          ...action.filters,
        }
      ),
      timeout: delay(parseResponseTimeout),
    });

    if (focalPoint) {
      const focalPoints = focalPoint.data ? focalPoint.data.data : [];
      yield put({
        type: GET_FOCAL_POINT_SUCCESS,
        source: action.source,
        focalPoints: focalPoints.rows,
        count: focalPoints.count,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_FOCAL_POINT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_FOCAL_POINT_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Focal points could not be updated.",
    });
  }
}
function* getFocalPoints() {
  yield takeLatest(GET_FOCAL_POINT_REQUEST, runGetFocalPoints);
}

function* runAddFocalPoint(action) {
  try {
    let extraUrl = action.userType === "staff" ? "Staff" : "";
    const { focalPoint } = yield race({
      focalPoint: call(api.add, `focalPoint${extraUrl}`, {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (focalPoint)
      yield put({
        type: ADD_FOCAL_POINT_SUCCESS,
        ...focalPoint.data,
      });
    else
      yield put({
        type: ADD_FOCAL_POINT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_FOCAL_POINT_ERROR,
      error,
      //error: typeof error === "object" ? error : "Could not add focalPoint",
    });
  }
}
function* addFocalPoint() {
  yield takeLatest(ADD_FOCAL_POINT_REQUEST, runAddFocalPoint);
}

function* runEditFocalPoint(action) {
  try {
    // Separate focalPoint fields from user fields

    // Update user details
    const { users } = yield race({
      users: call(api.update, "focalPoint", {
        lang: action.lang,
        id: action.id,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (users)
      yield put({
        type: EDIT_FOCAL_POINT_SUCCESS,
        ...users.data,
      });
    else
      yield put({
        type: EDIT_FOCAL_POINT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_FOCAL_POINT_ERROR,
      error,
      //error: typeof error === "object" ? error : "Could not edit focalPoint",
    });
  }
}
function* editFocalPoint() {
  yield takeLatest(EDIT_FOCAL_POINT_REQUEST, runEditFocalPoint);
}

function* runDeleteFocalPoint(action) {
  try {
    const { focalPoint } = yield race({
      focalPoint: call(
        api.delete,
        `focalPoint${action.params?.ids ? "Bulk" : ""}`,
        {
          id: action.params?.id || undefined,
          data: action.params?.ids || undefined,
          lang: action.lang,
        }
      ),
      timeout: delay(parseResponseTimeout),
    });

    if (focalPoint) {
      let obj = { type: DELETE_FOCAL_POINT_SUCCESS };
      if (
        focalPoint?.data?.data &&
        Object.values(focalPoint?.data?.data).length > 0
      )
        obj.userLinked = {
          message: focalPoint.data.message,
          data: focalPoint.data.data,
        };
      yield put(obj);
    } else
      yield put({
        type: DELETE_FOCAL_POINT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_FOCAL_POINT_ERROR,
      error: typeof error === "object" ? error : "Could not delete focalPoints",
    });
  }
}
function* deleteFocalPoint() {
  yield takeLatest(DELETE_FOCAL_POINT_REQUEST, runDeleteFocalPoint);
}

function* runDeleteBulkFocalPoint(action) {
  try {
    const { focalPoint } = yield race({
      focalPoint: call(api.delete, "focalPoint", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (focalPoint) yield put({ type: DELETE_BULK_FOCAL_POINT_SUCCESS });
    else
      yield put({
        type: DELETE_BULK_FOCAL_POINT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BULK_FOCAL_POINT_ERROR,
      error: typeof error === "object" ? error : "Could not delete focalPoints",
    });
  }
}
function* deleteBulkFocalPoint() {
  yield takeEvery(DELETE_BULK_FOCAL_POINT_REQUEST, runDeleteBulkFocalPoint);
}

export function* saga() {
  while (true) {
    yield all({
      getFocalPoints: call(getFocalPoints),
      getSingleFocalPoint: call(getSingleFocalPoint),
      addFocalPoint: call(addFocalPoint),
      editFocalPoint: call(editFocalPoint),
      deleteFocalPoint: call(deleteFocalPoint),
      deleteBulkFocalPoint: call(deleteBulkFocalPoint),
    });
  }
}
