// external import
import { all, put, call, takeLatest, delay, race } from "redux-saga/effects";
import moment from "moment";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  GET_CONTRACTOR_BILLING_REQUEST,
  GET_CONTRACTOR_BILLING_SUCCESS,
  GET_CONTRACTOR_BILLING_ERROR,
  GET_DEPARTMENT_BILLING_REQUEST,
  GET_DEPARTMENT_BILLING_SUCCESS,
  GET_DEPARTMENT_BILLING_ERROR,
  GET_BOOKING_STATISTICS_REQUEST,
  GET_BOOKING_STATISTICS_SUCCESS,
  GET_BOOKING_STATISTICS_ERROR,
  CLEAR_REPORTS_ACTION_STATUS,
  DOWNLOAD_ALL_REPORTS_REQUEST,
  DOWNLOAD_ALL_REPORTS_ERROR,
  DOWNLOAD_ALL_REPORTS_SUCCESS,
  CHANGE_BOARDING_STATUS_REQUEST,
  CHANGE_BOARDING_STATUS_SUCCESS,
  CHANGE_BOARDING_STATUS_ERROR,
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 50 * 1000; // 50 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case GET_CONTRACTOR_BILLING_REQUEST:
      return {
        ...newState,
        loading: true,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "contractorBilling",
                success: null,
                error: "",
              },
      };
    case GET_CONTRACTOR_BILLING_SUCCESS:
      return {
        ...newState,
        loading: false,
        filters: {
          ...action.filters,
          extraData: action.extraData,
        },
        contractorBilling: action.reports,
        contractorBillingCount: action.count,
        lastFetched: moment().format("YYYY-MM-DD"),
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "contractorBilling",
                success: true,
                error: "",
              },
      };
    case GET_CONTRACTOR_BILLING_ERROR:
      return {
        ...newState,
        loading: false,
        actionStatus: {
          type: "fetch",
          entity: "contractorBilling",
          success: false,
          error: action.error,
        },
      };

    case GET_DEPARTMENT_BILLING_REQUEST:
      return {
        ...newState,
        loading: true,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "departmentBilling",
                success: null,
                error: "",
              },
      };
    case GET_DEPARTMENT_BILLING_SUCCESS:
      return {
        ...newState,
        loading: false,
        filters: {
          ...action.filters,
          extraData: action.extraData,
        },
        departmentBilling: action.reports,
        departmentBillingCount: action.count,
        lastFetched: moment().format("YYYY-MM-DD"),
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "departmentBilling",
                success: true,
                error: "",
              },
      };
    case GET_DEPARTMENT_BILLING_ERROR:
      return {
        ...newState,
        loading: false,
        actionStatus: {
          type: "fetch",
          entity: "departmentBilling",
          success: false,
          error: action.error,
        },
      };

    case GET_BOOKING_STATISTICS_REQUEST:
      return {
        ...newState,
        loading: true,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "bookingStatistics",
                success: null,
                error: "",
              },
      };
    case GET_BOOKING_STATISTICS_SUCCESS:
      return {
        ...newState,
        loading: false,
        filters: {
          ...action.filters,
          extraData: action.extraData,
        },
        bookingStatistics: action.reports,
        bookingStatisticsCount: action.count,
        lastFetched: moment().format("YYYY-MM-DD"),
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "bookingStatistics",
                success: true,
                error: "",
              },
      };
    case GET_BOOKING_STATISTICS_ERROR:
      return {
        ...newState,
        loading: false,
        actionStatus: {
          type: "fetch",
          entity: "bookingStatistics",
          success: false,
          error: action.error,
        },
      };
    case DOWNLOAD_ALL_REPORTS_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "download",
          entity: action.entity,
          success: null,
          error: "",
        },
      };
    case DOWNLOAD_ALL_REPORTS_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "download",
          entity: action.entity,
          success: true,
          error: "",
        },
      };
    case DOWNLOAD_ALL_REPORTS_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "download",
          entity: action.entity,
          success: false,
          error: action.error,
        },
      };
    case CLEAR_REPORTS_ACTION_STATUS:
      return {
        ...newState,
        actionStatus: { type: "", entity: "", success: null, error: "" },
      };
    case CHANGE_BOARDING_STATUS_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "change",
          entity: "boardingStatus",
          success: null,
          error: "",
        },
      };
    case CHANGE_BOARDING_STATUS_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "change",
          entity: "boardingStatus",
          success: true,
          error: "",
        },
      };
    case CHANGE_BOARDING_STATUS_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "change",
          entity: "boardingStatus",
          success: false,
          error: action.error,
        },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetContractorBilling(action) {
  try {
    const { extraData, ...filters } = action.filters;

    const { reports } = yield race({
      reports: call(api.get, "contractorBilling", {
        lang: action.lang,
        // admin: false,
        ...action.params,
        ...filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (reports) {
      const report = reports.data ? reports.data.data : [];
      yield put({
        type: GET_CONTRACTOR_BILLING_SUCCESS,
        source: action.source,
        reports: report.rows,
        count: report.count,
        extraData,
        filters,
      });
    } else
      yield put({
        type: GET_CONTRACTOR_BILLING_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_CONTRACTOR_BILLING_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Contractor billing could not be updated.",
    });
  }
}
function* getContractorBilling() {
  yield takeLatest(GET_CONTRACTOR_BILLING_REQUEST, runGetContractorBilling);
}

function* runGetDepartmentBilling(action) {
  try {
    const { extraData, ...filters } = action.filters;

    const { reports } = yield race({
      reports: call(api.get, "departmentBilling", {
        lang: action.lang,
        // admin: false,
        ...action.params,
        ...filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (reports) {
      const report = reports.data ? reports.data.data : [];
      yield put({
        type: GET_DEPARTMENT_BILLING_SUCCESS,
        source: action.source,
        reports: report.rows,
        count: report.count,
        extraData,
        filters,
      });
    } else
      yield put({
        type: GET_DEPARTMENT_BILLING_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_DEPARTMENT_BILLING_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Department billing could not be updated.",
    });
  }
}
function* getDepartmentBilling() {
  yield takeLatest(GET_DEPARTMENT_BILLING_REQUEST, runGetDepartmentBilling);
}

function* runGetBookingStatistics(action) {
  try {
    const { extraData, ...filters } = action.filters;

    const { reports } = yield race({
      reports: call(api.get, "bookingStatistics", {
        lang: action.lang,
        // admin: false,
        ...action.params,
        ...filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (reports) {
      const report = reports.data ? reports.data.data : [];
      yield put({
        type: GET_BOOKING_STATISTICS_SUCCESS,
        source: action.source,
        reports: report.rows || report,
        count: report.count,
        extraData,
        filters,
      });
    } else
      yield put({
        type: GET_BOOKING_STATISTICS_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    // .log(error);
    yield put({
      type: GET_BOOKING_STATISTICS_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Booking statistics could not be updated.",
    });
  }
}
function* getBookingStatistics() {
  yield takeLatest(GET_BOOKING_STATISTICS_REQUEST, runGetBookingStatistics);
}

function* runDownLoadAllReports(action) {
  try {
    const { response } = yield race({
      response: call(api.get, `${action.entity}Download`, {
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response) {
      const res = response.data ? response.data.data : [];
      yield put({
        type: DOWNLOAD_ALL_REPORTS_SUCCESS,
        source: action.source,
        response: res.rows,
        entity: action.entity,
      });
    } else
      yield put({
        type: DOWNLOAD_ALL_REPORTS_ERROR,
        error: errors.requestTimeout.message,
        entity: action.entity,
      });
  } catch (error) {
    // .log(error);
    yield put({
      type: DOWNLOAD_ALL_REPORTS_ERROR,
      entity: action.entity,
      error:
        typeof error === "object"
          ? error
          : "Error sending mail to your email address",
    });
  }
}
function* downLoadAllReports() {
  yield takeLatest(DOWNLOAD_ALL_REPORTS_REQUEST, runDownLoadAllReports);
}

function* runChangeBoardingStatus(action) {
  try {
    const { response } = yield race({
      response: call(api.update, `changeBoardingStatus`, {
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response) {
      const res = response.data ? response.data.data : [];
      yield put({
        type: CHANGE_BOARDING_STATUS_SUCCESS,
        response: res.rows,
      });
    } else
      yield put({
        type: CHANGE_BOARDING_STATUS_ERROR,
        error: errors.requestTimeout.message,
        entity: action.entity,
      });
  } catch (error) {
    // .log(error);
    yield put({
      type: CHANGE_BOARDING_STATUS_ERROR,
      entity: action.entity,
      error: typeof error === "object" ? error : error,
    });
  }
}
function* changeBoardingStatus() {
  yield takeLatest(CHANGE_BOARDING_STATUS_REQUEST, runChangeBoardingStatus);
}

export function* saga() {
  while (true) {
    yield all({
      getContractorBilling: call(getContractorBilling),
      getDepartmentBilling: call(getDepartmentBilling),
      getBookingStatistics: call(getBookingStatistics),
      downLoadAllReports: call(downLoadAllReports),
      changeBoardingStatus: call(changeBoardingStatus),
    });
  }
}
