const initialState = {
  hardware: [],
  hardwareCount: 0,
  batches: [],
  batchesCount: 0,
  actionStatus: {
    action: "",
    entity: "",
    success: null,
    error: "",
  },
  filters: {},
};

export default initialState;
