// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";
import moment from "moment";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  GET_TRIP_PROGRESS_REQUEST,
  GET_TRIP_PROGRESS_SUCCESS,
  GET_TRIP_PROGRESS_ERROR,
  GET_BOARDING_PROGRESS_REQUEST,
  GET_BOARDING_PROGRESS_SUCCESS,
  GET_BOARDING_PROGRESS_ERROR,
  GET_TRIP_STATS_REQUEST,
  GET_TRIP_STATS_SUCCESS,
  GET_TRIP_STATS_ERROR,
  CLEAR_TRACKING_ACTION_STATUS,
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 30 * 1000; // 5 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case GET_TRIP_PROGRESS_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "tripProgress",
          success: null,
          error: "",
        },
      };
    case GET_TRIP_PROGRESS_SUCCESS:
      return {
        ...newState,
        tripProgress: {
          // ...state.tripProgress,
          [action.id]: action.tripProgress,
        },
        actionStatus: {
          type: "fetch",
          entity: "tripProgress",
          success: true,
          error: "",
        },
      };
    case GET_TRIP_PROGRESS_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "tripProgress",
          success: false,
          error: action.error,
        },
      };

    case GET_BOARDING_PROGRESS_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "boardingProgress",
          success: null,
          error: "",
        },
      };
    case GET_BOARDING_PROGRESS_SUCCESS:
      return {
        ...newState,
        boardingProgress: {
          // ...state.boardingProgress,
          [action.id]: action.boardingProgress,
        },
        actionStatus: {
          type: "fetch",
          entity: "boardingProgress",
          success: true,
          error: "",
        },
      };
    case GET_BOARDING_PROGRESS_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "boardingProgress",
          success: false,
          error: action.error,
        },
      };

    case GET_TRIP_STATS_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "tripStats",
          success: null,
          error: "",
        },
      };
    case GET_TRIP_STATS_SUCCESS:
      return {
        ...newState,
        tripStats: action.tripStats,
        actionStatus: {
          type: "fetch",
          entity: "tripStats",
          success: true,
          error: "",
        },
      };
    case GET_TRIP_STATS_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "tripStats",
          success: false,
          error: action.error,
        },
      };
    case CLEAR_TRACKING_ACTION_STATUS:
      return {
        ...newState,
        actionStatus: { type: "", entity: "", success: null, error: "" },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetTripProgress(action) {
  try {
    const { response } = yield race({
      response: call(api.get, "tripProgress", {
        lang: action.lang,
        admin: false,
        id: action.id,
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response)
      yield put({
        type: GET_TRIP_PROGRESS_SUCCESS,
        id: action.id,
        tripProgress: response.data && response.data.data,
      });
    else
      yield put({
        type: GET_TRIP_PROGRESS_ERROR,
        user: action.user,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_TRIP_PROGRESS_ERROR,
      user: action.user,
      error:
        typeof error === "object"
          ? error
          : "Trip progress could not be updated.",
    });
  }
}
function* getTripProgress() {
  yield takeLatest(GET_TRIP_PROGRESS_REQUEST, runGetTripProgress);
}

function* runGetBoardingProgress(action) {
  try {
    const { response } = yield race({
      response: call(api.get, "boardingProgress", {
        lang: action.lang,
        admin: false,
        id: action.id,
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response)
      yield put({
        type: GET_BOARDING_PROGRESS_SUCCESS,
        id: action.id,
        boardingProgress: response.data && response.data.data,
      });
    else
      yield put({
        type: GET_BOARDING_PROGRESS_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_BOARDING_PROGRESS_ERROR,
      user: action.user,
      error:
        typeof error === "object"
          ? error
          : "Boarding progress could not be updated.",
    });
  }
}
function* getBoardingProgress() {
  yield takeLatest(GET_BOARDING_PROGRESS_REQUEST, runGetBoardingProgress);
}

function* runGetTripStats(action) {
  try {
    const { response } = yield race({
      response: call(api.get, "tripStats", {
        lang: action.lang,
        admin: false,
        id: action.id,
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response)
      yield put({
        type: GET_TRIP_STATS_SUCCESS,
        id: action.id,
        tripStats: response.data && response.data.data,
      });
    else
      yield put({
        type: GET_TRIP_STATS_ERROR,
        user: action.user,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_TRIP_STATS_ERROR,
      user: action.user,
      error:
        typeof error === "object"
          ? error
          : "Trip progress could not be updated.",
    });
  }
}
function* getTripStats() {
  yield takeLatest(GET_TRIP_STATS_REQUEST, runGetTripStats);
}

export function* saga() {
  while (true) {
    yield all({
      getTripProgress: call(getTripProgress),
      getBoardingProgress: call(getBoardingProgress),
      getTripStats: call(getTripStats),
    });
  }
}
