// external import
import Router from "next/router";
import { useSelector } from "react-redux";
import { removeCookie, setCookie } from "../../../utils/cookie/cookies";
import {
  all,
  delay,
  put,
  putResolve,
  fork,
  select,
  call,
  take,
  takeLatest,
  race,
  cancel,
} from "redux-saga/effects";
import moment from "moment";

// internal imports
import {
  getOtp,
  getOtpAzure,
  loginWithOtp,
  verifyWithOtp,
  loginWithPassword,
  callForgetPasswordApi,
  callChangePasswordApi,
  logout as logoutUser,
  register,
  checkIsSessionValid,
  checkIsUserValid,
  loginWithAzure,
  registerWithAzure,
  // addStaff,
} from "../../../utils/api/login";

// get action names
import {
  LOGIN_PASS_REQUEST,
  LOGIN_PASS_AZURE_REQUEST,
  LOGIN_PASS_SUCCESS,
  LOGIN_PASS_ERROR,
  LOGIN_SMS_CODE_REQUEST_AZURE,
  LOGIN_SMS_CODE_REQUEST,
  LOGIN_SMS_CODE_SUCCESS,
  LOGIN_SMS_CODE_ERROR,
  LOGIN_SMS_REQUEST,
  LOGIN_SMS_SUCCESS,
  LOGIN_SMS_ERROR,
  LOGIN_SMS_CANCEL,
  LOGIN_SMS_TIMEOUT,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  IS_SESSION_VALID_REQUEST,
  IS_SESSION_VALID_SUCCESS,
  IS_SESSION_VALID_ERROR,
  ASK_FOR_OTP,
  ASK_FOR_OTP_FALSE,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  FORGET_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_ERROR,
  IS_USER_STATUS_SUCCESS,
  IS_USER_STATUS_ERROR,
  IS_USER_STATUS_REQUEST,
  SERVER_DOWN,
  INCREASE_WATCH_TUTORIAL_COUNT,
  CONTACT_INFO_REQUEST,
  CONTACT_INFO_ERROR,
  CONTACT_INFO_SUCCESS,
  CHECK_APP_VERSION_SUCCESS,
  CHECK_APP_VERSION_ERROR,
  CHECK_APP_VERSION_REQUEST,
  RESET_LOGGED_IN_STAT,
  ADD_STAFF_REQUEST_AZURE,
  ADD_STAFF_ERROR_AZURE,
  ADD_STAFF_SUCCESS_AZURE,
  // smsCodeTimeout
} from "../constants";

// get initial state
import initialState from "./initialState";
import api from "../../../utils/api/general";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 50 * 1000; // 50 seconds
const smsCodeTimeout = 100; // 100 seconds
const allowedVerificationAttempts = 3;
const isDev = process.env.NODE_ENV === "development";

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {
    smsLoginTimeout: false,
    smsLoginError: null,
    passLoginError: null,
    invalidOtpError: null,
  });

  switch (action.type) {
    case LOGIN_SMS_CODE_REQUEST:
      return {
        ...newState,
        numLoginAttempts: newState.numLoginAttempts + 1,
        isLoggedIn: false,
        isLoggingOut: false,
        loginStatus: "verifyPhone",
      };
    case LOGIN_SMS_CODE_REQUEST_AZURE:
      return {
        ...newState,
        numLoginAttempts: newState.numLoginAttempts + 1,
        isLoggedIn: false,
        isLoggingOut: false,
        loginStatus: "verifyPhone",
      };
    case LOGIN_SMS_CODE_SUCCESS:
      return {
        ...newState,
        loginStatus: "otpSent",
        loginError: null,
      };
    case LOGIN_SMS_CODE_ERROR:
      return {
        ...newState,
        loginStatus: "verifyPhoneFailed",
        loginError: action.error,
      };
    case LOGIN_SMS_REQUEST:
      return {
        ...newState,
        loginStatus: "verifyOtp",
      };
    case LOGIN_SMS_SUCCESS:
      return {
        ...newState,
        currentUser: action.user,
        accessToken: action.access_token,
        refreshToken: action.refresh_token,
        role: action.role,
        numLoginAttempts: 0,
        smsVerificationAttempts: 0,
        smsCodeSentAt: null,
        isLoggedIn: true,
        isSuperuser: false,
        loginError: null,
        loginStatus: "loginSuccess",
      };
    case LOGIN_SMS_ERROR:
      return {
        ...newState,
        loginError: action.error,
        loginStatus: "loginFailed",
      };
    case LOGIN_SMS_TIMEOUT:
      return {
        ...newState,
        smsLoginTimeout: true,
        smsVerificationAttempts: 0,
        loginStatus: "verifyOtpTimeout",
      };

    case LOGIN_PASS_REQUEST:
      if (action.fields.otp)
        return {
          ...newState,
          isLoggingIn: true,
          isLoggingOut: false,
          numLoginAttempts: newState.numLoginAttempts + 1,
          fields: action.fields,
          // isLoggedIn: false
        };
      else
        return {
          ...newState,
          isLoggingIn: true,
          isLoggingOut: false,
          numLoginAttempts: newState.numLoginAttempts + 1,
          fields: action.fields,
          expiresIn: null,
          resendAvailableIn: null,
          forgetPwSuc: null,
          forgetPwErr: null,
        };
    case LOGIN_PASS_SUCCESS:
      let role;
      switch (action.user_type) {
        case "superuser":
          role = "superuser";
          break;
        case "focalpoint":
          role = "pdoFocalPoint";
          break;
        case "contractfocalpoint":
          role = "contractFocalPoint";
          break;
        case "busoperator":
          role =
            action?.user?.bus_operators?.[0]?.role === "bus_operator"
              ? "busOperators"
              : "commutingMafOperator";
          break;
        default:
          role = "unspecifiedUser";
          break;
      }
      setCookie("role", role);
      return {
        ...newState,
        isLoggingIn: false,
        currentUser: action.user,
        accessToken: action.access_token,
        refreshToken: action.refresh_token,
        // accessToken: "action.access_token",
        // refreshToken: "action.refresh_token",
        deviceId: action.deviceId,
        numLoginAttempts: 0,
        role,
        // role: "superuser",
        // role: "contractFocalPoint",
        // role: "pdoFocalPoint",
        // role: "busOperators",
        // role: "superContractFocalPoint",
        roleType: action.roleType,
        locale: action.locale,
        isLoggedIn: true,
        isSuperuser: false,
        askForOtp: false,
        fields: null,
        sessionError: "",
        userSessionError: "",
      };
    case LOGIN_PASS_ERROR:
      return {
        ...newState,
        isLoggingIn: false,
        passLoginError: action.error,
        invalidOtpError: action.otpError,
        fields: null,
        // isLoggedIn: false
      };
    case ADD_STAFF_REQUEST_AZURE:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "staff",
          success: null,
          error: "",
        },
      };
    case ADD_STAFF_SUCCESS_AZURE:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "staff",
          success: true,
          error: "",
        },
      };
    case ADD_STAFF_ERROR_AZURE:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "staff",
          success: false,
          error: action.error,
        },
      };
    case RESET_LOGGED_IN_STAT:
      return { ...newState, isLoggingIn: false };
    case LOGOUT_REQUEST:
      return { ...newState, isLoggingOut: true };
    case LOGOUT_SUCCESS:
      return {
        watchTutorialCount: { ...newState?.watchTutorialCount },
        appVersion: { ...newState?.appVersion },
        sessionError: "",
        userSessionError: "",
      };
    case INCREASE_WATCH_TUTORIAL_COUNT:
      return {
        ...newState,
        watchTutorialCount: action.counts,
      };
    case LOGOUT_ERROR:
      return { ...newState, logoutError: action.error };

    case REGISTER_USER_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "register",
          entity: "user",
          success: null,
          error: "",
        },
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "register",
          entity: "user",
          success: true,
          error: "",
        },
      };
    case REGISTER_USER_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "register",
          entity: "user",
          success: false,
          error: action.error,
        },
      };
    case IS_SESSION_VALID_REQUEST:
      return {
        ...newState,
      };
    case IS_SESSION_VALID_SUCCESS:
      return {
        ...newState,
        accessToken: action.access_token,
        refreshToken: action.refresh_token,
        isSessionValid: true,
        sessionError: "",
        userSessionError: "",
      };
    case IS_SESSION_VALID_ERROR:
      return {
        ...newState,
        currentUser: action.error === "invalid" ? null : state.currentUser,
        isSessionValid: false,
        sessionError: action.sessionError,
      };
    case IS_USER_STATUS_SUCCESS:
      return {
        ...newState,
        isUserValid: true,
        userSessionError: "",
        accessToken: action.access_token,
        refreshToken: action.refresh_token,
      };
    case IS_USER_STATUS_ERROR:
      return {
        ...newState,
        currentUser: action.error === "invalid" ? null : state.currentUser,
        isUserValid: false,
        userSessionError: action.sessionError,
        // accessToken: "userNotValid",
        // refreshToken: "userNotValid",
      };
    case ASK_FOR_OTP: {
      return {
        ...newState,
        askForOtp: true,
        isLoggingIn: false,
        phone: action.phone,
        expiresIn: moment().add(10, "m"),
        resendAvailableIn: moment().add(100, "s"),
      };
    }
    case ASK_FOR_OTP_FALSE: {
      return {
        ...newState,
        askForOtp: false,
        isLoggingIn: false,
        expiresIn: null,
        resendAvailableIn: null,
        forgetPwSuc: null,
        forgetPwErr: null,
      };
    }
    case FORGET_PASSWORD_REQUEST: {
      return {
        ...newState,
        isLoggingIn: true,
        fields: action.fields,
        forgetPwSuc: null,
        forgetPwErr: null,
      };
    }
    case FORGET_PASSWORD_SUCCESS: {
      return {
        ...newState,
        isLoggingIn: false,
        forgetPwSuc: action.success,
      };
    }
    case FORGET_PASSWORD_ERROR: {
      return {
        ...newState,
        isLoggingIn: false,
        forgetPwErr: action.error,
      };
    }

    case CHANGE_PASSWORD_REQUEST: {
      return {
        ...newState,
        isLoggingIn: true,
      };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...newState,
        isLoggingIn: false,
        forgetPwSuc: action.success,
      };
    }
    case CHANGE_PASSWORD_ERROR: {
      return {
        ...newState,
        isLoggingIn: false,
        forgetPwErr: action.error,
      };
    }
    case SERVER_DOWN: {
      return {
        ...newState,
        serverDownErr: action.err,
      };
    }
    case CONTACT_INFO_SUCCESS:
      return {
        ...newState,
        contactInfo: action.contactInfo,
        actionStatus: {
          type: "fetch",
          entity: "contactInfo",
          success: true,
          error: " ",
        },
      };
    case CONTACT_INFO_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "contactInfo",
          success: false,
          error: action.error,
        },
      };
    case CHECK_APP_VERSION_SUCCESS:
      let oldVersion = process.env.version,
        newVersion =
          (action.version.major_version || 0) +
          "." +
          (action.version?.minor_version || 0) +
          "." +
          (action.version?.patch_version || 0),
        sortingVersion = [oldVersion, newVersion].sort(),
        newVerAvailable = oldVersion !== sortingVersion[1],
        hasEmailUpdate =
          newState?.appVersion?.settings?.support_email !==
          action?.version?.settings?.support_email,
        hasPhoneUpdate =
          newState?.appVersion?.settings?.support_phone !==
          action?.version?.settings?.support_phone;

      if (
        newVerAvailable ||
        !newState?.appVersion ||
        hasEmailUpdate ||
        hasPhoneUpdate
      ) {
        return {
          ...newState,
          appVersion: {
            newVerAvailable,
            oldVersion,
            ...action.version,
          },
        };
      }
    default:
      return state;
  }
}

// define saga functions

// request for a Verification code via SMS
function* requestSMSCode(action) {
  try {
    const { countryCode: country_code, phone, language } = action;

    const { response } = yield race({
      response: call(getOtp, {
        country_code: country_code,
        phone: phone,
        lang: language,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response) {
      yield put({
        type: LOGIN_SMS_CODE_SUCCESS,
      });
    } else {
      yield put({
        type: LOGIN_SMS_CODE_ERROR,
        error: "timeout",
      });
    }
  } catch (error) {
    yield put({
      type: LOGIN_SMS_CODE_ERROR,
      error:
        error.message || (typeof error === "string" && error) || "invalidPhone",
    });
  }
}

function* requestCode() {
  yield takeLatest(LOGIN_SMS_CODE_REQUEST, requestSMSCode);
}

function* requestSMSCodeAzure(action) {
  try {
    const { countryCode: country_code, phone, language } = action;
    console.log("kkkkkpppp", country_code, phone, language);
    const { response } = yield race({
      response: call(getOtpAzure, {
        // country_code: country_code,
        phone: phone,
        lang: language,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (response) {
      // yield put({
      //   type: LOGIN_SMS_CODE_SUCCESS,
      // });
      yield put({
        type: ASK_FOR_OTP,
        // user: userDate.user,
        // access_token: userDate.access_token,
        // refresh_token: userDate.refresh_token,
        // deviceId: action.fields && action.fields.device_udid,
        // role: userDate.role,
        phone: phone,
      });
    } else {
      yield put({
        type: LOGIN_SMS_CODE_ERROR,
        error: "timeout",
      });
    }
  } catch (error) {
    yield put({
      type: LOGIN_SMS_CODE_ERROR,
      error:
        error.message || (typeof error === "string" && error) || "invalidPhone",
    });
  }
}

function* requestCodeAzure() {
  yield takeLatest(LOGIN_SMS_CODE_REQUEST_AZURE, requestSMSCodeAzure);
}

// verify sms Verification code
function* verifySMSCode(action) {
  try {
    const { user } = yield race({
      user: call(loginWithOtp, { ...action.fields }),
      timeout: delay(parseResponseTimeout),
    });

    if (user) {
      const userDate = user.data ? user.data.data : {};

      // Store access token in storage
      // yield call(AsyncStorage.setItem, "token", userDate.access_token);

      yield put({
        type: LOGIN_SMS_SUCCESS,
        user: userDate.user,
        access_token: userDate.access_token,
        refresh_token: userDate.refresh_token,
        role: userDate.role,
      });
    } else
      yield put({
        type: LOGIN_SMS_ERROR,
        error: "timeout",
      });
  } catch (error) {
    yield put({
      type: LOGIN_SMS_ERROR,
      error:
        error.message || (typeof error === "string" && error) || "invalidOtp",
    });
  }
}

function* smsLogin() {
  yield takeLatest(LOGIN_SMS_REQUEST, verifySMSCode);
}

function* runPassLogin(action) {
  try {
    const { user } = yield race({
      user: action.fields.otp
        ? call(verifyWithOtp, {
            ...action.fields,
          })
        : call(loginWithPassword, {
            ...action.fields,
          }),
      timeout: delay(parseResponseTimeout),
    });

    if (user) {
      const userDate = user.data ? user.data.data : {};

      let is_twofactor_enabled = userDate.is_otp_required;
      // let is_twofactor_enabled = false;

      if (is_twofactor_enabled) {
        yield put({
          type: ASK_FOR_OTP,
          user: userDate.user,
          access_token: userDate.access_token,
          refresh_token: userDate.refresh_token,
          deviceId: action.fields && action.fields.device_udid,
          role: userDate.role,
          phone: userDate.phone,
        });
      } else {
        // Store access token in storage
        // yield call(AsyncStorage.setItem, "token", userDate.access_token);

        yield putResolve({
          type: LOGIN_PASS_SUCCESS,
          user: userDate.user,
          access_token: userDate.access_token,
          refresh_token: userDate.refresh_token,
          deviceId: action.fields && action.fields.device_udid,
          role: userDate.role,
          user_type: userDate.user_type,
        });
        setCookie("loggedIn", true);
        Router.push("/admin");
      }
    } else
      yield put({
        type: LOGIN_PASS_ERROR,
        error: "timeout",
      });
  } catch (error) {
    if (action.fields.otp)
      yield put({
        type: LOGIN_PASS_ERROR,
        // error: "invalidOtp",
        error: error,
      });
    else
      yield put({
        type: LOGIN_PASS_ERROR,
        // error: "invalidUsernameOrPassword",
        error: error,
      });
  }
}
function* passLogin() {
  yield takeLatest(LOGIN_PASS_REQUEST, runPassLogin);
}

function* runPassAzureLogin(action) {
  try {
    const { user } = yield race({
      user: call(loginWithAzure, {
        Authorization: `Bearer ${action.fields.accessToken}`,
      }),
      timeout: delay(parseResponseTimeout),
    });

    console.log("kkacccc", action, user);
    if (action.fields.accessToken && user) {
      const userData = user.data ? user.data.data : {};
      console.log("kkacccc", action);
      //   let is_twofactor_enabled = userDate.is_otp_required;
      //   // let is_twofactor_enabled = false;

      //   if (is_twofactor_enabled) {
      //     yield put({
      //       type: ASK_FOR_OTP,
      //       user: userDate.user,
      //       access_token: userDate.access_token,
      //       refresh_token: userDate.refresh_token,
      //       deviceId: action.fields && action.fields.device_udid,
      //       role: userDate.role,
      //       phone: userDate.phone,
      //     });
      //   } else {
      // Store access token in storage
      // yield call(AsyncStorage.setItem, "token", userDate.access_token);

      yield putResolve({
        type: LOGIN_PASS_SUCCESS,
        user: userData,
        access_token: action.fields && action.fields.accessToken,
        refresh_token: action.fields && action.fields.refreshToken,
        deviceId: action.fields && action.fields.device_udid,
        role: action.fields && action.fields.user_type,
        user_type: action.fields && action.fields.user_type,
      });
      setCookie("loggedIn", true);
      Router.push("/admin");
      // }
    } else
      yield put({
        type: LOGIN_PASS_ERROR,
        error: "timeout",
      });
  } catch (error) {
    yield put({
      type: LOGIN_PASS_ERROR,
      // error: "invalidUsernameOrPassword",
      error: error,
    });
  }
}

function* passAzureLogin() {
  yield takeLatest(LOGIN_PASS_AZURE_REQUEST, runPassAzureLogin);
}

function* runAddStaff(action) {
  try {
    const { staff } = yield race({
      staff: call(registerWithAzure, {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (staff) {
      console.log("access_token =====", staff?.data?.data?.access_token);
      yield put({
        type: ADD_STAFF_SUCCESS_AZURE,
        ...staff.data,
      });
      Router.push(
        `/unspecifiedUser?access_token=?${staff?.data?.data?.access_token}&refresh_token=${staff?.data?.data?.refresh_token}&user_type=${staff?.data?.data?.user_type}`
      );
    } else
      yield put({
        type: ADD_STAFF_ERROR_AZURE,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_STAFF_ERROR_AZURE,
      error,
      //error: typeof error === "object" ? error : "Could not add staff",
    });
  }
}
function* addStaff() {
  yield takeLatest(ADD_STAFF_REQUEST_AZURE, runAddStaff);
}

function* onForgetPassword(action) {
  try {
    const { user } = yield race({
      user: call(callForgetPasswordApi, {
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (user) {
      action.router.push("/login");
      yield put({
        type: FORGET_PASSWORD_SUCCESS,
        success: user.data.message,
      });
    } else
      yield put({
        type: FORGET_PASSWORD_ERROR,
        error: "timeout",
      });
  } catch (error) {
    yield put({
      type: FORGET_PASSWORD_ERROR,
      error: error,
    });
  }
}

function* forgetPassword() {
  yield takeLatest(FORGET_PASSWORD_REQUEST, onForgetPassword);
}

function* onChangePassword(action) {
  try {
    const { user } = yield race({
      user: call(callChangePasswordApi, {
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (user) {
      action.router.push("/login");
      yield put({
        type: CHANGE_PASSWORD_SUCCESS,
        success: user.data.message,
      });
    } else
      yield put({
        type: CHANGE_PASSWORD_ERROR,
        error: "timeout",
      });
  } catch (error) {
    yield put({
      type: CHANGE_PASSWORD_ERROR,
      error: error,
    });
  }
}

function* changePassword() {
  yield takeLatest(CHANGE_PASSWORD_REQUEST, onChangePassword);
}

function* login() {
  yield race([call(smsLogin), call(passLogin), call(passAzureLogin)]);
}

// logout function
// TODO: some sort of clean up. maybe the state
function* runLogout(action) {
  try {
    // TODO: remove this when notification is implemented
    // Remove access token from storage
    // yield call(AsyncStorage.removeItem, "token");

    // TODO: call endpoint to unsubscribe from notifications
    const { timeout } = yield race({
      response: call(logoutUser, {
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (timeout) {
      yield put({ type: LOGOUT_ERROR, error: "timeout" });
    } else {
      // Remove access token from storage
      // yield call(AsyncStorage.removeItem, "token");

      removeCookie("loggedIn");
      removeCookie("role");
      yield put({ type: LOGOUT_SUCCESS });
      action.router ? action.router.push("/login") : Router.push("/login");
    }
  } catch (error) {
    yield put({ type: LOGOUT_ERROR, error: error });
  }
}
function* logout() {
  yield takeLatest(LOGOUT_REQUEST, runLogout);
}

function* registerUser(action) {
  try {
    const [userObj, timeout] = yield race([
      call(register, { ...action.fields }),
      delay(parseResponseTimeout),
    ]);

    if (timeout)
      yield put({
        type: REGISTER_USER_ERROR,
        error: errors.requestTimeout.message,
      });
    else
      yield put({
        type: REGISTER_USER_SUCCESS,
      });
  } catch (error) {
    yield put({ type: REGISTER_USER_ERROR, error });
  }
}

function* runRegisterUser() {
  yield takeLatest(REGISTER_USER_REQUEST, registerUser);
}

function* checkSessionValidity(action) {
  // yield take(GET_USER_REQUEST);

  try {
    const [userObj, timeout] = yield race([
      call(checkIsSessionValid, {
        ...action.fields,
      }),
      delay(parseResponseTimeout),
    ]);

    if (userObj) {
      const userDate = (userObj.data && userObj.data.data) || {};

      // Store access token in storage
      // yield call(AsyncStorage.setItem, "token", userDate.access_token);

      yield put({
        type: IS_SESSION_VALID_SUCCESS,
        access_token: userDate.access_token,
        refresh_token: userDate.refresh_token,
      });
    } else {
      yield put({
        type: IS_SESSION_VALID_ERROR,
        error: errors.requestTimeout.message,
      });
    }
  } catch (error) {
    if (isDev) console.log(error, "------ checkSessionValidity -----2--");
    let obj = {
      type: IS_SESSION_VALID_ERROR,
      error: "User session is not valid.",
      // error?.success === 0 || error === "User session is not valid."
      //   ? "invalid"
      //   : "unknown",
    };
    if (error?.response === 440) obj.sessionError = error?.message || error;
    yield put({ ...obj });
  }
}

function* runCheckSessionValidity() {
  yield takeLatest(IS_SESSION_VALID_REQUEST, checkSessionValidity);
}

function* checkUserValidity(action) {
  try {
    const [userObj, timeout] = yield race([
      call(checkIsUserValid, {
        ...action.fields,
      }),
      delay(parseResponseTimeout),
    ]);

    if (userObj) {
      const userDate = (userObj.data && userObj.data.data) || {};

      // Store access token in storage
      // yield call(AsyncStorage.setItem, "token", userDate.access_token);

      yield put({
        type: IS_USER_STATUS_SUCCESS,
        access_token: userDate.access_token,
        refresh_token: userDate.refresh_token,
      });
    } else {
      yield put({
        type: IS_USER_STATUS_ERROR,
        error: errors.requestTimeout.message,
      });
    }
  } catch (error) {
    if (isDev) console.log(error, "---");
    let obj = {
      type: IS_USER_STATUS_ERROR,
      error: "User is not authorized.",
      // error?.success === 0 || error === "User is not authorized."
      //   ? "invalid"
      //   : "unknown",
    };
    if (error?.response === 440) obj.sessionError = error?.message || error;
    yield put({ ...obj });
  }
}

function* runCheckUserValidity() {
  yield takeLatest(IS_USER_STATUS_REQUEST, checkUserValidity);
}

function* runGetContactInfo(action) {
  try {
    const { res } = yield race({
      res: call(api.get, "setting", {
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (res) {
      yield put({
        type: CONTACT_INFO_SUCCESS,
        error: "",
        contactInfo: res.data,
      });
    } else
      yield put({
        type: CONTACT_INFO_ERROR,
        error: error?.message || error,
      });
  } catch (error) {
    yield put({
      type: CONTACT_INFO_ERROR,
      error: error.error,
    });
  }
}

function* callGetContactInfo() {
  yield takeLatest(CONTACT_INFO_REQUEST, runGetContactInfo);
}
function* runCheckAppVersion(action) {
  try {
    const { res } = yield race({
      res: call(api.get, "appVersion", {
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (res) {
      res.data.data.major_version =
        action.params?.testV || res.data.data.major_version;
      let manifestFile = res.data.data?.settings?.trip_manifest_file;
      yield put({
        type: CHECK_APP_VERSION_SUCCESS,
        error: "",
        version: res.data.data,
        tripManifest: manifestFile
          ? `/static/trip-manifest/${manifestFile}`
          : "",
      });
    } else
      yield put({
        type: CHECK_APP_VERSION_ERROR,
        error: error?.message || error,
      });
  } catch (error) {
    yield put({
      type: CHECK_APP_VERSION_ERROR,
      error: error.error,
    });
  }
}

function* callCheckAppVersion() {
  yield takeLatest(CHECK_APP_VERSION_REQUEST, runCheckAppVersion);
}

export function* saga() {
  while (true) {
    // const currentUser = yield select(state => state.core.currentUser);
    yield all([
      race([
        call(requestCode),
        call(requestCodeAzure),
        call(login),
        call(logout),
      ]),
      call(addStaff),
      call(runRegisterUser),
      call(runCheckSessionValidity),
      call(runCheckUserValidity),
      call(forgetPassword),
      call(changePassword),
      call(callGetContactInfo),
      call(callCheckAppVersion),
    ]);
  }
}
