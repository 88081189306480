import axios from "axios";
import { initializeStore } from "../../states/store";
import { apiURL } from "../apiCallsApp";

function handleError(error) {
  if (error?.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const receivedError = error.response?.data;
    if (error.response?.status === 440) {
      throw receivedError;
    } else if (
      receivedError.data &&
      typeof receivedError.data === "object" &&
      Object.keys(receivedError.data).length
    ) {
      throw receivedError.data;
    } else if (receivedError.message) {
      if (
        typeof receivedError.message === "object" ||
        typeof receivedError.message === "string"
      )
        throw receivedError.message;
      else throw JSON.stringify(receivedError.message);
    } else if (typeof receivedError === "object") {
      throw receivedError;
    } else {
      // return receivedError;
      throw new Error(JSON.stringify(receivedError));
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log("ERROR REQUEST::", error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error Message::", error.message);
  }
}

const endPoints = {
  dailyTrips: "vehicle/trip-daily",
  tripsManifest: "vehicle/manifest",
  changeVehicleBoardingStatus: "booking/vehicle-boarding-status",
};

const getAPI = async (
  endPoint,
  { admin = false, isAuth = true, lang, service = "core", id, ...params }
) => {
  try {
    // grab current state
    const store = initializeStore();
    const state = store.getState();
    const headers = {};
    // // Get auth token from store
    const authToken = state?.core?.vehicle?.access_token;
    // const authToken = "state?.core?.vehicle?.access_token;";
    if (isAuth && authToken) {
      headers.Authorization = `Bearer ${authToken}`;
    }

    return await axios.get(
      `${apiURL(lang, service, admin)}/${endPoints[endPoint]}${
        id ? "/" + id : ""
      }`,
      {
        params: {
          ...params,
        },
        headers,
        withCredentials: false,
      }
    );
  } catch (error) {
    handleError(error);
  }
};

const putAPI = async (
  endPoint,
  { admin = false, id, lang, service, ...rest }
) => {
  try {
    // grab current state
    const store = initializeStore();
    const state = store.getState();
    const serverDownErr = state?.core?.serverDownErr || null;

    // Get auth token from store
    const authToken = state?.core?.vehicle?.access_token;

    return await axios
      .patch(
        `${apiURL(lang, service, admin)}/${endPoints[endPoint]}${
          id ? "/" + id : ""
        }`,
        {
          ...rest,
        },
        {
          headers: {
            Authorization: authToken ? `Bearer ${authToken}` : undefined,
          },
          withCredentials: false,
        }
      )
      .then((data) => {
        if (serverDownErr) {
          store.dispatch({
            type: SERVER_DOWN,
            err: "",
          });
        }
        return data;
      });
  } catch (error) {
    handleError(error);
  }
};

export const vehicleApi = {
  get: getAPI,
  put: putAPI,
};
