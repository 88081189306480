import { reducer as hardwareReducers } from "./hardwareState";
import { reducer as batchReducers } from "./batchState";

const core = [hardwareReducers, batchReducers];

export default function hardwareReducer(state, action, i) {
  if (!Number.isInteger(i)) i = 0;
  if (i < core.length)
    return hardwareReducer(core[i](state, action), action, i + 1);
  else return state;
}
