// get action names
import { CHANGE_LOCALE } from "../constants";

// get initial state
import initialState from "./initialState";

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...newState, locale: action.locale, rtl: action.locale == "ar" };
    default:
      return state;
  }
}
