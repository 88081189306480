import {
  all,
  delay,
  put,
  call,
  takeLatest,
  takeEvery,
  race,
} from "redux-saga/effects";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  ADD_BOOKING_REQUEST,
  ADD_BOOKING_SUCCESS,
  ADD_BOOKING_ERROR,
  ADD_GROUP_BOOKING_REQUEST,
  ADD_GROUP_BOOKING_SUCCESS,
  ADD_GROUP_BOOKING_ERROR,
  EDIT_BOOKING_REQUEST,
  EDIT_BOOKING_SUCCESS,
  EDIT_BOOKING_ERROR,
  GET_BOOKING_REQUEST,
  GET_BOOKING_SUCCESS,
  GET_BOOKING_ERROR,
  CANCEL_BOOKING_REQUEST,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_ERROR,
  CANCEL_BULK_BOOKING_REQUEST,
  CANCEL_BULK_BOOKING_SUCCESS,
  CANCEL_BULK_BOOKING_ERROR,
  DELETE_BOOKING_REQUEST,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_ERROR,
  DELETE_BULK_BOOKING_REQUEST,
  DELETE_BULK_BOOKING_SUCCESS,
  DELETE_BULK_BOOKING_ERROR,
  GET_AVAILABLE_TRIPS_REQUEST,
  GET_AVAILABLE_TRIPS_SUCCESS,
  GET_AVAILABLE_TRIPS_ERROR,
  GET_POSSIBLE_ROUTES_REQUEST,
  GET_POSSIBLE_ROUTES_SUCCESS,
  GET_POSSIBLE_ROUTES_ERROR,
  GET_FUTURE_TRIP_DATE_REQUEST,
  GET_FUTURE_TRIP_DATE_SUCCESS,
  GET_FUTURE_TRIP_DATE_ERROR,
  CLEAR_BOOKING_ACTION_STATUS,
  VERIFY_STOPS_REQUEST,
  VERIFY_STOPS_SUCCESS,
  VERIFY_STOPS_ERROR,
  GET_LINKED_TRIP_REQUEST,
  GET_LINKED_TRIP_ERROR,
  GET_LINKED_TRIP_SUCCESS,
  GET_LINKED_VEHICLE_REQUEST,
  GET_LINKED_VEHICLE_ERROR,
  GET_LINKED_VEHICLE_SUCCESS,
  MOVE_PASSENGERS_REQUEST,
  MOVE_PASSENGERS_SUCCESS,
  MOVE_PASSENGERS_ERROR,
  MOVE_BOOKING_LIST,
  CHECK_MOVE_BOOKING_REQUEST,
  CHECK_MOVE_BOOKING_ERROR,
  CHECK_MOVE_BOOKING_SUCCESS,
  GET_LINKED_ROUTE_REQUEST,
  GET_LINKED_ROUTE_ERROR,
  GET_LINKED_ROUTE_SUCCESS,
  CHECK_EDIT_BOOKING_REQUEST,
  CHECK_EDIT_BOOKING_SUCCESS,
  CHECK_EDIT_BOOKING_ERROR,
  GET_PARK_RIDE_BOOKING_REQUEST,
  GET_PARK_RIDE_BOOKING_ERROR,
  GET_PARK_RIDE_BOOKING_SUCCESS,
  EDIT_PARK_RIDE_BOOKING_REQUEST,
  EDIT_PARK_RIDE_BOOKING_ERROR,
  EDIT_PARK_RIDE_BOOKING_SUCCESS,
  GET_PARK_RIDE_BOOKING_HISTORY_REQUEST,
  GET_PARK_RIDE_BOOKING_HISTORY_SUCCESS,
  GET_PARK_RIDE_BOOKING_HISTORY_ERROR,
  GET_FEEDBACK_REQUEST,
  GET_FEEDBACK_SUCCESS,
  GET_FEEDBACK_ERROR,
  // smsCodeTimeout
} from "../constants";

// get initial state
import initialState from "./initialState";
import { capitalizeWord } from "../../../utils/stringHelpers";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 50 * 1000; // 50 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_BOOKING_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "booking",
          success: null,
          error: "",
        },
      };
    case ADD_BOOKING_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "booking",
          success: true,
          error: "",
        },
      };
    case ADD_BOOKING_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "booking",
          success: false,
          error: action.error,
        },
      };
    case ADD_GROUP_BOOKING_REQUEST:
      return {
        ...newState,
        existingBookings: null,
        actionStatus: {
          type: "bulkAdd",
          entity: "booking",
          success: null,
          error: "",
        },
      };
    case ADD_GROUP_BOOKING_SUCCESS:
      return {
        ...newState,
        existingBookings: action.existing_bookings,
        actionStatus: {
          type: "bulkAdd",
          entity: "booking",
          success: true,
          error: "",
        },
      };
    case ADD_GROUP_BOOKING_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkAdd",
          entity: "booking",
          success: false,
          error: action.error,
        },
      };
    case EDIT_BOOKING_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "booking",
          success: null,
          error: "",
        },
      };

    case EDIT_BOOKING_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "booking",
          success: true,
          error: "",
        },
      };
    case EDIT_BOOKING_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "booking",
          success: false,
          error: action.error,
        },
      };

    case CANCEL_BOOKING_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "cancel",
          entity: "booking",
          success: null,
          error: "",
        },
      };
    case CANCEL_BOOKING_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "cancel",
          entity: "booking",
          success: true,
          error: "",
        },
      };
    case CANCEL_BOOKING_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "cancel",
          entity: "booking",
          success: false,
          error: action.error,
        },
      };

    case CANCEL_BULK_BOOKING_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "cancel",
          entity: "booking",
          success: null,
          error: "",
        },
      };
    case CANCEL_BULK_BOOKING_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "cancel",
          entity: "booking",
          success: true,
          error: "",
        },
      };
    case CANCEL_BULK_BOOKING_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "cancel",
          entity: "booking",
          success: false,
          error: action.error,
        },
      };

    case DELETE_BOOKING_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "booking",
          success: null,
          error: "",
        },
      };
    case DELETE_BOOKING_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "booking",
          success: true,
          error: "",
        },
      };
    case DELETE_BOOKING_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "booking",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BULK_BOOKING_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "booking",
          success: null,
          error: "",
        },
      };
    case DELETE_BULK_BOOKING_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "booking",
          success: true,
          error: "",
        },
      };
    case DELETE_BULK_BOOKING_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "booking",
          success: false,
          error: action.error,
        },
      };
    case GET_BOOKING_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "booking",
                success: null,
                error: "",
              },
      };
    case GET_BOOKING_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        [action.bookingType || "bookings"]: action.bookings,
        [(action.bookingType && `${action.bookingType}Count`) ||
        "bookingsCount"]: action.count,
        // bookingsCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "booking",
                success: true,
                error: "",
              },
      };
    case GET_BOOKING_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "booking",
          success: false,
          error: action.error,
        },
      };

    case GET_PARK_RIDE_BOOKING_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "parkRideBooking",
                success: null,
                error: "",
              },
      };
    case GET_PARK_RIDE_BOOKING_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        parkingBookings: action.parkingBookings,
        parkingBookingsCount: action.parkingBookingsCount,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "parkRideBooking",
                success: true,
                error: "",
              },
      };
    case GET_PARK_RIDE_BOOKING_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "parkRideBooking",
          success: false,
          error: action.error,
        },
      };

    case GET_FEEDBACK_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "feedback",
                success: null,
                error: "",
              },
      };
    case GET_FEEDBACK_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        bookings: action.bookings,
        count: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "feedback",
                success: true,
                error: "",
              },
      };
    case GET_FEEDBACK_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "feedback",
          success: false,
          error: action.error,
        },
      };

    case GET_PARK_RIDE_BOOKING_HISTORY_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "parkRideBookingHistory",
                success: null,
                error: "",
              },
      };
    case GET_PARK_RIDE_BOOKING_HISTORY_SUCCESS:
      return {
        ...newState,
        historyFilters: action.historyFilters,
        parkingBookingsHistory: action.parkingBookingsHistory,
        parkingBookingsHistoryCount: action.parkingBookingsHistoryCount,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "parkRideBookingHistory",
                success: true,
                error: "",
              },
      };
    case GET_PARK_RIDE_BOOKING_HISTORY_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "parkRideBookingHistory",
          success: false,
          error: action.error,
        },
      };

    case GET_AVAILABLE_TRIPS_REQUEST:
      return {
        ...newState,
        trips: null,
        actionStatus: {
          type: "fetch",
          entity: "availableTrips",
          success: null,
          error: "",
        },
      };

    case GET_AVAILABLE_TRIPS_SUCCESS:
      return {
        ...newState,
        tripFilters: action.filters,
        trips: action.trips,
        actionStatus: {
          type: "fetch",
          entity: "availableTrips",
          success: true,
          error: "",
        },
      };
    case GET_AVAILABLE_TRIPS_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "availableTrips",
          success: false,
          error: action.error,
        },
      };

    case GET_POSSIBLE_ROUTES_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "possibleRoutes",
          success: null,
          error: "",
        },
      };

    case GET_POSSIBLE_ROUTES_SUCCESS:
      return {
        ...newState,
        routes: action.routes,
        availableDates: [], // Reset dates
        actionStatus: {
          type: "fetch",
          entity: "possibleRoutes",
          success: true,
          error: "",
        },
      };
    case GET_POSSIBLE_ROUTES_ERROR:
      return {
        ...newState,
        trips: [],
        actionStatus: {
          type: "fetch",
          entity: "possibleRoutes",
          success: false,
          error: action.error,
        },
      };

    case GET_FUTURE_TRIP_DATE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "futureDates",
          success: null,
          error: "",
        },
      };

    case GET_FUTURE_TRIP_DATE_SUCCESS:
      return {
        ...newState,
        availableDates: action.data,
        actionStatus: {
          type: "fetch",
          entity: "futureDates",
          success: true,
          error: "",
        },
      };
    case GET_FUTURE_TRIP_DATE_ERROR:
      return {
        ...newState,
        trips: [],
        actionStatus: {
          type: "fetch",
          entity: "futureDates",
          success: false,
          error: action.error,
        },
      };
    case CLEAR_BOOKING_ACTION_STATUS:
      return {
        ...newState,
        actionStatus: { type: "", entity: "", success: null, error: "" },
      };
    case VERIFY_STOPS_REQUEST:
      return {
        ...newState,
        verifyStops: {
          loading: true,
          proceed: null,
          data: action.params,
        },
      };
    case VERIFY_STOPS_SUCCESS:
      return {
        ...newState,
        verifyStops: {
          loading: false,
          proceed: true,
          data: action.params,
        },
      };
    case VERIFY_STOPS_ERROR:
      return {
        ...newState,
        verifyStops: {
          loading: false,
          error: action?.error,
          proceed: false,
          data: action.params,
        },
      };
    case GET_LINKED_TRIP_REQUEST:
      return {
        ...newState,
        linkedEntity: {
          ...newState?.linkedEntity,
          status: {
            type: "fetch",
            entity: "trip",
            success: null,
            error: "",
          },
        },
      };
    case GET_LINKED_TRIP_SUCCESS:
      return {
        ...newState,
        linkedEntity: {
          ...newState?.linkedEntity,
          filters: action.filters,
          trips: action.trips,
          tripsCount: action.count,
          status: {
            type: "fetch",
            entity: "trip",
            success: true,
            error: "",
          },
        },
      };
    case GET_LINKED_TRIP_ERROR:
      return {
        ...newState,
        linkedEntity: {
          ...newState?.linkedEntity,
          status: {
            type: "fetch",
            entity: "trip",
            success: false,
            error: action.error,
          },
        },
      };
    case GET_LINKED_VEHICLE_REQUEST:
      return {
        ...newState,
        linkedEntity: {
          ...newState?.linkedEntity,
          status: {
            type: "fetch",
            entity: "vehicle",
            success: null,
            error: "",
          },
        },
      };
    case GET_LINKED_VEHICLE_SUCCESS:
      return {
        ...newState,
        linkedEntity: {
          ...newState?.linkedEntity,
          vehicles: action.vehicles,
          vehiclesCount: action.count,
          status: {
            type: "fetch",
            entity: "vehicle",
            success: true,
            error: "",
          },
        },
      };
    case GET_LINKED_VEHICLE_ERROR:
      return {
        ...newState,
        linkedEntity: {
          ...newState?.linkedEntity,
          status: {
            type: "fetch",
            entity: "vehicle",
            success: false,
            error: action.error,
          },
        },
      };
    case GET_LINKED_ROUTE_SUCCESS:
      return {
        ...newState,
        linkedEntity: {
          ...newState?.linkedEntity,
          routes: action.routes.rows,
          routesCount: action.routes.count,
          status: {},
        },
      };
    case GET_LINKED_ROUTE_ERROR:
      return {
        ...newState,
        linkedEntity: { ...newState?.linkedEntity, status: {} },
      };
    case GET_LINKED_ROUTE_REQUEST:
      return {
        ...newState,
        linkedEntity: { ...newState?.linkedEntity, status: {} },
      };
    case MOVE_PASSENGERS_REQUEST:
      return {
        ...newState,
        pendingBookings: [],
        actionStatus: {
          type: "add",
          entity: "movePassengers",
          success: null,
          error: "",
        },
      };
    case MOVE_PASSENGERS_SUCCESS:
      return {
        ...newState,
        pendingBookings: action.data || [],
        actionStatus: {
          type: "add",
          entity: "movePassengers",
          success: true,
          error: "",
        },
      };
    case MOVE_PASSENGERS_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "movePassengers",
          success: false,
          error: action.error,
        },
      };
    case MOVE_BOOKING_LIST:
      return {
        ...newState,
        moveBookingList: action.params.list,
        actionStatus: {
          type: "",
          entity: "",
          success: null,
          error: "",
        },
      };

    case CHECK_MOVE_BOOKING_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "verify",
          entity: "movePassengers",
          success: null,
          error: "",
        },
      };
    case CHECK_MOVE_BOOKING_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "verify",
          entity: "movePassengers",
          success: true,
          error: "",
        },
      };
    case CHECK_MOVE_BOOKING_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "verify",
          entity: "movePassengers",
          success: false,
          error: action.error,
        },
      };

    case CHECK_EDIT_BOOKING_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "check",
          entity: "movePassengers",
          success: null,
          error: "",
        },
      };
    case CHECK_EDIT_BOOKING_SUCCESS:
      return {
        ...newState,
        editData: action.data,
        actionStatus: {
          type: "check",
          entity: "movePassengers",
          success: action.data.edit_allowed_bookings ? true : false,
          error: "",
        },
      };
    case CHECK_EDIT_BOOKING_ERROR:
      return {
        ...newState,
        editData: action.data,
        actionStatus: {
          type: "check",
          entity: "movePassengers",
          success: false,
          error: action.error,
        },
      };

    case EDIT_PARK_RIDE_BOOKING_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "parkRideBooking",
          success: null,
          error: "",
        },
      };
    case EDIT_PARK_RIDE_BOOKING_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "parkRideBooking",
          success: true,
          error: "",
        },
      };
    case EDIT_PARK_RIDE_BOOKING_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "parkRideBooking",
          success: false,
          error: action.error,
        },
      };

    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

// gtg
// fb app
// app + web stream
// no-translate --> href eng nep language
//

function* runGetAvailableTrips(action) {
  try {
    const { trip } = yield race({
      trip: call(api.get, "tripAvailable", {
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (trip) {
      const trips = trip.data ? trip.data.data : [];

      yield put({ type: GET_AVAILABLE_TRIPS_SUCCESS, trips });
    } else
      yield put({
        type: GET_AVAILABLE_TRIPS_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_AVAILABLE_TRIPS_ERROR,
      error:
        typeof error === "object" || typeof error === "string"
          ? error
          : "Available trips could not be updated",
    });
  }
}
function* getAvailableTrips() {
  yield takeLatest(GET_AVAILABLE_TRIPS_REQUEST, runGetAvailableTrips);
}

function* runGetBookings(action) {
  try {
    let url =
      action.params.filterFor === "trips"
        ? "ByTrip"
        : action.params.filterFor === "passengers"
        ? "ByPassenger"
        : "";

    const { booking } = yield race({
      booking: call(api.get, `booking${url}`, {
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (booking) {
      const bookings = booking.data ? booking.data.data : [];
      yield put({
        type: GET_BOOKING_SUCCESS,
        bookings: bookings.rows,
        count: bookings.count,
        source: action.source,
        filters: action.filters,
        bookingType: action.params && action.params.type,
      });
    } else
      yield put({
        type: GET_BOOKING_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_BOOKING_ERROR,
      error:
        typeof error === "object" ? error : "Bookings could not be updated.",
    });
  }
}
function* getBookings() {
  yield takeLatest(GET_BOOKING_REQUEST, runGetBookings);
}

function* runAddBooking(action) {
  try {
    const { booking } = yield race({
      booking: call(api.add, "booking", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (booking)
      yield put({
        type: ADD_BOOKING_SUCCESS,
        ...booking.data,
      });
    else
      yield put({
        type: ADD_BOOKING_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_BOOKING_ERROR,
      error, // error: typeof error === "object" ? error : "Could not add booking",
    });
  }
}
function* addBooking() {
  yield takeLatest(ADD_BOOKING_REQUEST, runAddBooking);
}

function* runAddGroupBooking(action) {
  try {
    const { booking } = yield race({
      booking: call(api.add, "bulkBooking", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (booking)
      yield put({
        type: ADD_GROUP_BOOKING_SUCCESS,
        ...booking.data,
      });
    else
      yield put({
        type: ADD_GROUP_BOOKING_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_GROUP_BOOKING_ERROR,
      error, //error: typeof error === "object" ? error : "Could not add booking",
    });
  }
}
function* addGroupBooking() {
  yield takeEvery(ADD_GROUP_BOOKING_REQUEST, runAddGroupBooking);
}

function* runEditBooking(action) {
  try {
    const { booking } = yield race({
      booking: call(api.update, "editBooking", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (booking)
      yield put({
        type: EDIT_BOOKING_SUCCESS,
        ...booking.data,
      });
    else
      yield put({
        type: EDIT_BOOKING_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_BOOKING_ERROR,
      error, //  error: typeof error === "object" ? error : "Could not edit booking",
    });
  }
}
function* editBooking() {
  yield takeLatest(EDIT_BOOKING_REQUEST, runEditBooking);
}

function* runDeleteBooking(action) {
  try {
    const { booking } = yield race({
      booking: call(api.delete, "booking", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (booking) yield put({ type: DELETE_BOOKING_SUCCESS });
    else
      yield put({
        type: DELETE_BOOKING_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BOOKING_ERROR,
      error: typeof error === "object" ? error : "Could not delete booking",
    });
  }
}
function* deleteBooking() {
  yield takeLatest(DELETE_BOOKING_REQUEST, runDeleteBooking);
}

function* runDeleteBulkBooking(action) {
  try {
    const { booking } = yield race({
      booking: call(api.delete, "booking", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (booking) yield put({ type: DELETE_BULK_BOOKING_SUCCESS });
    else
      yield put({
        type: DELETE_BULK_BOOKING_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BULK_BOOKING_ERROR,
      error: typeof error === "object" ? error : "Could not delete booking",
    });
  }
}
function* deleteBulkBooking() {
  yield takeEvery(DELETE_BULK_BOOKING_REQUEST, runDeleteBulkBooking);
}

function* runCancelBooking(action) {
  try {
    const { booking } = yield race({
      booking: call(api.update, "cancelBooking", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (booking) yield put({ type: CANCEL_BOOKING_SUCCESS });
    else
      yield put({
        type: CANCEL_BOOKING_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: CANCEL_BOOKING_ERROR,
      error: typeof error === "object" ? error : "Could not delete booking",
    });
  }
}
function* cancelBooking() {
  yield takeLatest(CANCEL_BOOKING_REQUEST, runCancelBooking);
}

function* runCancelBulkBooking(action) {
  try {
    const { booking } = yield race({
      booking: call(api.update, "cancelBulkBooking", {
        booking_ids: action.ids,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (booking) yield put({ type: CANCEL_BULK_BOOKING_SUCCESS });
    else
      yield put({
        type: CANCEL_BULK_BOOKING_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: CANCEL_BULK_BOOKING_ERROR,
      error: typeof error === "object" ? error : "Could not cancel booking",
    });
  }
}

function* cancelBulkBooking() {
  yield takeLatest(CANCEL_BULK_BOOKING_REQUEST, runCancelBulkBooking);
}

function* runGetPossibleRoutes(action) {
  try {
    const { route } = yield race({
      route: call(api.get, "possibleRoutes", {
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (route) {
      const routes = route.data ? route.data.data : [];

      yield put({
        type: GET_POSSIBLE_ROUTES_SUCCESS,
        routes,
      });
    } else
      yield put({
        type: GET_POSSIBLE_ROUTES_ERROR,
        error: "timeout",
      });
  } catch (error) {
    yield put({
      type: GET_POSSIBLE_ROUTES_ERROR,
      error:
        typeof error === "object" || typeof error === "string"
          ? error
          : "Routes could not be updated.",
    });
  }
}
function* getPossibleRoutes() {
  yield takeLatest(GET_POSSIBLE_ROUTES_REQUEST, runGetPossibleRoutes);
}

function* runGetFutureTripDates(action) {
  try {
    const { tripDate } = yield race({
      tripDate: call(api.get, "futureTripDate", {
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (tripDate) {
      const tripDates = tripDate.data ? tripDate.data.data : [];

      yield put({ type: GET_FUTURE_TRIP_DATE_SUCCESS, data: tripDates });
    } else
      yield put({
        type: GET_FUTURE_TRIP_DATE_ERROR,
        error: "timeout",
      });
  } catch (error) {
    yield put({
      type: GET_FUTURE_TRIP_DATE_ERROR,
      error:
        typeof error === "object" || typeof error === "string"
          ? error
          : "Future date could not be updated.",
    });
  }
}
function* getFutureTripDates() {
  yield takeLatest(GET_FUTURE_TRIP_DATE_REQUEST, runGetFutureTripDates);
}

function* runVerifyingStops(action) {
  try {
    const { res } = yield race({
      res: call(api.add, "verifyStops", {
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (res) {
      const result = res.data ? res.data.data : [];

      yield put({
        type: VERIFY_STOPS_SUCCESS,
        data: result,
        params: action.params,
      });
    } else
      yield put({
        type: VERIFY_STOPS_ERROR,
        error: "timeout",
      });
  } catch (error) {
    yield put({
      type: VERIFY_STOPS_ERROR,
      params: action.params,
      error:
        typeof error === "object" || typeof error === "string"
          ? error
          : "Stops could not be verified",
    });
  }
}
function* verifyingStops() {
  yield takeLatest(VERIFY_STOPS_REQUEST, runVerifyingStops);
}

function* runGetLinkedTrips(action) {
  try {
    const { trip } = yield race({
      trip: call(api.get, "trip", {
        lang: action.lang,
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (trip) {
      const trips = trip.data ? trip.data.data : [];
      yield put({
        type: GET_LINKED_TRIP_SUCCESS,
        trips: trips.rows,
        count: trips.count,
        filters: action.params,
      });
    } else
      yield put({
        type: GET_LINKED_TRIP_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_LINKED_TRIP_ERROR,
      error: typeof error === "object" ? error : "Trips could not be updated.",
    });
  }
}
function* getLinkedTrips() {
  yield takeLatest(GET_LINKED_TRIP_REQUEST, runGetLinkedTrips);
}

function* runGetLinkedVehicles(action) {
  try {
    let url = action.params.search ? "vehicleSearch" : "vehicle";
    const { vehicle } = yield race({
      vehicle: call(api.get, url, {
        lang: action.lang,
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (vehicle) {
      const vehicles = vehicle.data ? vehicle.data.data : [];
      yield put({
        type: GET_LINKED_VEHICLE_SUCCESS,
        vehicles: vehicles.rows,
        count: vehicles.count,
      });
    } else
      yield put({
        type: GET_LINKED_VEHICLE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_LINKED_VEHICLE_ERROR,
      error:
        typeof error === "object"
          ? error
          : "LinkedVehicles could not be updated.",
    });
  }
}
function* getLinkedVehicles() {
  yield takeLatest(GET_LINKED_VEHICLE_REQUEST, runGetLinkedVehicles);
}

function* runMovePassengers(action) {
  try {
    let url = `movePassengers${capitalizeWord(action?.entity)}`;
    const { res } = yield race({
      res: call(api.add, url, {
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (res) {
      const data = res.data ? res.data.data : [];
      yield put({
        type: MOVE_PASSENGERS_SUCCESS,
        data: data.pending_bookings,
        entity: action?.entity,
      });
    } else
      yield put({
        type: MOVE_PASSENGERS_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: MOVE_PASSENGERS_ERROR,
      error: error || "There was problem Moving passenger",
    });
  }
}
function* movePassengers() {
  yield takeLatest(MOVE_PASSENGERS_REQUEST, runMovePassengers);
}

function* runCheckMovingBooking(action) {
  try {
    let url = `checkMoveBookings${capitalizeWord(action?.entity)}`;
    const { res } = yield race({
      res: call(api.add, url, {
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (res) {
      const data = res.data ? res.data.data : [];
      yield put({
        type: CHECK_MOVE_BOOKING_SUCCESS,
        data: data.rows,
        count: data.count,
        entity: action?.entity,
      });
    } else
      yield put({
        type: CHECK_MOVE_BOOKING_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: CHECK_MOVE_BOOKING_ERROR,
      error:
        typeof error === "object" ? error : "oops, moving bookings failed.",
    });
  }
}
function* checkMovingBooking() {
  yield takeLatest(CHECK_MOVE_BOOKING_REQUEST, runCheckMovingBooking);
}

function* runCheckEditBooking(action) {
  try {
    let url = `editCheck`;
    const { res } = yield race({
      res: call(api.add, url, {
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (res) {
      const data = res.data ? res.data.data : [];

      yield put({
        type: CHECK_EDIT_BOOKING_SUCCESS,
        data: data,
        entity: action?.entity,
      });
    } else {
      yield put({
        type: CHECK_EDIT_BOOKING_ERROR,
        error: errors.requestTimeout.message,
      });
    }
  } catch (error) {
    yield put({
      type: CHECK_EDIT_BOOKING_ERROR,
      data: error,
      error: typeof error === "object" ? error : "oops, edit bookings failed.",
    });
  }
}
function* checkEditingBooking() {
  yield takeLatest(CHECK_EDIT_BOOKING_REQUEST, runCheckEditBooking);
}

function* runGetLinkedRoute(action) {
  try {
    const { route } = yield race({
      route: call(api.get, "route", {
        lang: action.lang,
        ...action.params,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (route) {
      const routes = route.data ? route.data.data : [];
      yield put({
        type: GET_LINKED_ROUTE_SUCCESS,
        source: action.source,
        routes,
      });
    } else
      yield put({
        type: GET_LINKED_ROUTE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_LINKED_ROUTE_ERROR,
      error: typeof error === "object" ? error : "Routes could not be updated.",
    });
  }
}

function* getLinkedRoute() {
  yield takeLatest(GET_LINKED_ROUTE_REQUEST, runGetLinkedRoute);
}

function* runGetParkRideBookings(action) {
  try {
    const { booking } = yield race({
      booking: call(api.get, `parkRideBooking`, {
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (booking) {
      const bookings = booking.data ? booking.data : [];
      yield put({
        type: GET_PARK_RIDE_BOOKING_SUCCESS,
        parkingBookings: bookings.data,
        parkingBookingsCount: bookings.count,
        source: action.source,
        filters: action.filters,
        bookingType: action.params && action.params.type,
      });
    } else
      yield put({
        type: GET_PARK_RIDE_BOOKING_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_PARK_RIDE_BOOKING_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Park and Ride Bookings could not be updated.",
    });
  }
}
function* getParkRideBookings() {
  yield takeLatest(GET_PARK_RIDE_BOOKING_REQUEST, runGetParkRideBookings);
}

function* runGetParkRideBookingsHistory(action) {
  try {
    const { booking } = yield race({
      booking: call(api.get, `parkRideBooking`, {
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (booking) {
      const bookings = booking.data ? booking.data : [];
      yield put({
        type: GET_PARK_RIDE_BOOKING_HISTORY_SUCCESS,
        parkingBookingsHistory: bookings.data,
        parkingBookingsHistoryCount: bookings.count,
        source: action.source,
        historyFilters: action.filters,
        bookingType: action.params && action.params.type,
      });
    } else
      yield put({
        type: GET_PARK_RIDE_BOOKING_HISTORY_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_PARK_RIDE_BOOKING_HISTORY_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Park and Ride Bookings could not be updated.",
    });
  }
}
function* getParkRideBookingsHistory() {
  yield takeLatest(
    GET_PARK_RIDE_BOOKING_HISTORY_REQUEST,
    runGetParkRideBookingsHistory
  );
}

function* runGetFeedback(action) {
  try {
    const { booking } = yield race({
      booking: call(api.get, "feedback", {
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (booking) {
      const bookings = booking.data ? booking.data : [];
      yield put({
        type: GET_FEEDBACK_SUCCESS,
        bookings: bookings.data,
        count: bookings.count,
        source: action.source,
        filters: action.filters,
        bookingType: action.params && action.params.type,
      });
    } else
      yield put({
        type: GET_FEEDBACK_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_FEEDBACK_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Feedback list could not be updated.",
    });
  }
}
function* getFeedback() {
  yield takeLatest(GET_FEEDBACK_REQUEST, runGetFeedback);
}

function* runEditPRBooking(action) {
  try {
    const { booking } = yield race({
      booking: call(api.updateNoId, "editParkRideBookingStatus", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (booking)
      yield put({
        type: EDIT_PARK_RIDE_BOOKING_SUCCESS,
        ...booking.data,
      });
    else
      yield put({
        type: EDIT_PARK_RIDE_BOOKING_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_PARK_RIDE_BOOKING_ERROR,
      error, //  error: typeof error === "object" ? error : "Could not edit booking",
    });
  }
}
function* editPRBooking() {
  yield takeLatest(EDIT_PARK_RIDE_BOOKING_REQUEST, runEditPRBooking);
}

export function* saga() {
  while (true) {
    yield all({
      getBookings: call(getBookings),
      getAvailableTrips: call(getAvailableTrips),
      addBooking: call(addBooking),
      addGroupBooking: call(addGroupBooking),
      editBooking: call(editBooking),
      deleteBooking: call(deleteBooking),
      deleteBulkBooking: call(deleteBulkBooking),
      cancelBooking: call(cancelBooking),
      cancelBulkBooking: call(cancelBulkBooking),
      getPossibleRoutes: call(getPossibleRoutes),
      getFutureTripDates: call(getFutureTripDates),
      verifyingStops: call(verifyingStops),
      getLinkedTrips: call(getLinkedTrips),
      getLinkedVehicles: call(getLinkedVehicles),
      movePassengers: call(movePassengers),
      checkMovingBooking: call(checkMovingBooking),
      checkEditBooking: call(checkEditingBooking),
      getLinkedRoute: call(getLinkedRoute),
      getParkRideBookings: call(getParkRideBookings),
      getParkRideBookingsHistory: call(getParkRideBookingsHistory),
      getFeedback: call(getFeedback),
      editPRBooking: call(editPRBooking),
    });
  }
}
