import { reducer as routeReducers } from "./routeState";
// import { reducer as loggingUser } from './login'

const core = [
  routeReducers,
  // loggingUser
];

export default function routeReducer(state, action, i) {
  if (!Number.isInteger(i)) i = 0;
  if (i < core.length)
    return routeReducer(core[i](state, action), action, i + 1);
  else return state;
}
