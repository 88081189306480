import axios from "axios";
import { initializeStore } from "../../states/store";
import { apiURL } from "../apiCallsApp";
import { handleError } from "../handleError";

import createAuthRefreshInterceptor from "axios-auth-refresh";

import { endPoints } from "./endpoints";
import { refreshAuthLogic } from "./refreshAuthLogic";
import { SERVER_DOWN } from "../../modules/core/constants";
import { setItemLocal } from "../storageFunc";

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axios, refreshAuthLogic);

const getAPI = async (
  endPoint,
  { admin = false, isAuth = true, lang, service = "core", id, ...params }
) => {
  try {
    // grab current state

    const store = initializeStore();
    const state = store.getState();

    const serverDownErr = state?.core?.serverDownErr || null;
    const headers = {};

    // Get auth token from store
    const authToken = state.core && state.core.accessToken;
    if (isAuth && authToken) {
      headers.Authorization = `Bearer ${authToken}`;
    }
    return await axios
      .get(
        `${apiURL(lang, service, admin)}/${endPoints[endPoint]}${
          id ? "/" + id : ""
        }`,
        {
          params: {
            ...params,
          },
          headers,
          withCredentials: false,
          // validateStatus: function(status) {
          //   console.log(status, " validateStatus: function (status)");
          //   return true; // default
          // },
        }
      )
      .then((data) => {
        setItemLocal("lastFetched", new Date().getTime());
        if (serverDownErr) {
          store.dispatch({
            type: SERVER_DOWN,
            err: "",
          });
        }
        return data;
      });
  } catch (error) {
    handleError(error);
  }
};

const postAPI = async (
  endPoint,
  { admin = false, params, lang, id, service, ...rest }
) => {
  try {
    // grab current state
    const store = initializeStore();
    const state = store.getState();
    const serverDownErr = state?.core?.serverDownErr || null;

    // Get auth token from store
    const authToken = state.core && state.core.accessToken;

    return await axios
      .post(
        `${apiURL(lang, service, admin)}/${endPoints[endPoint]}${
          id ? "/" + id : ""
        }`,
        {
          ...rest,
        },
        {
          headers: {
            Authorization: authToken ? `Bearer ${authToken}` : undefined,
          },
          withCredentials: false,
          params,
        }
      )
      .then((data) => {
        if (serverDownErr) {
          store.dispatch({
            type: SERVER_DOWN,
            err: "",
          });
        }
        return data;
      });
  } catch (error) {
    handleError(error);
  }
};

const putAPI = async (
  endPoint,
  { admin = false, id, lang, service, ...rest }
) => {
  try {
    // grab current state
    const store = initializeStore();
    const state = store.getState();
    const serverDownErr = state?.core?.serverDownErr || null;

    // Get auth token from store
    const authToken = state.core && state.core.accessToken;

    return await axios
      .patch(
        `${apiURL(lang, service, admin)}/${endPoints[endPoint]}${
          id ? "/" + id : ""
        }`,
        {
          ...rest,
        },
        {
          headers: {
            Authorization: authToken ? `Bearer ${authToken}` : undefined,
          },
          withCredentials: false,
        }
      )
      .then((data) => {
        if (serverDownErr) {
          store.dispatch({
            type: SERVER_DOWN,
            err: "",
          });
        }
        return data;
      });
  } catch (error) {
    handleError(error);
  }
};

const putAPINoId = async (
  endPoint,
  { admin = false, lang, service, ...rest }
) => {
  try {
    // grab current state
    const store = initializeStore();
    const state = store.getState();
    const serverDownErr = state?.core?.serverDownErr || null;

    // Get auth token from store
    const authToken = state.core && state.core.accessToken;

    return await axios
      .patch(
        `${apiURL(lang, service, admin)}/${endPoints[endPoint]}`,
        {
          ...rest,
        },
        {
          headers: {
            Authorization: authToken ? `Bearer ${authToken}` : undefined,
          },
          withCredentials: false,
        }
      )
      .then((data) => {
        if (serverDownErr) {
          store.dispatch({
            type: SERVER_DOWN,
            err: "",
          });
        }
        return data;
      });
  } catch (error) {
    handleError(error);
  }
};

const deleteAPI = async (
  endPoint,
  { admin = false, lang, service, id, ...rest }
) => {
  try {
    // grab current state
    const store = initializeStore();
    const state = store.getState();
    const serverDownErr = state?.core?.serverDownErr || null;
    const { data } = rest;
    console.log({ rest, data });
    // Get auth token from store
    const authToken = state.core && state.core.accessToken;

    return await axios
      .delete(
        `${apiURL(lang, service, admin)}/${endPoints[endPoint]}${
          id ? "/" + id : ""
        }`,
        {
          headers: {
            Authorization: authToken ? `Bearer ${authToken}` : undefined,
          },
          data,
          withCredentials: false,
        }
      )
      .then((data) => {
        if (serverDownErr) {
          store.dispatch({
            type: SERVER_DOWN,
            err: "",
          });
        }
        return data;
      });
  } catch (error) {
    handleError(error);
  }
};

const uploadAPI = async (
  endPoint,
  { admin = true, lang, service, id, image, ...rest }
) => {
  try {
    // console.log(`${apiURL(lang, service)}/${endPoints[endPoint]}/${id || ""}`);

    const store = initializeStore();
    const state = store.getState();
    const serverDownErr = state?.core?.serverDownErr || null;
    const authToken = state.core && state.core.accessToken;

    let formData = new FormData();
    formData.append("image", image);

    const entries = Object.entries(rest);
    if (Object.values(rest).length) {
      for (const [key, value] of entries) {
        formData.append(key, value);
      }
    }

    return await axios
      .post(
        `${apiURL(lang, service, admin)}/${endPoints[endPoint]}/${id || ""}`,
        formData,
        {
          withCredentials: false,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: authToken ? `Bearer ${authToken}` : undefined,
          },
        }
      )
      .then((data) => {
        if (serverDownErr) {
          store.dispatch({
            type: SERVER_DOWN,
            err: "",
          });
        }
        return data;
      });
  } catch (error) {
    handleError(error);
  }
};

//testPostAPI is a garbage function used while testing is used in /admin/settings page
const testPostAPI = async (
  endPoint,
  { admin = false, params = {}, lang, id, service, ...rest }
) => {
  try {
    // grab current state
    const store = initializeStore();
    const state = store.getState();
    const authToken = state.settings.tkn;
    const headers = {};
    // Get auth token from store
    if (authToken) headers.Authorization = `Bearer ${authToken}`;
    // if (authToken) headers.Authorization = `Bearer `;
    const access_token = state?.core?.accessToken + "a";
    const refresh_token = state?.core?.accessToken;
    return await axios.post(
      "https://mytrip-dev.emushrif.om/api/auth/refresh-token",
      {
        // .post("https://mytrip-dev.emushrif.om/api/auth/create-test-token", {
        access_token,
        refresh_token,
      },
      //   )

      // return await axios.post(
      //   `https://mytrip-dev.emushrif.om/api/${endPoints[endPoint]}`,
      //   // `https://b3db4521.ngrok.io/api/${endPoints[endPoint]}`,
      //   {},
      {
        headers,
        withCredentials: false,
        params,
      }
    );
  } catch (error) {
    handleError(error);
  }
};

const api = {
  get: getAPI,
  update: putAPI,
  updateNoId: putAPINoId,
  delete: deleteAPI,
  add: postAPI,
  upload: uploadAPI,
  getT: testPostAPI,
};
export default api;
