import { initializeStore } from "../states/store";
import { SERVER_DOWN } from "../modules/core/constants";
export function handleError(error) {
  const store = initializeStore();
  const state = store.getState();
  const serverDownErr = state?.core?.serverDownErr || null;
  // if (!error?.response) {
  //   // console.log("reached here", "-------");
  //   if (!serverDownErr) {
  //     store.dispatch({
  //       type: SERVER_DOWN,
  //       err: "Oops!! Server is under maintenance. Please try again in a while.",
  //     });
  //   }
  // }

  if (error?.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const receivedError = error.response?.data;
    if (error.response?.status === 440) {
      throw receivedError;
    } else if (
      receivedError.data &&
      typeof receivedError.data === "object" &&
      Object.keys(receivedError.data).length
    ) {
      throw receivedError.data;
    } else if (receivedError.message) {
      if (
        typeof receivedError.message === "object" ||
        typeof receivedError.message === "string"
      )
        throw receivedError.message;
      else throw JSON.stringify(receivedError.message);
    } else if (typeof receivedError === "object") {
      throw receivedError;
    } else {
      // return receivedError;
      throw new Error(JSON.stringify(receivedError));
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log("ERROR REQUEST::", error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error Message::", error.message);
  }
}

export function replaceErrors(key, value) {
  if (value instanceof Error) {
    var error = {};

    Object.getOwnPropertyNames(value).forEach(function(key) {
      error[key] = value[key];
    });

    return error;
  }

  return value;
}
