import { reducer as loginReducers } from "./loginState";
import { reducer as vehicleLoginReducers } from "./vehicleLoginState";
import { reducer as localeReducers } from "./localeState";
import { reducer as settingsReducers } from "./settings";
import { reducer as dashboardReducers } from "./dashboardState";
// import { reducer as loggingUser } from './login'

const core = [
  loginReducers,
  settingsReducers,
  localeReducers,
  vehicleLoginReducers,
  dashboardReducers,
  // loggingUser
];

export default function coreReducer(state, action, i) {
  if (!Number.isInteger(i)) i = 0;
  if (i < core.length)
    return coreReducer(core[i](state, action), action, i + 1);
  else return state;
}
