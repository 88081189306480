const initialState = {
  vehicles: [],
  vehiclesCount: 0,
  actionStatus: {
    action: "",
    entity: "",
    success: null,
    error: "",
  },
  filters: {},
};

export default initialState;
