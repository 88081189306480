import axios from "axios";

import { apiURL, apiURLDev } from "../apiCallsApp";
import { handleError } from "../handleError";
import { convertNumbers2English } from "../parseData";

export const getOtp = async ({ country_code, phone, lang }) => {
  try {
    phone = await convertNumbers2English(phone);
    country_code = await convertNumbers2English(country_code);

    return await axios.post(`${apiURL(lang)}/auth/verify-phone`, {
      country_code,
      phone,
      lang,
    });
  } catch (error) {
    handleError(error);
  }
};

export const getOtpAzure = async ({ phone, lang }) => {
  console.log("kkllll", phone, lang);
  try {
    phone = await convertNumbers2English(phone);
    // country_code = await convertNumbers2English(country_code);

    return await axios.post(`${apiURL(lang)}/auth/send-otp`, {
      // country_code,
      phone,
      // lang,
    });
  } catch (error) {
    handleError(error);
  }
};

export const registerWithAzure = async ({ lang, ...fields }) => {
  try {
    return await axios.post(
      `${apiURL(lang)}/passenger/register-by-email`,
      {
        ...fields,
      },
      { withCredentials: false }
    );
  } catch (error) {
    handleError(error);
  }
};

export const loginWithPassword = async ({ lang, ...fields }) => {
  try {
    return await axios.post(
      `${apiURL(lang)}/auth/admin-login`,
      {
        ...fields,
      },
      { withCredentials: false }
    );
  } catch (error) {
    handleError(error);
  }
};

export const verifyWithOtp = async ({ lang, ...fields }) => {
  try {
    return await axios.post(
      `${apiURL(lang)}/auth/verify-admin-login`,
      {
        ...fields,
      },
      { withCredentials: false }
    );
  } catch (error) {
    handleError(error);
  }
};

export const loginWithAzure = async ({ lang, ...fields }) => {
  try {
    return await axios.get(
      `${apiURL(lang)}/auth/me`,
      {
        headers: { ...fields },
      },
      { withCredentials: false }
    );
  } catch (error) {
    handleError(error);
  }
};

export const callForgetPasswordApi = async ({ lang, ...fields }) => {
  try {
    return await axios.post(
      `${apiURL(lang)}/auth/forgot-password`,
      {
        ...fields,
      },
      { withCredentials: false }
    );
  } catch (error) {
    handleError(error);
  }
};

export const callChangePasswordApi = async ({ lang, ...fields }) => {
  try {
    return await axios.post(
      `${apiURL(lang)}/auth/reset-password`,
      {
        ...fields,
      },
      { withCredentials: false }
    );
  } catch (error) {
    handleError(error);
  }
};

export const loginWithOtp = async ({
  lang,
  country_code,
  phone,
  code,
  ...rest
}) => {
  try {
    if (country_code) country_code = await convertNumbers2English(country_code);

    phone = await convertNumbers2English(phone);
    code = await convertNumbers2English(code);

    return await axios.post(
      `${apiURL(lang)}/auth/login`,
      {
        phone,
        country_code,
        otp: code,
        ...rest,
      },
      { withCredentials: false }
    );
  } catch (error) {
    handleError(error);
  }
};

export const logout = async ({ lang, ...fields }) => {
  try {
    var result = await axios.post(
      `${apiURL(lang)}/auth/logout`,
      {
        ...fields,
      },
      {
        withCredentials: false,
      }
    );

    return result;
  } catch (error) {
    handleError(error);
  }
};

export const register = async ({ lang, ...fields }) => {
  try {
    return await axios.post(
      `${apiURL(lang)}/passenger/self-register`,
      {
        ...fields,
      },
      { withCredentials: false }
    );
  } catch (error) {
    handleError(error);
  }
};

export const checkIsSessionValid = async ({ lang, ...fields }) => {
  try {
    return await axios.post(
      `${apiURL(lang)}/auth/check-session`,
      {
        ...fields,
      },
      {
        withCredentials: false,
      }
    );
  } catch (error) {
    handleError(error);
  }
};

export const checkIsUserValid = async ({ lang, ...fields }) => {
  try {
    return await axios.post(
      `${apiURL(lang)}/auth/check-status`,
      {
        ...fields,
      },
      {
        withCredentials: false,
      }
    );
  } catch (error) {
    handleError(error);
  }
};

export const apiVehicleLogin = async ({ lang, ...fields }) => {
  try {
    return await axios.post(
      `${apiURL(lang)}/auth/vehicle-login`,
      {
        ...fields,
      },
      { withCredentials: false }
    );
  } catch (error) {
    handleError(error);
  }
};
