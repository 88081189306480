// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";

// internal imports
import api from "../../../utils/api/general";
import { replaceErrors } from "../../../utils/handleError";
// get action names
import {
  ADD_BUS_OPERATOR_REQUEST,
  ADD_BUS_OPERATOR_SUCCESS,
  ADD_BUS_OPERATOR_ERROR,
  EDIT_BUS_OPERATOR_REQUEST,
  EDIT_BUS_OPERATOR_SUCCESS,
  EDIT_BUS_OPERATOR_ERROR,
  GET_BUS_OPERATOR_REQUEST,
  GET_BUS_OPERATOR_SUCCESS,
  GET_BUS_OPERATOR_ERROR,
  GET_SINGLE_BUS_OPERATOR_REQUEST,
  GET_SINGLE_BUS_OPERATOR_SUCCESS,
  GET_SINGLE_BUS_OPERATOR_ERROR,
  DELETE_BUS_OPERATOR_REQUEST,
  DELETE_BUS_OPERATOR_SUCCESS,
  DELETE_BUS_OPERATOR_ERROR,
  DELETE_BULK_BUS_OPERATOR_REQUEST,
  DELETE_BULK_BUS_OPERATOR_SUCCESS,
  DELETE_BULK_BUS_OPERATOR_ERROR,
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 30 * 1000; // 5 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_BUS_OPERATOR_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "busOperator",
          success: null,
          error: "",
        },
      };
    case ADD_BUS_OPERATOR_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "busOperator",
          success: true,
          error: "",
        },
      };
    case ADD_BUS_OPERATOR_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "busOperator",
          success: false,
          error: action.error,
        },
      };
    case EDIT_BUS_OPERATOR_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "busOperator",
          success: null,
          error: "",
        },
      };
    case EDIT_BUS_OPERATOR_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "busOperator",
          success: true,
          error: "",
        },
      };
    case EDIT_BUS_OPERATOR_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "busOperator",
          success: false,
          error: action.error,
        },
      };

    case DELETE_BUS_OPERATOR_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "busOperator",
          success: null,
          error: "",
        },
        userLinked: null,
      };
    case DELETE_BUS_OPERATOR_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "busOperator",
          success: true,
          error: "",
        },
        userLinked: action.userLinked,
      };
    case DELETE_BUS_OPERATOR_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "busOperator",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BULK_BUS_OPERATOR_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "busOperator",
          success: null,
          error: "",
        },
      };
    case DELETE_BULK_BUS_OPERATOR_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "busOperator",
          success: true,
          error: "",
        },
      };
    case DELETE_BULK_BUS_OPERATOR_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "busOperator",
          success: false,
          error: action.error,
        },
      };
    case GET_BUS_OPERATOR_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "busOperator",
                success: null,
                error: "",
              },
      };
    case GET_BUS_OPERATOR_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        busOperators: action.busOperators,
        busOperatorsCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "busOperator",
                success: true,
                error: "",
              },
      };
    case GET_BUS_OPERATOR_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "busOperator",
          success: false,
          error: action.error,
        },
      };

    case GET_SINGLE_BUS_OPERATOR_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "busOperator",
          success: null,
          error: "",
        },
      };
    case GET_SINGLE_BUS_OPERATOR_SUCCESS:
      return {
        ...newState,
        busOperators: {
          ...state.busOperators,
          [action.id]: action.busOperator,
        },
        actionStatus: {
          type: "fetch",
          entity: "busOperator",
          success: true,
          error: "",
        },
      };
    case GET_SINGLE_BUS_OPERATOR_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "busOperator",
          success: false,
          error: action.error,
        },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetSingleBusOperator(action) {
  try {
    const { busOperator } = yield race({
      busOperator: call(api.get, "busOperator", {
        lang: action.lang,
        id: action.id,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (busOperator)
      yield put({
        type: GET_SINGLE_BUS_OPERATOR_SUCCESS,
        busOperator: busOperator.data,
        id: action.id,
      });
    else
      yield put({
        type: GET_SINGLE_BUS_OPERATOR_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_SINGLE_BUS_OPERATOR_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Bus operator  could not be updated.",
    });
  }
}
function* getSingleBusOperator() {
  yield takeLatest(GET_SINGLE_BUS_OPERATOR_REQUEST, runGetSingleBusOperator);
}

function* runGetBusOperators(action) {
  try {
    const { busOperator } = yield race({
      busOperator: call(api.get, "busOperator", {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (busOperator) {
      const busOperators = busOperator.data ? busOperator.data.data : [];
      yield put({
        type: GET_BUS_OPERATOR_SUCCESS,
        source: action.source,
        busOperators: busOperators.rows,
        count: busOperators.count,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_BUS_OPERATOR_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_BUS_OPERATOR_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Bus operators could not be updated.",
    });
  }
}
function* getBusOperators() {
  yield takeLatest(GET_BUS_OPERATOR_REQUEST, runGetBusOperators);
}

function* runAddBusOperator(action) {
  try {
    let extraUrl = action.userType === "staff" ? "Staff" : "";
    const { busOperator } = yield race({
      busOperator: call(api.add, `busOperator${extraUrl}`, {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (busOperator)
      yield put({
        type: ADD_BUS_OPERATOR_SUCCESS,
        ...busOperator.data,
      });
    else
      yield put({
        type: ADD_BUS_OPERATOR_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_BUS_OPERATOR_ERROR,
      error,
      //error: typeof error === "object" ? error : "Could not add busOperator",
    });
  }
}
function* addBusOperator() {
  yield takeLatest(ADD_BUS_OPERATOR_REQUEST, runAddBusOperator);
}

function* runEditBusOperator(action) {
  try {
    // Update busOperator details
    const { busOperator } = yield race({
      busOperator: call(api.update, "busOperator", {
        lang: action.lang,
        id: action.id,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (busOperator) {
      // Update user details
      yield put({
        type: EDIT_BUS_OPERATOR_SUCCESS,
        ...busOperator.data,
      });
    } else
      yield put({
        type: EDIT_BUS_OPERATOR_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_BUS_OPERATOR_ERROR,
      error,
      //error: typeof error === "object" ? error : "Could not edit busOperator",
    });
  }
}
function* editBusOperator() {
  yield takeLatest(EDIT_BUS_OPERATOR_REQUEST, runEditBusOperator);
}

function* runDeleteBusOperator(action) {
  try {
    const { busOperator } = yield race({
      busOperator: call(
        api.delete,
        `busOperator${action.params?.ids ? "Bulk" : ""}`,
        {
          id: action.params?.id || undefined,
          data: action.params?.ids || undefined,
          lang: action.lang,
        }
      ),
      timeout: delay(parseResponseTimeout),
    });

    if (busOperator) {
      let obj = { type: DELETE_BUS_OPERATOR_SUCCESS };
      if (
        busOperator?.data?.data &&
        Object.values(busOperator?.data?.data).length > 0
      )
        obj.userLinked = {
          message: busOperator.data.message,
          data: busOperator.data.data,
        };
      yield put(obj);
    } else
      yield put({
        type: DELETE_BUS_OPERATOR_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BUS_OPERATOR_ERROR,
      error:
        typeof error === "object" ? error : "Could not delete busOperators",
    });
  }
}
function* deleteBusOperator() {
  yield takeLatest(DELETE_BUS_OPERATOR_REQUEST, runDeleteBusOperator);
}

function* runDeleteBulkBusOperator(action) {
  try {
    const { busOperator } = yield race({
      busOperator: call(api.delete, "busOperator", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (busOperator) yield put({ type: DELETE_BULK_BUS_OPERATOR_SUCCESS });
    else
      yield put({
        type: DELETE_BULK_BUS_OPERATOR_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BULK_BUS_OPERATOR_ERROR,
      error:
        typeof error === "object" ? error : "Could not delete busOperators",
    });
  }
}
function* deleteBulkBusOperator() {
  yield takeEvery(DELETE_BULK_BUS_OPERATOR_REQUEST, runDeleteBulkBusOperator);
}

export function* saga() {
  while (true) {
    yield all({
      getBusOperators: call(getBusOperators),
      getSingleBusOperator: call(getSingleBusOperator),
      addBusOperator: call(addBusOperator),
      editBusOperator: call(editBusOperator),
      deleteBusOperator: call(deleteBusOperator),
      deleteBulkBusOperator: call(deleteBulkBusOperator),
    });
  }
}
