import { reducer as regionReducers } from "./regionState";

const core = [regionReducers];

export default function regionReducer(state, action, i) {
  if (!Number.isInteger(i)) i = 0;
  if (i < core.length)
    return regionReducer(core[i](state, action), action, i + 1);
  else return state;
}
