import { reducer as bookingReducers } from "./bookingState";
import { reducer as bookingTemplateReducers } from "./shiftBookingState";
// import { reducer as loggingUser } from './login'

const core = [
  bookingReducers,
  bookingTemplateReducers,
  // loggingUser
];

export default function bookingReducer(state, action, i) {
  if (!Number.isInteger(i)) i = 0;
  if (i < core.length)
    return bookingReducer(core[i](state, action), action, i + 1);
  else return state;
}
