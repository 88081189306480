import { reducer as parkReducers } from "./parkState";
import { reducer as scheduleReducers } from "./scheduleState";

const core = [parkReducers, scheduleReducers];

export default function parkReducer(state, action, i) {
  if (!Number.isInteger(i)) i = 0;
  if (i < core.length)
    return parkReducer(core[i](state, action), action, i + 1);
  else return state;
}
