import { reducer as notificationReducers } from "./notificationState";
// import { reducer as loggingUser } from './login'

const core = [
  notificationReducers,
  // loggingUser
];

export default function notificationReducer(state, action, i) {
  if (!Number.isInteger(i)) i = 0;
  if (i < core.length)
    return notificationReducer(core[i](state, action), action, i + 1);
  else return state;
}
