// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";
import moment from "moment";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  ADD_DEPARTMENT_REQUEST,
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_ERROR,
  EDIT_DEPARTMENT_REQUEST,
  EDIT_DEPARTMENT_SUCCESS,
  EDIT_DEPARTMENT_ERROR,
  GET_DEPARTMENT_REQUEST,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_ERROR,
  DELETE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_ERROR,
  DELETE_BULK_DEPARTMENT_REQUEST,
  DELETE_BULK_DEPARTMENT_SUCCESS,
  DELETE_BULK_DEPARTMENT_ERROR,
  CLEAR_ORGANIZATION_ACTION_STATUS,
  GET_COST_CENTER_ERROR,
  GET_COST_CENTER_REQUEST,
  GET_COST_CENTER_SUCCESS,
  // smsCodeTimeout
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 50 * 1000; // 50 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_DEPARTMENT_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "department",
          success: null,
          error: "",
        },
      };
    case ADD_DEPARTMENT_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "department",
          success: true,
          error: "",
        },
      };
    case ADD_DEPARTMENT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "department",
          success: false,
          error: action.error,
        },
      };
    case EDIT_DEPARTMENT_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "department",
          success: null,
          error: "",
        },
      };
    case EDIT_DEPARTMENT_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "department",
          success: true,
          error: "",
        },
      };
    case EDIT_DEPARTMENT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "department",
          success: false,
          error: action.error,
        },
      };
    case DELETE_DEPARTMENT_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "department",
          success: null,
          error: "",
        },
      };
    case DELETE_DEPARTMENT_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "department",
          success: true,
          error: "",
        },
      };
    case DELETE_DEPARTMENT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "department",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BULK_DEPARTMENT_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "department",
          success: null,
          error: "",
        },
      };
    case DELETE_BULK_DEPARTMENT_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "department",
          success: true,
          error: "",
        },
      };
    case DELETE_BULK_DEPARTMENT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "department",
          success: false,
          error: action.error,
        },
      };

    case GET_DEPARTMENT_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "department",
                success: null,
                error: "",
              },
      };
    case GET_DEPARTMENT_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        departments: action.departments,
        departmentsCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "department",
                success: true,
                error: "",
              },
      };
    case GET_DEPARTMENT_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "department",
          success: false,
          error: action.error,
        },
      };

    case GET_COST_CENTER_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "costCenter",
                success: null,
                error: "",
              },
      };
    case GET_COST_CENTER_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        [`${action.get || ""}CostCenters`]: action.costCenters,
        [`${action.get || ""}CostCentersCount`]: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "costCenter",
                success: true,
                error: "",
              },
      };
    case GET_COST_CENTER_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "costCenter",
          success: false,
          error: action.error,
        },
      };
    case CLEAR_ORGANIZATION_ACTION_STATUS:
      return {
        ...newState,
        actionStatus: { type: "", entity: "", success: null, error: "" },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetDepartments(action) {
  try {
    const { department } = yield race({
      department: call(api.get, "department", {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (department) {
      const departments = department.data ? department.data.data : [];
      yield put({
        type: GET_DEPARTMENT_SUCCESS,
        source: action.source,
        departments: departments.rows,
        count: departments.count,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_DEPARTMENT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_DEPARTMENT_ERROR,
      error:
        typeof error === "object" ? error : "Departments could not be updated.",
    });
  }
}
function* getDepartments() {
  yield takeLatest(GET_DEPARTMENT_REQUEST, runGetDepartments);
}

function* runAddDepartment(action) {
  try {
    const { department } = yield race({
      department: call(api.add, "department", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (department)
      yield put({
        type: ADD_DEPARTMENT_SUCCESS,
        ...department.data,
      });
    else
      yield put({
        type: ADD_DEPARTMENT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_DEPARTMENT_ERROR,
      error, //error: typeof error === "object" ? error : "Could not add department",
    });
  }
}
function* addDepartment() {
  yield takeLatest(ADD_DEPARTMENT_REQUEST, runAddDepartment);
}

function* runEditDepartment(action) {
  try {
    const { department } = yield race({
      department: call(api.update, "department", {
        lang: action.lang,
        id: action.id,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (department)
      yield put({
        type: EDIT_DEPARTMENT_SUCCESS,
        ...department.data,
      });
    else
      yield put({
        type: EDIT_DEPARTMENT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_DEPARTMENT_ERROR,
      error, //error: typeof error === "object" ? error : "Could not edit department",
    });
  }
}
function* editDepartment() {
  yield takeLatest(EDIT_DEPARTMENT_REQUEST, runEditDepartment);
}

function* runDeleteDepartment(action) {
  try {
    const { department } = yield race({
      department: call(api.delete, "department", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (department) yield put({ type: DELETE_DEPARTMENT_SUCCESS });
    else
      yield put({
        type: DELETE_DEPARTMENT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_DEPARTMENT_ERROR,
      error: typeof error === "object" ? error : "Could not delete department",
    });
  }
}
function* deleteDepartment() {
  yield takeLatest(DELETE_DEPARTMENT_REQUEST, runDeleteDepartment);
}

function* runDeleteBulkDepartment(action) {
  try {
    const { department } = yield race({
      department: call(api.delete, "department", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (department) yield put({ type: DELETE_BULK_DEPARTMENT_SUCCESS });
    else
      yield put({
        type: DELETE_BULK_DEPARTMENT_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BULK_DEPARTMENT_ERROR,
      error: typeof error === "object" ? error : "Could not delete department",
    });
  }
}
function* deleteBulkDepartment() {
  yield takeEvery(DELETE_BULK_DEPARTMENT_REQUEST, runDeleteBulkDepartment);
}

function* runGetCostCenters(action) {
  try {
    const { get = "" } = action;
    const { costCenter } = yield race({
      costCenter: call(api.get, `costCenter${get}`, {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (costCenter) {
      const costCenters = costCenter.data ? costCenter.data.data : [];
      yield put({
        type: GET_COST_CENTER_SUCCESS,
        source: action.source,
        costCenters: costCenters.rows,
        count: costCenters.count,
        get,

        filters: action.filters,
      });
    } else
      yield put({
        type: GET_COST_CENTER_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_COST_CENTER_ERROR,
      error:
        typeof error === "object"
          ? error
          : "Cost centers could not be updated.",
    });
  }
}
function* getCostCenters() {
  yield takeLatest(GET_COST_CENTER_REQUEST, runGetCostCenters);
}

export function* saga() {
  while (true) {
    yield all({
      getDepartments: call(getDepartments),
      addDepartment: call(addDepartment),
      editDepartment: call(editDepartment),
      deleteDepartment: call(deleteDepartment),
      deleteBulkDepartment: call(deleteBulkDepartment),
      getCostCenters: call(getCostCenters),
    });
  }
}
