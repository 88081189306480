export const GET_PARK_REQUEST = "GET_PARK_REQUEST";
export const GET_PARK_SUCCESS = "GET_PARK_SUCCESS";
export const GET_PARK_ERROR = "GET_PARK_ERROR";

export const ADD_PARK_REQUEST = "ADD_PARK_REQUEST";
export const ADD_PARK_SUCCESS = "ADD_PARK_SUCCESS";
export const ADD_PARK_ERROR = "ADD_PARK_ERROR";

export const EDIT_PARK_REQUEST = "EDIT_PARK_REQUEST";
export const EDIT_PARK_SUCCESS = "EDIT_PARK_SUCCESS";
export const EDIT_PARK_ERROR = "EDIT_PARK_ERROR";

export const DELETE_PARK_REQUEST = "DELETE_PARK_REQUEST";
export const DELETE_PARK_SUCCESS = "DELETE_PARK_SUCCESS";
export const DELETE_PARK_ERROR = "DELETE_PARK_ERROR";

export const DELETE_PARK_BULK_ERROR = "DELETE_PARK_BULK_ERROR";
export const DELETE_PARK_BULK_REQUEST = "DELETE_PARK_BULK_REQUEST";
export const DELETE_PARK_BULK_SUCCESS = "DELETE_PARK_BULK_SUCCESS";

export const CLEAR_PARKS_ACTION_STATUS = "CLEAR_PARKS_ACTION_STATUS";

export const ADD_SCHEDULE_REQUEST = "ADD_SCHEDULE_REQUEST";
export const ADD_SCHEDULE_SUCCESS = "ADD_SCHEDULE_SUCCESS";
export const ADD_SCHEDULE_ERROR = "ADD_SCHEDULE_ERROR";

export const GET_SCHEDULE_ERROR = "GET_SCHEDULE_ERROR";
export const GET_SCHEDULE_REQUEST = "GET_SCHEDULE_REQUEST";
export const GET_SCHEDULE_SUCCESS = "GET_SCHEDULE_SUCCESS";

export const EDIT_SCHEDULE_REQUEST = "EDIT_SCHEDULE_REQUEST";
export const EDIT_SCHEDULE_SUCCESS = "EDIT_SCHEDULE_SUCCESS";
export const EDIT_SCHEDULE_ERROR = "EDIT_SCHEDULE_ERROR";

export const DELETE_SCHEDULE_REQUEST = "DELETE_SCHEDULE_REQUEST";
export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS";
export const DELETE_SCHEDULE_ERROR = "DELETE_SCHEDULE_ERROR";

export const CLEAR_SCHEDULES_ACTION_STATUS = "CLEAR_SCHEDULES_ACTION_STATUS";
