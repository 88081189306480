// external import
import { all, call, delay, put, race, takeLatest } from "redux-saga/effects";
// internal imports
import api from "../../../utils/api/general";
// get action names
import {
  ADD_SCHEDULE_ERROR,
  ADD_SCHEDULE_REQUEST,
  ADD_SCHEDULE_SUCCESS,
  GET_SCHEDULE_ERROR,
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  CLEAR_SCHEDULES_ACTION_STATUS,
  EDIT_SCHEDULE_REQUEST,
  EDIT_SCHEDULE_ERROR,
  EDIT_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_ERROR,
  DELETE_SCHEDULE_REQUEST,
} from "../constants";

// get initial state
import initialState from "./initialState";

const parseResponseTimeout = 50 * 1000; // 50 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_SCHEDULE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "schedule",
          success: null,
          error: "",
        },
      };
    case ADD_SCHEDULE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "schedule",
          success: true,
          error: "",
        },
      };
    case ADD_SCHEDULE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "schedule",
          success: false,
          error: action.error,
        },
      };

    case GET_SCHEDULE_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "schedule",
                success: null,
                error: "",
              },
      };
    case GET_SCHEDULE_SUCCESS:
      return {
        ...newState,
        schedulefilters: action.schedulefilters,
        schedules: action.schedules,
        schedulesCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "schedule",
                success: true,
                error: "",
              },
      };
    case GET_SCHEDULE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "schedule",
          success: false,
          error: action.error,
        },
      };

    case EDIT_SCHEDULE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "schedule",
          success: null,
          error: "",
        },
      };
    case EDIT_SCHEDULE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "schedule",
          success: true,
          error: "",
        },
      };
    case EDIT_SCHEDULE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "schedule",
          success: false,
          error: action.error,
        },
      };

    case DELETE_SCHEDULE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "schedule",
          success: null,
          error: "",
        },
      };
    case DELETE_SCHEDULE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "schedule",
          success: true,
          error: "",
        },
      };
    case DELETE_SCHEDULE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "schedule",
          success: false,
          error: action.error,
        },
      };

    case CLEAR_SCHEDULES_ACTION_STATUS:
      return {
        ...newState,
        actionStatus: { type: "", entity: "", success: null, error: "" },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runAddSchedule(action) {
  try {
    const { park } = yield race({
      park: call(api.add, "scheduleMaintenance", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (park)
      yield put({
        type: ADD_SCHEDULE_SUCCESS,
        ...park.data,
      });
    else
      yield put({
        type: ADD_SCHEDULE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_SCHEDULE_ERROR,
      error, //error: typeof error === "object" ? error : "Could not add park",
    });
  }
}
function* addSchedule() {
  yield takeLatest(ADD_SCHEDULE_REQUEST, runAddSchedule);
}

function* runGetSchedules(action) {
  try {
    const { schedule } = yield race({
      schedule: call(api.get, "scheduleMaintenance", {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (schedule) {
      const schedules = schedule.data ? schedule.data : [];
      yield put({
        type: GET_SCHEDULE_SUCCESS,
        source: action.source,
        schedules: schedules.data,
        count: schedules.count,
        schedulefilters: action.filters,
      });
    } else
      yield put({
        type: GET_SCHEDULE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_SCHEDULE_ERROR,
      error: typeof error === "object" ? error : "Parks could not be updated.",
    });
  }
}
function* getSchedules() {
  yield takeLatest(GET_SCHEDULE_REQUEST, runGetSchedules);
}

function* runEditSchedule(action) {
  try {
    const { park } = yield race({
      park: call(api.updateNoId, "scheduleMaintenance", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (park)
      yield put({
        type: EDIT_SCHEDULE_SUCCESS,
        ...park.data,
      });
    else
      yield put({
        type: EDIT_SCHEDULE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_SCHEDULE_ERROR,
      error, //error: typeof error === "object" ? error : "Could not edit park",
    });
  }
}
function* editSchedule() {
  yield takeLatest(EDIT_SCHEDULE_REQUEST, runEditSchedule);
}

function* runDeleteSchedules(action) {
  try {
    const { stop } = yield race({
      stop: call(api.delete, "scheduleMaintenance", {
        data: { ...action.params },
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (stop) yield put({ type: DELETE_SCHEDULE_SUCCESS });
    else
      yield put({
        type: DELETE_SCHEDULE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_SCHEDULE_ERROR,
      error: typeof error === "object" ? error : "Could not delete schedules",
    });
  }
}

function* deleteSchedules() {
  yield takeLatest(DELETE_SCHEDULE_REQUEST, runDeleteSchedules);
}

export function* saga() {
  while (true) {
    yield all({
      addSchedule: call(addSchedule),
      getSchedules: call(getSchedules),
      editSchedule: call(editSchedule),
      deleteSchedules: call(deleteSchedules),
    });
  }
}
