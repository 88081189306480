// external import

import {
  all,
  delay,
  put,
  fork,
  select,
  call,
  take,
  takeLatest,
  takeEvery,
  race,
  cancel,
} from "redux-saga/effects";
import moment from "moment";

// internal imports
import api from "../../../utils/api/general";

// get action names
import {
  ADD_VEHICLE_REQUEST,
  ADD_VEHICLE_SUCCESS,
  ADD_VEHICLE_ERROR,
  EDIT_VEHICLE_REQUEST,
  EDIT_VEHICLE_SUCCESS,
  EDIT_VEHICLE_ERROR,
  GET_VEHICLE_REQUEST,
  GET_VEHICLE_SUCCESS,
  GET_VEHICLE_ERROR,
  DELETE_VEHICLE_REQUEST,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_ERROR,
  DELETE_BULK_VEHICLE_REQUEST,
  DELETE_BULK_VEHICLE_SUCCESS,
  DELETE_BULK_VEHICLE_ERROR,
  CLEAR_VEHICLES_ACTION_STATUS,
  // smsCodeTimeout
} from "../constants";

// get initial state
import initialState from "./initialState";

// define other constants
// TODO: is this the best place for this???
// const parseResponseTimeout = 10000; // 5 seconds
const parseResponseTimeout = 30 * 1000; // 5 seconds

const errors = {
  requestTimeout: {
    code: 2001,
    message: `REQ_TIME_ERR_MSG`,
  },
};

// define reducers (state changers)
export function reducer(state = initialState, action) {
  // apply any state resets here
  const newState = Object.assign({}, state, {});

  switch (action.type) {
    case ADD_VEHICLE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "vehicle",
          success: null,
          error: "",
        },
      };
    case ADD_VEHICLE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "vehicle",
          success: true,
          error: "",
        },
      };
    case ADD_VEHICLE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "add",
          entity: "vehicle",
          success: false,
          error: action.error,
        },
      };

    case EDIT_VEHICLE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "vehicle",
          success: null,
          error: "",
          fieldValue: undefined,
        },
      };
    case EDIT_VEHICLE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "vehicle",
          success: true,
          error: "",
        },
      };
    case EDIT_VEHICLE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "edit",
          entity: "vehicle",
          success: false,
          error: action.error,
          fieldValue: action.fieldValue,
        },
      };
    case DELETE_VEHICLE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "vehicle",
          success: null,
          error: "",
        },
      };
    case DELETE_VEHICLE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "vehicle",
          success: true,
          error: "",
        },
      };
    case DELETE_VEHICLE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "delete",
          entity: "vehicle",
          success: false,
          error: action.error,
        },
      };
    case DELETE_BULK_VEHICLE_REQUEST:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "vehicle",
          success: null,
          error: "",
        },
      };
    case DELETE_BULK_VEHICLE_SUCCESS:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "vehicle",
          success: true,
          error: "",
        },
      };
    case DELETE_BULK_VEHICLE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "bulkDelete",
          entity: "vehicle",
          success: false,
          error: action.error,
        },
      };

    case GET_VEHICLE_REQUEST:
      return {
        ...newState,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "vehicle",
                success: null,
                error: "",
              },
      };
    case GET_VEHICLE_SUCCESS:
      return {
        ...newState,
        filters: action.filters,
        vehicles: action.vehicles,
        vehiclesCount: action.count,
        actionStatus:
          action.source === "sidePanel"
            ? state.actionStatus
            : {
                type: "fetch",
                entity: "vehicle",
                success: true,
                error: "",
              },
      };
    case GET_VEHICLE_ERROR:
      return {
        ...newState,
        actionStatus: {
          type: "fetch",
          entity: "vehicle",
          success: false,
          error: action.error,
        },
      };
    case CLEAR_VEHICLES_ACTION_STATUS:
      return {
        ...newState,
        actionStatus: { type: "", entity: "", success: "", error: "" },
      };
    case "LOGOUT_SUCCESS":
      return {};
    default:
      return state;
  }
}

function* runGetVehicles(action) {
  try {
    let url = action.search
      ? "Search"
      : action.params.filterFor === "hardwares"
      ? "ByHardware"
      : "";
    const { vehicle } = yield race({
      vehicle: call(api.get, `vehicle${url}`, {
        lang: action.lang,
        ...action.params,
        ...action.filters,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (vehicle) {
      const vehicles = vehicle.data ? vehicle.data.data : [];
      yield put({
        type: GET_VEHICLE_SUCCESS,
        source: action.source,
        vehicles: vehicles.rows,
        count: vehicles.count,
        filters: action.filters,
      });
    } else
      yield put({
        type: GET_VEHICLE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: GET_VEHICLE_ERROR,
      error:
        typeof error === "object" ? error : "Vehicles could not be updated.",
    });
  }
}
function* getVehicles() {
  yield takeLatest(GET_VEHICLE_REQUEST, runGetVehicles);
}

function* runAddVehicle(action) {
  try {
    const { vehicle } = yield race({
      vehicle: call(api.add, "vehicle", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (vehicle)
      yield put({
        type: ADD_VEHICLE_SUCCESS,
        ...vehicle.data,
      });
    else
      yield put({
        type: ADD_VEHICLE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: ADD_VEHICLE_ERROR,
      error, // error: typeof error === "object" ? error : "Could not add vehicle",
    });
  }
}
function* addVehicle() {
  yield takeLatest(ADD_VEHICLE_REQUEST, runAddVehicle);
}

function* runEditVehicle(action) {
  try {
    const { vehicle } = yield race({
      vehicle: call(api.update, "vehicle", {
        lang: action.lang,
        ...action.fields,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (vehicle)
      yield put({
        type: EDIT_VEHICLE_SUCCESS,
        ...vehicle.data,
      });
    else
      yield put({
        type: EDIT_VEHICLE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: EDIT_VEHICLE_ERROR,
      error, //error: typeof error === "object" ? error : "Could not edit vehicle",
      fieldValue: { id: action.id, ...action.fields },
    });
  }
}
function* editVehicle() {
  yield takeLatest(EDIT_VEHICLE_REQUEST, runEditVehicle);
}

function* runDeleteVehicle(action) {
  try {
    const { vehicle } = yield race({
      vehicle: call(api.delete, "vehicle", {
        id: action.id,
        lang: action.lang,
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (vehicle) yield put({ type: DELETE_VEHICLE_SUCCESS });
    else
      yield put({
        type: DELETE_VEHICLE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_VEHICLE_ERROR,
      error: typeof error === "object" ? error : "Could not delete vehicle",
    });
  }
}
function* deleteVehicle() {
  yield takeLatest(DELETE_VEHICLE_REQUEST, runDeleteVehicle);
}

function* runDeleteBulkVehicle(action) {
  // yield put({
  //   type: DELETE_BULK_VEHICLE_ERROR,
  //   error: {
  //     hasTrips: true,
  //     confirmDialogue: true,
  //     message: "Future bookings exists for this trip.",
  //     action: "edit",
  //     count: 10,
  //   },
  // });
  try {
    console.log({ action });
    const { vehicle } = yield race({
      vehicle: call(api.delete, "bulkDeleteVehicle", {
        lang: action?.lang,
        data: { ...action.params },
      }),
      timeout: delay(parseResponseTimeout),
    });

    if (vehicle) yield put({ type: DELETE_BULK_VEHICLE_SUCCESS });
    else
      yield put({
        type: DELETE_BULK_VEHICLE_ERROR,
        error: errors.requestTimeout.message,
      });
  } catch (error) {
    yield put({
      type: DELETE_BULK_VEHICLE_ERROR,
      error: typeof error === "object" ? error : "Could not delete vehicle",
    });
  }
}
function* deleteBulkVehicle() {
  yield takeEvery(DELETE_BULK_VEHICLE_REQUEST, runDeleteBulkVehicle);
}

export function* saga() {
  while (true) {
    yield all({
      getVehicles: call(getVehicles),
      addVehicle: call(addVehicle),
      editVehicle: call(editVehicle),
      deleteVehicle: call(deleteVehicle),
      deleteBulkVehicle: call(deleteBulkVehicle),
    });
  }
}
