const initialState = {
  passengers: [],
  passengersCount: 0,
  contractors: [],
  contractorsCount: 0,
  contractFocalPoints: [],
  contractFocalPointsCount: 0,
  drivers: [],
  driversCount: 0,
  busOperators: [],
  busOperatorsCount: 0,
  staff: [],
  staffCount: 0,
  focalPoints: [],
  focalPointsCount: 0,
  approvers: [],
  approversCount: 0,
  adminUsers: [],
  adminUsersCount: 0,
  actionStatus: {
    action: "",
    entity: "",
    success: null,
    error: "",
  },
  filters: {},
};

export default initialState;
