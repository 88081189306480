export const GET_PENDING_REGISTRATION_REQUEST =
  "GET_PENDING_REGISTRATION_REQUEST";
export const GET_PENDING_REGISTRATION_SUCCESS =
  "GET_PENDING_REGISTRATION_SUCCESS";
export const GET_PENDING_REGISTRATION_ERROR = "GET_PENDING_REGISTRATION_ERROR";

export const GET_APPROVER_PENDING_REGISTRATION_REQUEST =
  "GET_APPROVER_PENDING_REGISTRATION_REQUEST";
export const GET_APPROVER_PENDING_REGISTRATION_SUCCESS =
  "GET_APPROVER_PENDING_REGISTRATION_SUCCESS";
export const GET_APPROVER_PENDING_REGISTRATION_ERROR =
  "GET_APPROVER_PENDING_REGISTRATION_ERROR";

export const APPROVE_PENDING_REGISTRATION_REQUEST =
  "APPROVE_PENDING_REGISTRATION_REQUEST";
export const APPROVE_PENDING_REGISTRATION_SUCCESS =
  "APPROVE_PENDING_REGISTRATION_SUCCESS";
export const APPROVE_PENDING_REGISTRATION_ERROR =
  "APPROVE_PENDING_REGISTRATION_ERROR";

export const APPROVE_BULK_PENDING_REGISTRATION_REQUEST =
  "APPROVE_BULK_PENDING_REGISTRATION_REQUEST";
export const APPROVE_BULK_PENDING_REGISTRATION_SUCCESS =
  "APPROVE_BULK_PENDING_REGISTRATION_SUCCESS";
export const APPROVE_BULK_PENDING_REGISTRATION_ERROR =
  "APPROVE_BULK_PENDING_REGISTRATION_ERROR";

export const CLEAR_REQUESTS_ACTION_STATUS = "CLEAR_REQUESTS_ACTION_STATUS";

export const UPDATE_REJECTED_REGISTRATION_REQUEST =
  "UPDATE_REJECTED_REGISTRATION_REQUEST";
export const UPDATE_REJECTED_REGISTRATION_SUCCESS =
  "UPDATE_REJECTED_REGISTRATION_SUCCESS";
export const UPDATE_REJECTED_REGISTRATION_ERROR =
  "UPDATE_REJECTED_REGISTRATION_ERROR";
